// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-layouts-mdx-layout-tsx": () => import("./../../src/layouts/MDXLayout.tsx" /* webpackChunkName: "component---src-layouts-mdx-layout-tsx" */),
  "component---src-pages-bpmindicatie-tsx": () => import("./../../src/pages/bpmindicatie.tsx" /* webpackChunkName: "component---src-pages-bpmindicatie-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-tracktrace-tsx": () => import("./../../src/pages/tracktrace.tsx" /* webpackChunkName: "component---src-pages-tracktrace-tsx" */)
}

