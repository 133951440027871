import { InMemoryCache } from 'apollo-cache-inmemory'
import { ApolloClient } from 'apollo-client'
import { ApolloLink } from 'apollo-link'
import { onError } from 'apollo-link-error'
import { createUploadLink } from 'apollo-upload-client'
import { isClient } from '../constants'
import fetch from 'isomorphic-fetch'

const cache = new InMemoryCache()

const httpLink = createUploadLink({
  fetch,
  credentials: 'include',
  uri:
    process.env.NODE_ENV === 'development'
      ? 'http://localhost:4000/graphql'
      : 'https://graphql.pcautoimport.nl',
})

if (isClient) {
  cache.restore((window as any).__APOLLO__)
}

/*  eslint-disable no-console */

export const client = new ApolloClient({
  cache,
  link: ApolloLink.from([
    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors) {
        graphQLErrors.map(({ message, locations, path }) =>
          console.log(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
          )
        )
      }
      if (networkError) {
        console.log(`[Network error]: ${networkError}`) // eslint-disable-line
      }
    }),
    httpLink,
  ]),
})
