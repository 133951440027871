import React from 'react'
import styled from '@emotion/styled'
import Icon, { IconProps } from './Icon'
import { jsx } from 'theme-ui'

/** @jsx jsx */
const ListItemWrapper = styled.div`
  display: flex;
  align-items: start;
  & > li {
    padding-left: 20px;
    width: 100%;
  }
  &:not(:last-of-type) {
    margin-bottom: 10px;
  }
`

const ListItem: React.SFC<{ icon?: IconProps['icon'] | null }> = ({
  children,
  icon = 'plus',
  ...props
}) => {
  return (
    <ListItemWrapper {...props}>
      {icon && (
        <Icon
          icon={icon}
          size={13}
          style={{ transform: 'rotate(45deg )', marginTop: 8 }}
        />
      )}
      <li>{children}</li>
    </ListItemWrapper>
  )
}

export default ListItem
