import styled from '@emotion/styled'

export default styled.div`
  ul {
    margin-left: 2em;
    li {
      list-style: disc outside none;
      display: list-item;
      margin-left: 1em;
    }
  }
`
