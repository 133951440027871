/** @jsx jsx */
import * as React from 'react'
import styled from '@emotion/styled'
import OnScreen from 'react-on-screen'
import AnimateHeight from 'react-animate-height'
import { Icon } from '.'
import { jsx } from 'theme-ui'
import { Flex, Box, Text } from 'rebass'

const Wrapper = styled.div<{ isVisible?: boolean }>`
  display: flex;
  transition: opacity 0.4s ease-out, transform 0.4s ease-out;
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  transform: translateY(${({ isVisible }) => (isVisible ? 0 : -10)}%);
  flex-direction: column;
  cursor: pointer;
`

const Description = styled.div`
  margin-left: 34px;
  overflow: hidden;
  & > p {
    margin-bottom: 20px;
  }
`

export interface FeatureProps {
  title: string
  description: string | React.ReactNode
  index?: number
  color?: string
}

const Feature: React.SFC<FeatureProps> = ({
  title,
  description,
  index,
  color = 'white',
}) => {
  const [open, setOpen] = React.useState(index === 0)
  const toggleFeature = () => setOpen(!open)
  return (
    <OnScreen once offset={400}>
      <Wrapper>
        <Flex alignItems="center" onClick={toggleFeature} mb={8}>
          <Box height={24} width={24}>
            <Icon
              icon="plus"
              style={{
                transform: open ? 'rotate(135deg)' : 'none',
                transition: 'transform .3s ease-in-out',
              }}
              color={color}
            />
          </Box>
          <Text fontSize={16} ml={8} mt={-1} fontWeight={600}>
            {title}
          </Text>
        </Flex>
        <AnimateHeight height={open ? 'auto' : 0}>
          <Description>{description}</Description>
        </AnimateHeight>
      </Wrapper>
    </OnScreen>
  )
}

export default Feature
