/** @jsx jsx */
import { FC } from 'react'
import { FlexProps, Flex, Heading, Box } from 'rebass'
import { jsx } from 'theme-ui'

const padding = [20, 20, 10]

const Section: FC<
  Omit<FlexProps, 'css'> & {
    fluid?: boolean
    title?: string
    type?: 'primary' | 'secondary'
  }
> = ({ fluid, children, title, type = 'primary', ...props }) => (
  <Flex
    width="100%"
    py={[4]}
    color={type === 'secondary' && '#fff'}
    flexDirection="column"
    px={!fluid && padding}
    sx={{
      background: theme =>
        type === 'secondary'
          ? `linear-gradient(to bottom, ${theme.colors.secondary}, ${theme.colors.primary})`
          : 'none',
    }}
    {...props}
  >
    <Box variant="container" width="100%">
      {title && (
        <Heading as="h3" fontSize={[3, 3, 4]} mb={4}>
          {title}
        </Heading>
      )}
      {children}
    </Box>
  </Flex>
)

export default Section
