import React from 'react'
import FormField from './FormField'
import styled from '@emotion/styled'
import { useField } from 'formik'
import { IconProps } from './Icon'

const TextAreaInput = styled.textarea`
  background: none;
  border: none;
  width: inherit;
  color: inherit;
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  :focus {
    outline: none;
  }
  ::placeholder {
    color: inherit;
    font-weight: 400i;
  }
`

interface InputProps extends React.InputHTMLAttributes<{}> {
  fieldProps: IconProps
}

const TextArea: React.SFC<InputProps> = ({ name, fieldProps, ...props }) => {
  const [field, form] = useField(name)
  return (
    <FormField errors={form.error} touched={form.touched} {...fieldProps}>
      <TextAreaInput {...field} {...props} />
    </FormField>
  )
}

export default TextArea
