import * as React from 'react'
import styled from '@emotion/styled'
import Lottie from 'react-lottie'
import { Button as RBButton, ButtonProps } from 'rebass'
import Loading from '../../lottie/loading.json'
import Checkmark from '../../lottie/checked-done.json'

const ButtonContainer = styled(RBButton)`
  border-radius: 10px;
  width: 100%;
  text-align: center;
  border: none;
  position: relative;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  font-weight: 700;
  font-size: 24px;
  transition-property: tranform, background-color;
  transition: 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &:active {
    transform: translateY(1px);
  }
`

const SuffixWrapper = styled.div`
  position: absolute;
  right: 10px;
`

export interface Button extends Omit<ButtonProps, 'css'> {
  loading?: boolean
  success?: boolean
  disabled?: boolean
  color?: string
  suffix?: React.ReactNode
}

const Button: React.SFC<Button> = ({
  children,
  loading,
  success,
  suffix,
  disabled,
  color,
  ...props
}) => (
  <ButtonContainer
    sx={{
      ':hover': () =>
        !disabled && {
          transform: 'translateY(-1px)',
          boxShadow: '0 2px 6px 1px rgba(0,0,0,0.5)',
        },
    }}
    color={disabled ? '#808080' : '#fff'}
    backgroundColor={disabled ? 'disabled' : color || 'primaryDark'}
    {...props}
  >
    {(loading && (
      <Lottie
        width={50}
        options={{
          autoplay: true,
          animationData: Loading,
          rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
          },
        }}
      />
    )) ||
      (success && (
        <Lottie
          width={120}
          height={50}
          options={{
            loop: false,
            autoplay: true,
            animationData: Checkmark,
            rendererSettings: {
              preserveAspectRatio: 'xMidYMid slice',
            },
          }}
        />
      )) ||
      children}
    {suffix && <SuffixWrapper>{suffix}</SuffixWrapper>}
  </ButtonContainer>
)

export default Button
