import gql from 'graphql-tag'
import * as ApolloReactCommon from '@apollo/react-common'
import * as React from 'react'
import * as ApolloReactComponents from '@apollo/react-components'
import * as ApolloReactHoc from '@apollo/react-hoc'
import * as ApolloReactHooks from '@apollo/react-hooks'
export type Maybe<T> = T | null
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  DateTime: any
  Upload: any
}

export type Address = {
  __typename?: 'Address'
  id: Scalars['ID']
  createdAt: Scalars['DateTime']
  street: Scalars['String']
  number: Scalars['String']
  addition?: Maybe<Scalars['String']>
  city: Scalars['String']
  zipCode: Scalars['String']
}

export type AddressCreateInput = {
  id?: Maybe<Scalars['ID']>
  street: Scalars['String']
  number: Scalars['String']
  addition?: Maybe<Scalars['String']>
  city: Scalars['String']
  zipCode: Scalars['String']
}

export type AddressCreateOneInput = {
  create?: Maybe<AddressCreateInput>
  connect?: Maybe<AddressWhereUniqueInput>
}

export type AddressUpdateDataInput = {
  street?: Maybe<Scalars['String']>
  number?: Maybe<Scalars['String']>
  addition?: Maybe<Scalars['String']>
  city?: Maybe<Scalars['String']>
  zipCode?: Maybe<Scalars['String']>
}

export type AddressUpdateOneInput = {
  create?: Maybe<AddressCreateInput>
  update?: Maybe<AddressUpdateDataInput>
  upsert?: Maybe<AddressUpsertNestedInput>
  delete?: Maybe<Scalars['Boolean']>
  disconnect?: Maybe<Scalars['Boolean']>
  connect?: Maybe<AddressWhereUniqueInput>
}

export type AddressUpsertNestedInput = {
  update: AddressUpdateDataInput
  create: AddressCreateInput
}

export type AddressWhereInput = {
  id?: Maybe<Scalars['ID']>
  id_not?: Maybe<Scalars['ID']>
  id_in?: Maybe<Array<Scalars['ID']>>
  id_not_in?: Maybe<Array<Scalars['ID']>>
  id_lt?: Maybe<Scalars['ID']>
  id_lte?: Maybe<Scalars['ID']>
  id_gt?: Maybe<Scalars['ID']>
  id_gte?: Maybe<Scalars['ID']>
  id_contains?: Maybe<Scalars['ID']>
  id_not_contains?: Maybe<Scalars['ID']>
  id_starts_with?: Maybe<Scalars['ID']>
  id_not_starts_with?: Maybe<Scalars['ID']>
  id_ends_with?: Maybe<Scalars['ID']>
  id_not_ends_with?: Maybe<Scalars['ID']>
  createdAt?: Maybe<Scalars['DateTime']>
  createdAt_not?: Maybe<Scalars['DateTime']>
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>
  createdAt_lt?: Maybe<Scalars['DateTime']>
  createdAt_lte?: Maybe<Scalars['DateTime']>
  createdAt_gt?: Maybe<Scalars['DateTime']>
  createdAt_gte?: Maybe<Scalars['DateTime']>
  street?: Maybe<Scalars['String']>
  street_not?: Maybe<Scalars['String']>
  street_in?: Maybe<Array<Scalars['String']>>
  street_not_in?: Maybe<Array<Scalars['String']>>
  street_lt?: Maybe<Scalars['String']>
  street_lte?: Maybe<Scalars['String']>
  street_gt?: Maybe<Scalars['String']>
  street_gte?: Maybe<Scalars['String']>
  street_contains?: Maybe<Scalars['String']>
  street_not_contains?: Maybe<Scalars['String']>
  street_starts_with?: Maybe<Scalars['String']>
  street_not_starts_with?: Maybe<Scalars['String']>
  street_ends_with?: Maybe<Scalars['String']>
  street_not_ends_with?: Maybe<Scalars['String']>
  number?: Maybe<Scalars['String']>
  number_not?: Maybe<Scalars['String']>
  number_in?: Maybe<Array<Scalars['String']>>
  number_not_in?: Maybe<Array<Scalars['String']>>
  number_lt?: Maybe<Scalars['String']>
  number_lte?: Maybe<Scalars['String']>
  number_gt?: Maybe<Scalars['String']>
  number_gte?: Maybe<Scalars['String']>
  number_contains?: Maybe<Scalars['String']>
  number_not_contains?: Maybe<Scalars['String']>
  number_starts_with?: Maybe<Scalars['String']>
  number_not_starts_with?: Maybe<Scalars['String']>
  number_ends_with?: Maybe<Scalars['String']>
  number_not_ends_with?: Maybe<Scalars['String']>
  addition?: Maybe<Scalars['String']>
  addition_not?: Maybe<Scalars['String']>
  addition_in?: Maybe<Array<Scalars['String']>>
  addition_not_in?: Maybe<Array<Scalars['String']>>
  addition_lt?: Maybe<Scalars['String']>
  addition_lte?: Maybe<Scalars['String']>
  addition_gt?: Maybe<Scalars['String']>
  addition_gte?: Maybe<Scalars['String']>
  addition_contains?: Maybe<Scalars['String']>
  addition_not_contains?: Maybe<Scalars['String']>
  addition_starts_with?: Maybe<Scalars['String']>
  addition_not_starts_with?: Maybe<Scalars['String']>
  addition_ends_with?: Maybe<Scalars['String']>
  addition_not_ends_with?: Maybe<Scalars['String']>
  city?: Maybe<Scalars['String']>
  city_not?: Maybe<Scalars['String']>
  city_in?: Maybe<Array<Scalars['String']>>
  city_not_in?: Maybe<Array<Scalars['String']>>
  city_lt?: Maybe<Scalars['String']>
  city_lte?: Maybe<Scalars['String']>
  city_gt?: Maybe<Scalars['String']>
  city_gte?: Maybe<Scalars['String']>
  city_contains?: Maybe<Scalars['String']>
  city_not_contains?: Maybe<Scalars['String']>
  city_starts_with?: Maybe<Scalars['String']>
  city_not_starts_with?: Maybe<Scalars['String']>
  city_ends_with?: Maybe<Scalars['String']>
  city_not_ends_with?: Maybe<Scalars['String']>
  zipCode?: Maybe<Scalars['String']>
  zipCode_not?: Maybe<Scalars['String']>
  zipCode_in?: Maybe<Array<Scalars['String']>>
  zipCode_not_in?: Maybe<Array<Scalars['String']>>
  zipCode_lt?: Maybe<Scalars['String']>
  zipCode_lte?: Maybe<Scalars['String']>
  zipCode_gt?: Maybe<Scalars['String']>
  zipCode_gte?: Maybe<Scalars['String']>
  zipCode_contains?: Maybe<Scalars['String']>
  zipCode_not_contains?: Maybe<Scalars['String']>
  zipCode_starts_with?: Maybe<Scalars['String']>
  zipCode_not_starts_with?: Maybe<Scalars['String']>
  zipCode_ends_with?: Maybe<Scalars['String']>
  zipCode_not_ends_with?: Maybe<Scalars['String']>
  AND?: Maybe<Array<AddressWhereInput>>
  OR?: Maybe<Array<AddressWhereInput>>
  NOT?: Maybe<Array<AddressWhereInput>>
}

export type AddressWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>
}

export type AggregateOrder = {
  __typename?: 'AggregateOrder'
  count: Scalars['Int']
}

export type AggregateUser = {
  __typename?: 'AggregateUser'
  count: Scalars['Int']
}

export type AuthPayload = {
  __typename?: 'AuthPayload'
  user?: Maybe<User>
}

export type Brand = {
  __typename?: 'Brand'
  id: Scalars['ID']
  createdAt: Scalars['DateTime']
  updatedAt: Scalars['DateTime']
  deletedAt?: Maybe<Scalars['DateTime']>
  name: Scalars['String']
  logo?: Maybe<File>
  cars?: Maybe<Array<Car>>
}

export type BrandCarsArgs = {
  where?: Maybe<CarWhereInput>
  orderBy?: Maybe<CarOrderByInput>
  skip?: Maybe<Scalars['Int']>
  after?: Maybe<Scalars['String']>
  before?: Maybe<Scalars['String']>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
}

export type BrandCreateInput = {
  id?: Maybe<Scalars['ID']>
  deletedAt?: Maybe<Scalars['DateTime']>
  name: Scalars['String']
  logo?: Maybe<FileCreateOneInput>
  cars?: Maybe<CarCreateManyWithoutBrandInput>
}

export type BrandCreateOneWithoutCarsInput = {
  create?: Maybe<BrandCreateWithoutCarsInput>
  connect?: Maybe<BrandWhereUniqueInput>
}

export type BrandCreateWithoutCarsInput = {
  id?: Maybe<Scalars['ID']>
  deletedAt?: Maybe<Scalars['DateTime']>
  name: Scalars['String']
  logo?: Maybe<FileCreateOneInput>
}

export enum BrandOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
}

export type BrandUpdateInput = {
  deletedAt?: Maybe<Scalars['DateTime']>
  name?: Maybe<Scalars['String']>
  logo?: Maybe<FileUpdateOneInput>
  cars?: Maybe<CarUpdateManyWithoutBrandInput>
}

export type BrandUpdateOneRequiredWithoutCarsInput = {
  create?: Maybe<BrandCreateWithoutCarsInput>
  update?: Maybe<BrandUpdateWithoutCarsDataInput>
  upsert?: Maybe<BrandUpsertWithoutCarsInput>
  connect?: Maybe<BrandWhereUniqueInput>
}

export type BrandUpdateWithoutCarsDataInput = {
  deletedAt?: Maybe<Scalars['DateTime']>
  name?: Maybe<Scalars['String']>
  logo?: Maybe<FileUpdateOneInput>
}

export type BrandUpsertWithoutCarsInput = {
  update: BrandUpdateWithoutCarsDataInput
  create: BrandCreateWithoutCarsInput
}

export type BrandWhereInput = {
  id?: Maybe<Scalars['ID']>
  id_not?: Maybe<Scalars['ID']>
  id_in?: Maybe<Array<Scalars['ID']>>
  id_not_in?: Maybe<Array<Scalars['ID']>>
  id_lt?: Maybe<Scalars['ID']>
  id_lte?: Maybe<Scalars['ID']>
  id_gt?: Maybe<Scalars['ID']>
  id_gte?: Maybe<Scalars['ID']>
  id_contains?: Maybe<Scalars['ID']>
  id_not_contains?: Maybe<Scalars['ID']>
  id_starts_with?: Maybe<Scalars['ID']>
  id_not_starts_with?: Maybe<Scalars['ID']>
  id_ends_with?: Maybe<Scalars['ID']>
  id_not_ends_with?: Maybe<Scalars['ID']>
  createdAt?: Maybe<Scalars['DateTime']>
  createdAt_not?: Maybe<Scalars['DateTime']>
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>
  createdAt_lt?: Maybe<Scalars['DateTime']>
  createdAt_lte?: Maybe<Scalars['DateTime']>
  createdAt_gt?: Maybe<Scalars['DateTime']>
  createdAt_gte?: Maybe<Scalars['DateTime']>
  updatedAt?: Maybe<Scalars['DateTime']>
  updatedAt_not?: Maybe<Scalars['DateTime']>
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>
  updatedAt_lt?: Maybe<Scalars['DateTime']>
  updatedAt_lte?: Maybe<Scalars['DateTime']>
  updatedAt_gt?: Maybe<Scalars['DateTime']>
  updatedAt_gte?: Maybe<Scalars['DateTime']>
  deletedAt?: Maybe<Scalars['DateTime']>
  deletedAt_not?: Maybe<Scalars['DateTime']>
  deletedAt_in?: Maybe<Array<Scalars['DateTime']>>
  deletedAt_not_in?: Maybe<Array<Scalars['DateTime']>>
  deletedAt_lt?: Maybe<Scalars['DateTime']>
  deletedAt_lte?: Maybe<Scalars['DateTime']>
  deletedAt_gt?: Maybe<Scalars['DateTime']>
  deletedAt_gte?: Maybe<Scalars['DateTime']>
  name?: Maybe<Scalars['String']>
  name_not?: Maybe<Scalars['String']>
  name_in?: Maybe<Array<Scalars['String']>>
  name_not_in?: Maybe<Array<Scalars['String']>>
  name_lt?: Maybe<Scalars['String']>
  name_lte?: Maybe<Scalars['String']>
  name_gt?: Maybe<Scalars['String']>
  name_gte?: Maybe<Scalars['String']>
  name_contains?: Maybe<Scalars['String']>
  name_not_contains?: Maybe<Scalars['String']>
  name_starts_with?: Maybe<Scalars['String']>
  name_not_starts_with?: Maybe<Scalars['String']>
  name_ends_with?: Maybe<Scalars['String']>
  name_not_ends_with?: Maybe<Scalars['String']>
  logo?: Maybe<FileWhereInput>
  cars_every?: Maybe<CarWhereInput>
  cars_some?: Maybe<CarWhereInput>
  cars_none?: Maybe<CarWhereInput>
  AND?: Maybe<Array<BrandWhereInput>>
  OR?: Maybe<Array<BrandWhereInput>>
  NOT?: Maybe<Array<BrandWhereInput>>
}

export type BrandWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>
  name?: Maybe<Scalars['String']>
}

export type Car = {
  __typename?: 'Car'
  id: Scalars['ID']
  createdAt: Scalars['DateTime']
  updatedAt: Scalars['DateTime']
  deletedAt?: Maybe<Scalars['DateTime']>
  order?: Maybe<Order>
  brand: Brand
  model: Scalars['String']
  version?: Maybe<Scalars['String']>
  color?: Maybe<Scalars['String']>
  paint?: Maybe<Scalars['String']>
  interiorColor?: Maybe<Scalars['String']>
  interiorType?: Maybe<Scalars['String']>
  transmission?: Maybe<Scalars['String']>
  fuelType?: Maybe<Scalars['String']>
  year?: Maybe<Scalars['String']>
  power?: Maybe<Scalars['String']>
  displacement?: Maybe<Scalars['String']>
  co2?: Maybe<Scalars['Int']>
  images?: Maybe<Array<File>>
  published: Scalars['Boolean']
  carPrice?: Maybe<Scalars['Int']>
  bpmPrice?: Maybe<Scalars['Int']>
  saving?: Maybe<Scalars['Int']>
}

export type CarImagesArgs = {
  where?: Maybe<FileWhereInput>
  orderBy?: Maybe<FileOrderByInput>
  skip?: Maybe<Scalars['Int']>
  after?: Maybe<Scalars['String']>
  before?: Maybe<Scalars['String']>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
}

export type CarCreateInput = {
  id?: Maybe<Scalars['ID']>
  deletedAt?: Maybe<Scalars['DateTime']>
  order?: Maybe<OrderCreateOneWithoutCarInput>
  brand: BrandCreateOneWithoutCarsInput
  model: Scalars['String']
  version?: Maybe<Scalars['String']>
  color?: Maybe<Scalars['String']>
  paint?: Maybe<Scalars['String']>
  interiorColor?: Maybe<Scalars['String']>
  interiorType?: Maybe<Scalars['String']>
  transmission?: Maybe<Scalars['String']>
  fuelType?: Maybe<Scalars['String']>
  year?: Maybe<Scalars['String']>
  power?: Maybe<Scalars['String']>
  displacement?: Maybe<Scalars['String']>
  co2?: Maybe<Scalars['Int']>
  images?: Maybe<FileCreateManyInput>
  published?: Maybe<Scalars['Boolean']>
  carPrice?: Maybe<Scalars['Int']>
  bpmPrice?: Maybe<Scalars['Int']>
  saving?: Maybe<Scalars['Int']>
}

export type CarCreateManyWithoutBrandInput = {
  create?: Maybe<Array<CarCreateWithoutBrandInput>>
  connect?: Maybe<Array<CarWhereUniqueInput>>
}

export type CarCreateOneWithoutOrderInput = {
  create?: Maybe<CarCreateWithoutOrderInput>
  connect?: Maybe<CarWhereUniqueInput>
}

export type CarCreateWithoutBrandInput = {
  id?: Maybe<Scalars['ID']>
  deletedAt?: Maybe<Scalars['DateTime']>
  order?: Maybe<OrderCreateOneWithoutCarInput>
  model: Scalars['String']
  version?: Maybe<Scalars['String']>
  color?: Maybe<Scalars['String']>
  paint?: Maybe<Scalars['String']>
  interiorColor?: Maybe<Scalars['String']>
  interiorType?: Maybe<Scalars['String']>
  transmission?: Maybe<Scalars['String']>
  fuelType?: Maybe<Scalars['String']>
  year?: Maybe<Scalars['String']>
  power?: Maybe<Scalars['String']>
  displacement?: Maybe<Scalars['String']>
  co2?: Maybe<Scalars['Int']>
  images?: Maybe<FileCreateManyInput>
  published?: Maybe<Scalars['Boolean']>
  carPrice?: Maybe<Scalars['Int']>
  bpmPrice?: Maybe<Scalars['Int']>
  saving?: Maybe<Scalars['Int']>
}

export type CarCreateWithoutOrderInput = {
  id?: Maybe<Scalars['ID']>
  deletedAt?: Maybe<Scalars['DateTime']>
  brand: BrandCreateOneWithoutCarsInput
  model: Scalars['String']
  version?: Maybe<Scalars['String']>
  color?: Maybe<Scalars['String']>
  paint?: Maybe<Scalars['String']>
  interiorColor?: Maybe<Scalars['String']>
  interiorType?: Maybe<Scalars['String']>
  transmission?: Maybe<Scalars['String']>
  fuelType?: Maybe<Scalars['String']>
  year?: Maybe<Scalars['String']>
  power?: Maybe<Scalars['String']>
  displacement?: Maybe<Scalars['String']>
  co2?: Maybe<Scalars['Int']>
  images?: Maybe<FileCreateManyInput>
  published?: Maybe<Scalars['Boolean']>
  carPrice?: Maybe<Scalars['Int']>
  bpmPrice?: Maybe<Scalars['Int']>
  saving?: Maybe<Scalars['Int']>
}

export enum CarOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  ModelAsc = 'model_ASC',
  ModelDesc = 'model_DESC',
  VersionAsc = 'version_ASC',
  VersionDesc = 'version_DESC',
  ColorAsc = 'color_ASC',
  ColorDesc = 'color_DESC',
  PaintAsc = 'paint_ASC',
  PaintDesc = 'paint_DESC',
  InteriorColorAsc = 'interiorColor_ASC',
  InteriorColorDesc = 'interiorColor_DESC',
  InteriorTypeAsc = 'interiorType_ASC',
  InteriorTypeDesc = 'interiorType_DESC',
  TransmissionAsc = 'transmission_ASC',
  TransmissionDesc = 'transmission_DESC',
  FuelTypeAsc = 'fuelType_ASC',
  FuelTypeDesc = 'fuelType_DESC',
  YearAsc = 'year_ASC',
  YearDesc = 'year_DESC',
  PowerAsc = 'power_ASC',
  PowerDesc = 'power_DESC',
  DisplacementAsc = 'displacement_ASC',
  DisplacementDesc = 'displacement_DESC',
  Co2Asc = 'co2_ASC',
  Co2Desc = 'co2_DESC',
  PublishedAsc = 'published_ASC',
  PublishedDesc = 'published_DESC',
  CarPriceAsc = 'carPrice_ASC',
  CarPriceDesc = 'carPrice_DESC',
  BpmPriceAsc = 'bpmPrice_ASC',
  BpmPriceDesc = 'bpmPrice_DESC',
  SavingAsc = 'saving_ASC',
  SavingDesc = 'saving_DESC',
}

export type CarScalarWhereInput = {
  id?: Maybe<Scalars['ID']>
  id_not?: Maybe<Scalars['ID']>
  id_in?: Maybe<Array<Scalars['ID']>>
  id_not_in?: Maybe<Array<Scalars['ID']>>
  id_lt?: Maybe<Scalars['ID']>
  id_lte?: Maybe<Scalars['ID']>
  id_gt?: Maybe<Scalars['ID']>
  id_gte?: Maybe<Scalars['ID']>
  id_contains?: Maybe<Scalars['ID']>
  id_not_contains?: Maybe<Scalars['ID']>
  id_starts_with?: Maybe<Scalars['ID']>
  id_not_starts_with?: Maybe<Scalars['ID']>
  id_ends_with?: Maybe<Scalars['ID']>
  id_not_ends_with?: Maybe<Scalars['ID']>
  createdAt?: Maybe<Scalars['DateTime']>
  createdAt_not?: Maybe<Scalars['DateTime']>
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>
  createdAt_lt?: Maybe<Scalars['DateTime']>
  createdAt_lte?: Maybe<Scalars['DateTime']>
  createdAt_gt?: Maybe<Scalars['DateTime']>
  createdAt_gte?: Maybe<Scalars['DateTime']>
  updatedAt?: Maybe<Scalars['DateTime']>
  updatedAt_not?: Maybe<Scalars['DateTime']>
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>
  updatedAt_lt?: Maybe<Scalars['DateTime']>
  updatedAt_lte?: Maybe<Scalars['DateTime']>
  updatedAt_gt?: Maybe<Scalars['DateTime']>
  updatedAt_gte?: Maybe<Scalars['DateTime']>
  deletedAt?: Maybe<Scalars['DateTime']>
  deletedAt_not?: Maybe<Scalars['DateTime']>
  deletedAt_in?: Maybe<Array<Scalars['DateTime']>>
  deletedAt_not_in?: Maybe<Array<Scalars['DateTime']>>
  deletedAt_lt?: Maybe<Scalars['DateTime']>
  deletedAt_lte?: Maybe<Scalars['DateTime']>
  deletedAt_gt?: Maybe<Scalars['DateTime']>
  deletedAt_gte?: Maybe<Scalars['DateTime']>
  model?: Maybe<Scalars['String']>
  model_not?: Maybe<Scalars['String']>
  model_in?: Maybe<Array<Scalars['String']>>
  model_not_in?: Maybe<Array<Scalars['String']>>
  model_lt?: Maybe<Scalars['String']>
  model_lte?: Maybe<Scalars['String']>
  model_gt?: Maybe<Scalars['String']>
  model_gte?: Maybe<Scalars['String']>
  model_contains?: Maybe<Scalars['String']>
  model_not_contains?: Maybe<Scalars['String']>
  model_starts_with?: Maybe<Scalars['String']>
  model_not_starts_with?: Maybe<Scalars['String']>
  model_ends_with?: Maybe<Scalars['String']>
  model_not_ends_with?: Maybe<Scalars['String']>
  version?: Maybe<Scalars['String']>
  version_not?: Maybe<Scalars['String']>
  version_in?: Maybe<Array<Scalars['String']>>
  version_not_in?: Maybe<Array<Scalars['String']>>
  version_lt?: Maybe<Scalars['String']>
  version_lte?: Maybe<Scalars['String']>
  version_gt?: Maybe<Scalars['String']>
  version_gte?: Maybe<Scalars['String']>
  version_contains?: Maybe<Scalars['String']>
  version_not_contains?: Maybe<Scalars['String']>
  version_starts_with?: Maybe<Scalars['String']>
  version_not_starts_with?: Maybe<Scalars['String']>
  version_ends_with?: Maybe<Scalars['String']>
  version_not_ends_with?: Maybe<Scalars['String']>
  color?: Maybe<Scalars['String']>
  color_not?: Maybe<Scalars['String']>
  color_in?: Maybe<Array<Scalars['String']>>
  color_not_in?: Maybe<Array<Scalars['String']>>
  color_lt?: Maybe<Scalars['String']>
  color_lte?: Maybe<Scalars['String']>
  color_gt?: Maybe<Scalars['String']>
  color_gte?: Maybe<Scalars['String']>
  color_contains?: Maybe<Scalars['String']>
  color_not_contains?: Maybe<Scalars['String']>
  color_starts_with?: Maybe<Scalars['String']>
  color_not_starts_with?: Maybe<Scalars['String']>
  color_ends_with?: Maybe<Scalars['String']>
  color_not_ends_with?: Maybe<Scalars['String']>
  paint?: Maybe<Scalars['String']>
  paint_not?: Maybe<Scalars['String']>
  paint_in?: Maybe<Array<Scalars['String']>>
  paint_not_in?: Maybe<Array<Scalars['String']>>
  paint_lt?: Maybe<Scalars['String']>
  paint_lte?: Maybe<Scalars['String']>
  paint_gt?: Maybe<Scalars['String']>
  paint_gte?: Maybe<Scalars['String']>
  paint_contains?: Maybe<Scalars['String']>
  paint_not_contains?: Maybe<Scalars['String']>
  paint_starts_with?: Maybe<Scalars['String']>
  paint_not_starts_with?: Maybe<Scalars['String']>
  paint_ends_with?: Maybe<Scalars['String']>
  paint_not_ends_with?: Maybe<Scalars['String']>
  interiorColor?: Maybe<Scalars['String']>
  interiorColor_not?: Maybe<Scalars['String']>
  interiorColor_in?: Maybe<Array<Scalars['String']>>
  interiorColor_not_in?: Maybe<Array<Scalars['String']>>
  interiorColor_lt?: Maybe<Scalars['String']>
  interiorColor_lte?: Maybe<Scalars['String']>
  interiorColor_gt?: Maybe<Scalars['String']>
  interiorColor_gte?: Maybe<Scalars['String']>
  interiorColor_contains?: Maybe<Scalars['String']>
  interiorColor_not_contains?: Maybe<Scalars['String']>
  interiorColor_starts_with?: Maybe<Scalars['String']>
  interiorColor_not_starts_with?: Maybe<Scalars['String']>
  interiorColor_ends_with?: Maybe<Scalars['String']>
  interiorColor_not_ends_with?: Maybe<Scalars['String']>
  interiorType?: Maybe<Scalars['String']>
  interiorType_not?: Maybe<Scalars['String']>
  interiorType_in?: Maybe<Array<Scalars['String']>>
  interiorType_not_in?: Maybe<Array<Scalars['String']>>
  interiorType_lt?: Maybe<Scalars['String']>
  interiorType_lte?: Maybe<Scalars['String']>
  interiorType_gt?: Maybe<Scalars['String']>
  interiorType_gte?: Maybe<Scalars['String']>
  interiorType_contains?: Maybe<Scalars['String']>
  interiorType_not_contains?: Maybe<Scalars['String']>
  interiorType_starts_with?: Maybe<Scalars['String']>
  interiorType_not_starts_with?: Maybe<Scalars['String']>
  interiorType_ends_with?: Maybe<Scalars['String']>
  interiorType_not_ends_with?: Maybe<Scalars['String']>
  transmission?: Maybe<Scalars['String']>
  transmission_not?: Maybe<Scalars['String']>
  transmission_in?: Maybe<Array<Scalars['String']>>
  transmission_not_in?: Maybe<Array<Scalars['String']>>
  transmission_lt?: Maybe<Scalars['String']>
  transmission_lte?: Maybe<Scalars['String']>
  transmission_gt?: Maybe<Scalars['String']>
  transmission_gte?: Maybe<Scalars['String']>
  transmission_contains?: Maybe<Scalars['String']>
  transmission_not_contains?: Maybe<Scalars['String']>
  transmission_starts_with?: Maybe<Scalars['String']>
  transmission_not_starts_with?: Maybe<Scalars['String']>
  transmission_ends_with?: Maybe<Scalars['String']>
  transmission_not_ends_with?: Maybe<Scalars['String']>
  fuelType?: Maybe<Scalars['String']>
  fuelType_not?: Maybe<Scalars['String']>
  fuelType_in?: Maybe<Array<Scalars['String']>>
  fuelType_not_in?: Maybe<Array<Scalars['String']>>
  fuelType_lt?: Maybe<Scalars['String']>
  fuelType_lte?: Maybe<Scalars['String']>
  fuelType_gt?: Maybe<Scalars['String']>
  fuelType_gte?: Maybe<Scalars['String']>
  fuelType_contains?: Maybe<Scalars['String']>
  fuelType_not_contains?: Maybe<Scalars['String']>
  fuelType_starts_with?: Maybe<Scalars['String']>
  fuelType_not_starts_with?: Maybe<Scalars['String']>
  fuelType_ends_with?: Maybe<Scalars['String']>
  fuelType_not_ends_with?: Maybe<Scalars['String']>
  year?: Maybe<Scalars['String']>
  year_not?: Maybe<Scalars['String']>
  year_in?: Maybe<Array<Scalars['String']>>
  year_not_in?: Maybe<Array<Scalars['String']>>
  year_lt?: Maybe<Scalars['String']>
  year_lte?: Maybe<Scalars['String']>
  year_gt?: Maybe<Scalars['String']>
  year_gte?: Maybe<Scalars['String']>
  year_contains?: Maybe<Scalars['String']>
  year_not_contains?: Maybe<Scalars['String']>
  year_starts_with?: Maybe<Scalars['String']>
  year_not_starts_with?: Maybe<Scalars['String']>
  year_ends_with?: Maybe<Scalars['String']>
  year_not_ends_with?: Maybe<Scalars['String']>
  power?: Maybe<Scalars['String']>
  power_not?: Maybe<Scalars['String']>
  power_in?: Maybe<Array<Scalars['String']>>
  power_not_in?: Maybe<Array<Scalars['String']>>
  power_lt?: Maybe<Scalars['String']>
  power_lte?: Maybe<Scalars['String']>
  power_gt?: Maybe<Scalars['String']>
  power_gte?: Maybe<Scalars['String']>
  power_contains?: Maybe<Scalars['String']>
  power_not_contains?: Maybe<Scalars['String']>
  power_starts_with?: Maybe<Scalars['String']>
  power_not_starts_with?: Maybe<Scalars['String']>
  power_ends_with?: Maybe<Scalars['String']>
  power_not_ends_with?: Maybe<Scalars['String']>
  displacement?: Maybe<Scalars['String']>
  displacement_not?: Maybe<Scalars['String']>
  displacement_in?: Maybe<Array<Scalars['String']>>
  displacement_not_in?: Maybe<Array<Scalars['String']>>
  displacement_lt?: Maybe<Scalars['String']>
  displacement_lte?: Maybe<Scalars['String']>
  displacement_gt?: Maybe<Scalars['String']>
  displacement_gte?: Maybe<Scalars['String']>
  displacement_contains?: Maybe<Scalars['String']>
  displacement_not_contains?: Maybe<Scalars['String']>
  displacement_starts_with?: Maybe<Scalars['String']>
  displacement_not_starts_with?: Maybe<Scalars['String']>
  displacement_ends_with?: Maybe<Scalars['String']>
  displacement_not_ends_with?: Maybe<Scalars['String']>
  co2?: Maybe<Scalars['Int']>
  co2_not?: Maybe<Scalars['Int']>
  co2_in?: Maybe<Array<Scalars['Int']>>
  co2_not_in?: Maybe<Array<Scalars['Int']>>
  co2_lt?: Maybe<Scalars['Int']>
  co2_lte?: Maybe<Scalars['Int']>
  co2_gt?: Maybe<Scalars['Int']>
  co2_gte?: Maybe<Scalars['Int']>
  published?: Maybe<Scalars['Boolean']>
  published_not?: Maybe<Scalars['Boolean']>
  carPrice?: Maybe<Scalars['Int']>
  carPrice_not?: Maybe<Scalars['Int']>
  carPrice_in?: Maybe<Array<Scalars['Int']>>
  carPrice_not_in?: Maybe<Array<Scalars['Int']>>
  carPrice_lt?: Maybe<Scalars['Int']>
  carPrice_lte?: Maybe<Scalars['Int']>
  carPrice_gt?: Maybe<Scalars['Int']>
  carPrice_gte?: Maybe<Scalars['Int']>
  bpmPrice?: Maybe<Scalars['Int']>
  bpmPrice_not?: Maybe<Scalars['Int']>
  bpmPrice_in?: Maybe<Array<Scalars['Int']>>
  bpmPrice_not_in?: Maybe<Array<Scalars['Int']>>
  bpmPrice_lt?: Maybe<Scalars['Int']>
  bpmPrice_lte?: Maybe<Scalars['Int']>
  bpmPrice_gt?: Maybe<Scalars['Int']>
  bpmPrice_gte?: Maybe<Scalars['Int']>
  saving?: Maybe<Scalars['Int']>
  saving_not?: Maybe<Scalars['Int']>
  saving_in?: Maybe<Array<Scalars['Int']>>
  saving_not_in?: Maybe<Array<Scalars['Int']>>
  saving_lt?: Maybe<Scalars['Int']>
  saving_lte?: Maybe<Scalars['Int']>
  saving_gt?: Maybe<Scalars['Int']>
  saving_gte?: Maybe<Scalars['Int']>
  AND?: Maybe<Array<CarScalarWhereInput>>
  OR?: Maybe<Array<CarScalarWhereInput>>
  NOT?: Maybe<Array<CarScalarWhereInput>>
}

export type CarUpdateInput = {
  deletedAt?: Maybe<Scalars['DateTime']>
  order?: Maybe<OrderUpdateOneWithoutCarInput>
  brand?: Maybe<BrandUpdateOneRequiredWithoutCarsInput>
  model?: Maybe<Scalars['String']>
  version?: Maybe<Scalars['String']>
  color?: Maybe<Scalars['String']>
  paint?: Maybe<Scalars['String']>
  interiorColor?: Maybe<Scalars['String']>
  interiorType?: Maybe<Scalars['String']>
  transmission?: Maybe<Scalars['String']>
  fuelType?: Maybe<Scalars['String']>
  year?: Maybe<Scalars['String']>
  power?: Maybe<Scalars['String']>
  displacement?: Maybe<Scalars['String']>
  co2?: Maybe<Scalars['Int']>
  images?: Maybe<FileUpdateManyInput>
  published?: Maybe<Scalars['Boolean']>
  carPrice?: Maybe<Scalars['Int']>
  bpmPrice?: Maybe<Scalars['Int']>
  saving?: Maybe<Scalars['Int']>
}

export type CarUpdateManyDataInput = {
  deletedAt?: Maybe<Scalars['DateTime']>
  model?: Maybe<Scalars['String']>
  version?: Maybe<Scalars['String']>
  color?: Maybe<Scalars['String']>
  paint?: Maybe<Scalars['String']>
  interiorColor?: Maybe<Scalars['String']>
  interiorType?: Maybe<Scalars['String']>
  transmission?: Maybe<Scalars['String']>
  fuelType?: Maybe<Scalars['String']>
  year?: Maybe<Scalars['String']>
  power?: Maybe<Scalars['String']>
  displacement?: Maybe<Scalars['String']>
  co2?: Maybe<Scalars['Int']>
  published?: Maybe<Scalars['Boolean']>
  carPrice?: Maybe<Scalars['Int']>
  bpmPrice?: Maybe<Scalars['Int']>
  saving?: Maybe<Scalars['Int']>
}

export type CarUpdateManyWithoutBrandInput = {
  create?: Maybe<Array<CarCreateWithoutBrandInput>>
  delete?: Maybe<Array<CarWhereUniqueInput>>
  connect?: Maybe<Array<CarWhereUniqueInput>>
  set?: Maybe<Array<CarWhereUniqueInput>>
  disconnect?: Maybe<Array<CarWhereUniqueInput>>
  update?: Maybe<Array<CarUpdateWithWhereUniqueWithoutBrandInput>>
  upsert?: Maybe<Array<CarUpsertWithWhereUniqueWithoutBrandInput>>
  deleteMany?: Maybe<Array<CarScalarWhereInput>>
  updateMany?: Maybe<Array<CarUpdateManyWithWhereNestedInput>>
}

export type CarUpdateManyWithWhereNestedInput = {
  where: CarScalarWhereInput
  data: CarUpdateManyDataInput
}

export type CarUpdateOneWithoutOrderInput = {
  create?: Maybe<CarCreateWithoutOrderInput>
  update?: Maybe<CarUpdateWithoutOrderDataInput>
  upsert?: Maybe<CarUpsertWithoutOrderInput>
  delete?: Maybe<Scalars['Boolean']>
  disconnect?: Maybe<Scalars['Boolean']>
  connect?: Maybe<CarWhereUniqueInput>
}

export type CarUpdateWithoutBrandDataInput = {
  deletedAt?: Maybe<Scalars['DateTime']>
  order?: Maybe<OrderUpdateOneWithoutCarInput>
  model?: Maybe<Scalars['String']>
  version?: Maybe<Scalars['String']>
  color?: Maybe<Scalars['String']>
  paint?: Maybe<Scalars['String']>
  interiorColor?: Maybe<Scalars['String']>
  interiorType?: Maybe<Scalars['String']>
  transmission?: Maybe<Scalars['String']>
  fuelType?: Maybe<Scalars['String']>
  year?: Maybe<Scalars['String']>
  power?: Maybe<Scalars['String']>
  displacement?: Maybe<Scalars['String']>
  co2?: Maybe<Scalars['Int']>
  images?: Maybe<FileUpdateManyInput>
  published?: Maybe<Scalars['Boolean']>
  carPrice?: Maybe<Scalars['Int']>
  bpmPrice?: Maybe<Scalars['Int']>
  saving?: Maybe<Scalars['Int']>
}

export type CarUpdateWithoutOrderDataInput = {
  deletedAt?: Maybe<Scalars['DateTime']>
  brand?: Maybe<BrandUpdateOneRequiredWithoutCarsInput>
  model?: Maybe<Scalars['String']>
  version?: Maybe<Scalars['String']>
  color?: Maybe<Scalars['String']>
  paint?: Maybe<Scalars['String']>
  interiorColor?: Maybe<Scalars['String']>
  interiorType?: Maybe<Scalars['String']>
  transmission?: Maybe<Scalars['String']>
  fuelType?: Maybe<Scalars['String']>
  year?: Maybe<Scalars['String']>
  power?: Maybe<Scalars['String']>
  displacement?: Maybe<Scalars['String']>
  co2?: Maybe<Scalars['Int']>
  images?: Maybe<FileUpdateManyInput>
  published?: Maybe<Scalars['Boolean']>
  carPrice?: Maybe<Scalars['Int']>
  bpmPrice?: Maybe<Scalars['Int']>
  saving?: Maybe<Scalars['Int']>
}

export type CarUpdateWithWhereUniqueWithoutBrandInput = {
  where: CarWhereUniqueInput
  data: CarUpdateWithoutBrandDataInput
}

export type CarUpsertWithoutOrderInput = {
  update: CarUpdateWithoutOrderDataInput
  create: CarCreateWithoutOrderInput
}

export type CarUpsertWithWhereUniqueWithoutBrandInput = {
  where: CarWhereUniqueInput
  update: CarUpdateWithoutBrandDataInput
  create: CarCreateWithoutBrandInput
}

export type CarWhereInput = {
  id?: Maybe<Scalars['ID']>
  id_not?: Maybe<Scalars['ID']>
  id_in?: Maybe<Array<Scalars['ID']>>
  id_not_in?: Maybe<Array<Scalars['ID']>>
  id_lt?: Maybe<Scalars['ID']>
  id_lte?: Maybe<Scalars['ID']>
  id_gt?: Maybe<Scalars['ID']>
  id_gte?: Maybe<Scalars['ID']>
  id_contains?: Maybe<Scalars['ID']>
  id_not_contains?: Maybe<Scalars['ID']>
  id_starts_with?: Maybe<Scalars['ID']>
  id_not_starts_with?: Maybe<Scalars['ID']>
  id_ends_with?: Maybe<Scalars['ID']>
  id_not_ends_with?: Maybe<Scalars['ID']>
  createdAt?: Maybe<Scalars['DateTime']>
  createdAt_not?: Maybe<Scalars['DateTime']>
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>
  createdAt_lt?: Maybe<Scalars['DateTime']>
  createdAt_lte?: Maybe<Scalars['DateTime']>
  createdAt_gt?: Maybe<Scalars['DateTime']>
  createdAt_gte?: Maybe<Scalars['DateTime']>
  updatedAt?: Maybe<Scalars['DateTime']>
  updatedAt_not?: Maybe<Scalars['DateTime']>
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>
  updatedAt_lt?: Maybe<Scalars['DateTime']>
  updatedAt_lte?: Maybe<Scalars['DateTime']>
  updatedAt_gt?: Maybe<Scalars['DateTime']>
  updatedAt_gte?: Maybe<Scalars['DateTime']>
  deletedAt?: Maybe<Scalars['DateTime']>
  deletedAt_not?: Maybe<Scalars['DateTime']>
  deletedAt_in?: Maybe<Array<Scalars['DateTime']>>
  deletedAt_not_in?: Maybe<Array<Scalars['DateTime']>>
  deletedAt_lt?: Maybe<Scalars['DateTime']>
  deletedAt_lte?: Maybe<Scalars['DateTime']>
  deletedAt_gt?: Maybe<Scalars['DateTime']>
  deletedAt_gte?: Maybe<Scalars['DateTime']>
  order?: Maybe<OrderWhereInput>
  brand?: Maybe<BrandWhereInput>
  model?: Maybe<Scalars['String']>
  model_not?: Maybe<Scalars['String']>
  model_in?: Maybe<Array<Scalars['String']>>
  model_not_in?: Maybe<Array<Scalars['String']>>
  model_lt?: Maybe<Scalars['String']>
  model_lte?: Maybe<Scalars['String']>
  model_gt?: Maybe<Scalars['String']>
  model_gte?: Maybe<Scalars['String']>
  model_contains?: Maybe<Scalars['String']>
  model_not_contains?: Maybe<Scalars['String']>
  model_starts_with?: Maybe<Scalars['String']>
  model_not_starts_with?: Maybe<Scalars['String']>
  model_ends_with?: Maybe<Scalars['String']>
  model_not_ends_with?: Maybe<Scalars['String']>
  version?: Maybe<Scalars['String']>
  version_not?: Maybe<Scalars['String']>
  version_in?: Maybe<Array<Scalars['String']>>
  version_not_in?: Maybe<Array<Scalars['String']>>
  version_lt?: Maybe<Scalars['String']>
  version_lte?: Maybe<Scalars['String']>
  version_gt?: Maybe<Scalars['String']>
  version_gte?: Maybe<Scalars['String']>
  version_contains?: Maybe<Scalars['String']>
  version_not_contains?: Maybe<Scalars['String']>
  version_starts_with?: Maybe<Scalars['String']>
  version_not_starts_with?: Maybe<Scalars['String']>
  version_ends_with?: Maybe<Scalars['String']>
  version_not_ends_with?: Maybe<Scalars['String']>
  color?: Maybe<Scalars['String']>
  color_not?: Maybe<Scalars['String']>
  color_in?: Maybe<Array<Scalars['String']>>
  color_not_in?: Maybe<Array<Scalars['String']>>
  color_lt?: Maybe<Scalars['String']>
  color_lte?: Maybe<Scalars['String']>
  color_gt?: Maybe<Scalars['String']>
  color_gte?: Maybe<Scalars['String']>
  color_contains?: Maybe<Scalars['String']>
  color_not_contains?: Maybe<Scalars['String']>
  color_starts_with?: Maybe<Scalars['String']>
  color_not_starts_with?: Maybe<Scalars['String']>
  color_ends_with?: Maybe<Scalars['String']>
  color_not_ends_with?: Maybe<Scalars['String']>
  paint?: Maybe<Scalars['String']>
  paint_not?: Maybe<Scalars['String']>
  paint_in?: Maybe<Array<Scalars['String']>>
  paint_not_in?: Maybe<Array<Scalars['String']>>
  paint_lt?: Maybe<Scalars['String']>
  paint_lte?: Maybe<Scalars['String']>
  paint_gt?: Maybe<Scalars['String']>
  paint_gte?: Maybe<Scalars['String']>
  paint_contains?: Maybe<Scalars['String']>
  paint_not_contains?: Maybe<Scalars['String']>
  paint_starts_with?: Maybe<Scalars['String']>
  paint_not_starts_with?: Maybe<Scalars['String']>
  paint_ends_with?: Maybe<Scalars['String']>
  paint_not_ends_with?: Maybe<Scalars['String']>
  interiorColor?: Maybe<Scalars['String']>
  interiorColor_not?: Maybe<Scalars['String']>
  interiorColor_in?: Maybe<Array<Scalars['String']>>
  interiorColor_not_in?: Maybe<Array<Scalars['String']>>
  interiorColor_lt?: Maybe<Scalars['String']>
  interiorColor_lte?: Maybe<Scalars['String']>
  interiorColor_gt?: Maybe<Scalars['String']>
  interiorColor_gte?: Maybe<Scalars['String']>
  interiorColor_contains?: Maybe<Scalars['String']>
  interiorColor_not_contains?: Maybe<Scalars['String']>
  interiorColor_starts_with?: Maybe<Scalars['String']>
  interiorColor_not_starts_with?: Maybe<Scalars['String']>
  interiorColor_ends_with?: Maybe<Scalars['String']>
  interiorColor_not_ends_with?: Maybe<Scalars['String']>
  interiorType?: Maybe<Scalars['String']>
  interiorType_not?: Maybe<Scalars['String']>
  interiorType_in?: Maybe<Array<Scalars['String']>>
  interiorType_not_in?: Maybe<Array<Scalars['String']>>
  interiorType_lt?: Maybe<Scalars['String']>
  interiorType_lte?: Maybe<Scalars['String']>
  interiorType_gt?: Maybe<Scalars['String']>
  interiorType_gte?: Maybe<Scalars['String']>
  interiorType_contains?: Maybe<Scalars['String']>
  interiorType_not_contains?: Maybe<Scalars['String']>
  interiorType_starts_with?: Maybe<Scalars['String']>
  interiorType_not_starts_with?: Maybe<Scalars['String']>
  interiorType_ends_with?: Maybe<Scalars['String']>
  interiorType_not_ends_with?: Maybe<Scalars['String']>
  transmission?: Maybe<Scalars['String']>
  transmission_not?: Maybe<Scalars['String']>
  transmission_in?: Maybe<Array<Scalars['String']>>
  transmission_not_in?: Maybe<Array<Scalars['String']>>
  transmission_lt?: Maybe<Scalars['String']>
  transmission_lte?: Maybe<Scalars['String']>
  transmission_gt?: Maybe<Scalars['String']>
  transmission_gte?: Maybe<Scalars['String']>
  transmission_contains?: Maybe<Scalars['String']>
  transmission_not_contains?: Maybe<Scalars['String']>
  transmission_starts_with?: Maybe<Scalars['String']>
  transmission_not_starts_with?: Maybe<Scalars['String']>
  transmission_ends_with?: Maybe<Scalars['String']>
  transmission_not_ends_with?: Maybe<Scalars['String']>
  fuelType?: Maybe<Scalars['String']>
  fuelType_not?: Maybe<Scalars['String']>
  fuelType_in?: Maybe<Array<Scalars['String']>>
  fuelType_not_in?: Maybe<Array<Scalars['String']>>
  fuelType_lt?: Maybe<Scalars['String']>
  fuelType_lte?: Maybe<Scalars['String']>
  fuelType_gt?: Maybe<Scalars['String']>
  fuelType_gte?: Maybe<Scalars['String']>
  fuelType_contains?: Maybe<Scalars['String']>
  fuelType_not_contains?: Maybe<Scalars['String']>
  fuelType_starts_with?: Maybe<Scalars['String']>
  fuelType_not_starts_with?: Maybe<Scalars['String']>
  fuelType_ends_with?: Maybe<Scalars['String']>
  fuelType_not_ends_with?: Maybe<Scalars['String']>
  year?: Maybe<Scalars['String']>
  year_not?: Maybe<Scalars['String']>
  year_in?: Maybe<Array<Scalars['String']>>
  year_not_in?: Maybe<Array<Scalars['String']>>
  year_lt?: Maybe<Scalars['String']>
  year_lte?: Maybe<Scalars['String']>
  year_gt?: Maybe<Scalars['String']>
  year_gte?: Maybe<Scalars['String']>
  year_contains?: Maybe<Scalars['String']>
  year_not_contains?: Maybe<Scalars['String']>
  year_starts_with?: Maybe<Scalars['String']>
  year_not_starts_with?: Maybe<Scalars['String']>
  year_ends_with?: Maybe<Scalars['String']>
  year_not_ends_with?: Maybe<Scalars['String']>
  power?: Maybe<Scalars['String']>
  power_not?: Maybe<Scalars['String']>
  power_in?: Maybe<Array<Scalars['String']>>
  power_not_in?: Maybe<Array<Scalars['String']>>
  power_lt?: Maybe<Scalars['String']>
  power_lte?: Maybe<Scalars['String']>
  power_gt?: Maybe<Scalars['String']>
  power_gte?: Maybe<Scalars['String']>
  power_contains?: Maybe<Scalars['String']>
  power_not_contains?: Maybe<Scalars['String']>
  power_starts_with?: Maybe<Scalars['String']>
  power_not_starts_with?: Maybe<Scalars['String']>
  power_ends_with?: Maybe<Scalars['String']>
  power_not_ends_with?: Maybe<Scalars['String']>
  displacement?: Maybe<Scalars['String']>
  displacement_not?: Maybe<Scalars['String']>
  displacement_in?: Maybe<Array<Scalars['String']>>
  displacement_not_in?: Maybe<Array<Scalars['String']>>
  displacement_lt?: Maybe<Scalars['String']>
  displacement_lte?: Maybe<Scalars['String']>
  displacement_gt?: Maybe<Scalars['String']>
  displacement_gte?: Maybe<Scalars['String']>
  displacement_contains?: Maybe<Scalars['String']>
  displacement_not_contains?: Maybe<Scalars['String']>
  displacement_starts_with?: Maybe<Scalars['String']>
  displacement_not_starts_with?: Maybe<Scalars['String']>
  displacement_ends_with?: Maybe<Scalars['String']>
  displacement_not_ends_with?: Maybe<Scalars['String']>
  co2?: Maybe<Scalars['Int']>
  co2_not?: Maybe<Scalars['Int']>
  co2_in?: Maybe<Array<Scalars['Int']>>
  co2_not_in?: Maybe<Array<Scalars['Int']>>
  co2_lt?: Maybe<Scalars['Int']>
  co2_lte?: Maybe<Scalars['Int']>
  co2_gt?: Maybe<Scalars['Int']>
  co2_gte?: Maybe<Scalars['Int']>
  images_every?: Maybe<FileWhereInput>
  images_some?: Maybe<FileWhereInput>
  images_none?: Maybe<FileWhereInput>
  published?: Maybe<Scalars['Boolean']>
  published_not?: Maybe<Scalars['Boolean']>
  carPrice?: Maybe<Scalars['Int']>
  carPrice_not?: Maybe<Scalars['Int']>
  carPrice_in?: Maybe<Array<Scalars['Int']>>
  carPrice_not_in?: Maybe<Array<Scalars['Int']>>
  carPrice_lt?: Maybe<Scalars['Int']>
  carPrice_lte?: Maybe<Scalars['Int']>
  carPrice_gt?: Maybe<Scalars['Int']>
  carPrice_gte?: Maybe<Scalars['Int']>
  bpmPrice?: Maybe<Scalars['Int']>
  bpmPrice_not?: Maybe<Scalars['Int']>
  bpmPrice_in?: Maybe<Array<Scalars['Int']>>
  bpmPrice_not_in?: Maybe<Array<Scalars['Int']>>
  bpmPrice_lt?: Maybe<Scalars['Int']>
  bpmPrice_lte?: Maybe<Scalars['Int']>
  bpmPrice_gt?: Maybe<Scalars['Int']>
  bpmPrice_gte?: Maybe<Scalars['Int']>
  saving?: Maybe<Scalars['Int']>
  saving_not?: Maybe<Scalars['Int']>
  saving_in?: Maybe<Array<Scalars['Int']>>
  saving_not_in?: Maybe<Array<Scalars['Int']>>
  saving_lt?: Maybe<Scalars['Int']>
  saving_lte?: Maybe<Scalars['Int']>
  saving_gt?: Maybe<Scalars['Int']>
  saving_gte?: Maybe<Scalars['Int']>
  AND?: Maybe<Array<CarWhereInput>>
  OR?: Maybe<Array<CarWhereInput>>
  NOT?: Maybe<Array<CarWhereInput>>
}

export type CarWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>
}

export type Client = {
  __typename?: 'Client'
  id: Scalars['ID']
  createdAt: Scalars['DateTime']
  updatedAt: Scalars['DateTime']
  deletedAt?: Maybe<Scalars['DateTime']>
  name: Scalars['String']
  email: Scalars['String']
  orders?: Maybe<Array<Order>>
  searchRequests?: Maybe<Array<SearchRequest>>
  telephone: Scalars['String']
  address?: Maybe<Address>
  clientType?: Maybe<ClientType>
  vatNumber?: Maybe<Scalars['String']>
  cocNumber?: Maybe<Scalars['String']>
  identification?: Maybe<File>
}

export type ClientOrdersArgs = {
  where?: Maybe<OrderWhereInput>
  orderBy?: Maybe<OrderOrderByInput>
  skip?: Maybe<Scalars['Int']>
  after?: Maybe<Scalars['String']>
  before?: Maybe<Scalars['String']>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
}

export type ClientSearchRequestsArgs = {
  where?: Maybe<SearchRequestWhereInput>
  orderBy?: Maybe<SearchRequestOrderByInput>
  skip?: Maybe<Scalars['Int']>
  after?: Maybe<Scalars['String']>
  before?: Maybe<Scalars['String']>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
}

export type ClientAuthPayload = {
  __typename?: 'ClientAuthPayload'
  client?: Maybe<Client>
}

export type ClientCreateInput = {
  id?: Maybe<Scalars['ID']>
  deletedAt?: Maybe<Scalars['DateTime']>
  name: Scalars['String']
  email: Scalars['String']
  orders?: Maybe<OrderCreateManyWithoutClientInput>
  searchRequests?: Maybe<SearchRequestCreateManyWithoutClientInput>
  telephone: Scalars['String']
  address?: Maybe<AddressCreateOneInput>
  clientType?: Maybe<ClientType>
  vatNumber?: Maybe<Scalars['String']>
  cocNumber?: Maybe<Scalars['String']>
  identification?: Maybe<FileCreateOneInput>
}

export type ClientCreateOneInput = {
  create?: Maybe<ClientCreateInput>
  connect?: Maybe<ClientWhereUniqueInput>
}

export type ClientCreateOneWithoutOrdersInput = {
  create?: Maybe<ClientCreateWithoutOrdersInput>
  connect?: Maybe<ClientWhereUniqueInput>
}

export type ClientCreateWithoutOrdersInput = {
  id?: Maybe<Scalars['ID']>
  deletedAt?: Maybe<Scalars['DateTime']>
  name: Scalars['String']
  email: Scalars['String']
  searchRequests?: Maybe<SearchRequestCreateManyWithoutClientInput>
  telephone: Scalars['String']
  address?: Maybe<AddressCreateOneInput>
  clientType?: Maybe<ClientType>
  vatNumber?: Maybe<Scalars['String']>
  cocNumber?: Maybe<Scalars['String']>
  identification?: Maybe<FileCreateOneInput>
}

export enum ClientOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  EmailAsc = 'email_ASC',
  EmailDesc = 'email_DESC',
  TelephoneAsc = 'telephone_ASC',
  TelephoneDesc = 'telephone_DESC',
  ClientTypeAsc = 'clientType_ASC',
  ClientTypeDesc = 'clientType_DESC',
  VatNumberAsc = 'vatNumber_ASC',
  VatNumberDesc = 'vatNumber_DESC',
  CocNumberAsc = 'cocNumber_ASC',
  CocNumberDesc = 'cocNumber_DESC',
}

export enum ClientType {
  Private = 'PRIVATE',
  Business = 'BUSINESS',
}

export type ClientUpdateDataInput = {
  deletedAt?: Maybe<Scalars['DateTime']>
  name?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['String']>
  orders?: Maybe<OrderUpdateManyWithoutClientInput>
  searchRequests?: Maybe<SearchRequestUpdateManyWithoutClientInput>
  telephone?: Maybe<Scalars['String']>
  address?: Maybe<AddressUpdateOneInput>
  clientType?: Maybe<ClientType>
  vatNumber?: Maybe<Scalars['String']>
  cocNumber?: Maybe<Scalars['String']>
  identification?: Maybe<FileUpdateOneInput>
}

export type ClientUpdateInput = {
  deletedAt?: Maybe<Scalars['DateTime']>
  name?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['String']>
  orders?: Maybe<OrderUpdateManyWithoutClientInput>
  searchRequests?: Maybe<SearchRequestUpdateManyWithoutClientInput>
  telephone?: Maybe<Scalars['String']>
  address?: Maybe<AddressUpdateOneInput>
  clientType?: Maybe<ClientType>
  vatNumber?: Maybe<Scalars['String']>
  cocNumber?: Maybe<Scalars['String']>
  identification?: Maybe<FileUpdateOneInput>
}

export type ClientUpdateOneInput = {
  create?: Maybe<ClientCreateInput>
  update?: Maybe<ClientUpdateDataInput>
  upsert?: Maybe<ClientUpsertNestedInput>
  delete?: Maybe<Scalars['Boolean']>
  disconnect?: Maybe<Scalars['Boolean']>
  connect?: Maybe<ClientWhereUniqueInput>
}

export type ClientUpdateOneRequiredWithoutOrdersInput = {
  create?: Maybe<ClientCreateWithoutOrdersInput>
  update?: Maybe<ClientUpdateWithoutOrdersDataInput>
  upsert?: Maybe<ClientUpsertWithoutOrdersInput>
  connect?: Maybe<ClientWhereUniqueInput>
}

export type ClientUpdateWithoutOrdersDataInput = {
  deletedAt?: Maybe<Scalars['DateTime']>
  name?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['String']>
  searchRequests?: Maybe<SearchRequestUpdateManyWithoutClientInput>
  telephone?: Maybe<Scalars['String']>
  address?: Maybe<AddressUpdateOneInput>
  clientType?: Maybe<ClientType>
  vatNumber?: Maybe<Scalars['String']>
  cocNumber?: Maybe<Scalars['String']>
  identification?: Maybe<FileUpdateOneInput>
}

export type ClientUpsertNestedInput = {
  update: ClientUpdateDataInput
  create: ClientCreateInput
}

export type ClientUpsertWithoutOrdersInput = {
  update: ClientUpdateWithoutOrdersDataInput
  create: ClientCreateWithoutOrdersInput
}

export type ClientWhereInput = {
  id?: Maybe<Scalars['ID']>
  id_not?: Maybe<Scalars['ID']>
  id_in?: Maybe<Array<Scalars['ID']>>
  id_not_in?: Maybe<Array<Scalars['ID']>>
  id_lt?: Maybe<Scalars['ID']>
  id_lte?: Maybe<Scalars['ID']>
  id_gt?: Maybe<Scalars['ID']>
  id_gte?: Maybe<Scalars['ID']>
  id_contains?: Maybe<Scalars['ID']>
  id_not_contains?: Maybe<Scalars['ID']>
  id_starts_with?: Maybe<Scalars['ID']>
  id_not_starts_with?: Maybe<Scalars['ID']>
  id_ends_with?: Maybe<Scalars['ID']>
  id_not_ends_with?: Maybe<Scalars['ID']>
  createdAt?: Maybe<Scalars['DateTime']>
  createdAt_not?: Maybe<Scalars['DateTime']>
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>
  createdAt_lt?: Maybe<Scalars['DateTime']>
  createdAt_lte?: Maybe<Scalars['DateTime']>
  createdAt_gt?: Maybe<Scalars['DateTime']>
  createdAt_gte?: Maybe<Scalars['DateTime']>
  updatedAt?: Maybe<Scalars['DateTime']>
  updatedAt_not?: Maybe<Scalars['DateTime']>
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>
  updatedAt_lt?: Maybe<Scalars['DateTime']>
  updatedAt_lte?: Maybe<Scalars['DateTime']>
  updatedAt_gt?: Maybe<Scalars['DateTime']>
  updatedAt_gte?: Maybe<Scalars['DateTime']>
  deletedAt?: Maybe<Scalars['DateTime']>
  deletedAt_not?: Maybe<Scalars['DateTime']>
  deletedAt_in?: Maybe<Array<Scalars['DateTime']>>
  deletedAt_not_in?: Maybe<Array<Scalars['DateTime']>>
  deletedAt_lt?: Maybe<Scalars['DateTime']>
  deletedAt_lte?: Maybe<Scalars['DateTime']>
  deletedAt_gt?: Maybe<Scalars['DateTime']>
  deletedAt_gte?: Maybe<Scalars['DateTime']>
  name?: Maybe<Scalars['String']>
  name_not?: Maybe<Scalars['String']>
  name_in?: Maybe<Array<Scalars['String']>>
  name_not_in?: Maybe<Array<Scalars['String']>>
  name_lt?: Maybe<Scalars['String']>
  name_lte?: Maybe<Scalars['String']>
  name_gt?: Maybe<Scalars['String']>
  name_gte?: Maybe<Scalars['String']>
  name_contains?: Maybe<Scalars['String']>
  name_not_contains?: Maybe<Scalars['String']>
  name_starts_with?: Maybe<Scalars['String']>
  name_not_starts_with?: Maybe<Scalars['String']>
  name_ends_with?: Maybe<Scalars['String']>
  name_not_ends_with?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['String']>
  email_not?: Maybe<Scalars['String']>
  email_in?: Maybe<Array<Scalars['String']>>
  email_not_in?: Maybe<Array<Scalars['String']>>
  email_lt?: Maybe<Scalars['String']>
  email_lte?: Maybe<Scalars['String']>
  email_gt?: Maybe<Scalars['String']>
  email_gte?: Maybe<Scalars['String']>
  email_contains?: Maybe<Scalars['String']>
  email_not_contains?: Maybe<Scalars['String']>
  email_starts_with?: Maybe<Scalars['String']>
  email_not_starts_with?: Maybe<Scalars['String']>
  email_ends_with?: Maybe<Scalars['String']>
  email_not_ends_with?: Maybe<Scalars['String']>
  orders_every?: Maybe<OrderWhereInput>
  orders_some?: Maybe<OrderWhereInput>
  orders_none?: Maybe<OrderWhereInput>
  searchRequests_every?: Maybe<SearchRequestWhereInput>
  searchRequests_some?: Maybe<SearchRequestWhereInput>
  searchRequests_none?: Maybe<SearchRequestWhereInput>
  telephone?: Maybe<Scalars['String']>
  telephone_not?: Maybe<Scalars['String']>
  telephone_in?: Maybe<Array<Scalars['String']>>
  telephone_not_in?: Maybe<Array<Scalars['String']>>
  telephone_lt?: Maybe<Scalars['String']>
  telephone_lte?: Maybe<Scalars['String']>
  telephone_gt?: Maybe<Scalars['String']>
  telephone_gte?: Maybe<Scalars['String']>
  telephone_contains?: Maybe<Scalars['String']>
  telephone_not_contains?: Maybe<Scalars['String']>
  telephone_starts_with?: Maybe<Scalars['String']>
  telephone_not_starts_with?: Maybe<Scalars['String']>
  telephone_ends_with?: Maybe<Scalars['String']>
  telephone_not_ends_with?: Maybe<Scalars['String']>
  address?: Maybe<AddressWhereInput>
  clientType?: Maybe<ClientType>
  clientType_not?: Maybe<ClientType>
  clientType_in?: Maybe<Array<ClientType>>
  clientType_not_in?: Maybe<Array<ClientType>>
  vatNumber?: Maybe<Scalars['String']>
  vatNumber_not?: Maybe<Scalars['String']>
  vatNumber_in?: Maybe<Array<Scalars['String']>>
  vatNumber_not_in?: Maybe<Array<Scalars['String']>>
  vatNumber_lt?: Maybe<Scalars['String']>
  vatNumber_lte?: Maybe<Scalars['String']>
  vatNumber_gt?: Maybe<Scalars['String']>
  vatNumber_gte?: Maybe<Scalars['String']>
  vatNumber_contains?: Maybe<Scalars['String']>
  vatNumber_not_contains?: Maybe<Scalars['String']>
  vatNumber_starts_with?: Maybe<Scalars['String']>
  vatNumber_not_starts_with?: Maybe<Scalars['String']>
  vatNumber_ends_with?: Maybe<Scalars['String']>
  vatNumber_not_ends_with?: Maybe<Scalars['String']>
  cocNumber?: Maybe<Scalars['String']>
  cocNumber_not?: Maybe<Scalars['String']>
  cocNumber_in?: Maybe<Array<Scalars['String']>>
  cocNumber_not_in?: Maybe<Array<Scalars['String']>>
  cocNumber_lt?: Maybe<Scalars['String']>
  cocNumber_lte?: Maybe<Scalars['String']>
  cocNumber_gt?: Maybe<Scalars['String']>
  cocNumber_gte?: Maybe<Scalars['String']>
  cocNumber_contains?: Maybe<Scalars['String']>
  cocNumber_not_contains?: Maybe<Scalars['String']>
  cocNumber_starts_with?: Maybe<Scalars['String']>
  cocNumber_not_starts_with?: Maybe<Scalars['String']>
  cocNumber_ends_with?: Maybe<Scalars['String']>
  cocNumber_not_ends_with?: Maybe<Scalars['String']>
  identification?: Maybe<FileWhereInput>
  AND?: Maybe<Array<ClientWhereInput>>
  OR?: Maybe<Array<ClientWhereInput>>
  NOT?: Maybe<Array<ClientWhereInput>>
}

export type ClientWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>
  email?: Maybe<Scalars['String']>
}

export type FeaturedCar = {
  __typename?: 'FeaturedCar'
  id?: Maybe<Scalars['ID']>
  brand?: Maybe<Brand>
  model?: Maybe<Scalars['String']>
  version?: Maybe<Scalars['String']>
  images?: Maybe<Array<File>>
  carPrice?: Maybe<Scalars['Int']>
  saving?: Maybe<Scalars['Int']>
  bpmPrice?: Maybe<Scalars['Int']>
}

export type File = {
  __typename?: 'File'
  id: Scalars['ID']
  createdAt: Scalars['DateTime']
  updatedAt: Scalars['DateTime']
  filename: Scalars['String']
  mimetype: Scalars['String']
  encoding: Scalars['String']
  location: Scalars['String']
}

export type FileCreateInput = {
  id?: Maybe<Scalars['ID']>
  filename: Scalars['String']
  mimetype: Scalars['String']
  encoding: Scalars['String']
  location: Scalars['String']
}

export type FileCreateManyInput = {
  create?: Maybe<Array<FileCreateInput>>
  connect?: Maybe<Array<FileWhereUniqueInput>>
}

export type FileCreateOneInput = {
  create?: Maybe<FileCreateInput>
  connect?: Maybe<FileWhereUniqueInput>
}

export enum FileOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  FilenameAsc = 'filename_ASC',
  FilenameDesc = 'filename_DESC',
  MimetypeAsc = 'mimetype_ASC',
  MimetypeDesc = 'mimetype_DESC',
  EncodingAsc = 'encoding_ASC',
  EncodingDesc = 'encoding_DESC',
  LocationAsc = 'location_ASC',
  LocationDesc = 'location_DESC',
}

export type FileScalarWhereInput = {
  id?: Maybe<Scalars['ID']>
  id_not?: Maybe<Scalars['ID']>
  id_in?: Maybe<Array<Scalars['ID']>>
  id_not_in?: Maybe<Array<Scalars['ID']>>
  id_lt?: Maybe<Scalars['ID']>
  id_lte?: Maybe<Scalars['ID']>
  id_gt?: Maybe<Scalars['ID']>
  id_gte?: Maybe<Scalars['ID']>
  id_contains?: Maybe<Scalars['ID']>
  id_not_contains?: Maybe<Scalars['ID']>
  id_starts_with?: Maybe<Scalars['ID']>
  id_not_starts_with?: Maybe<Scalars['ID']>
  id_ends_with?: Maybe<Scalars['ID']>
  id_not_ends_with?: Maybe<Scalars['ID']>
  createdAt?: Maybe<Scalars['DateTime']>
  createdAt_not?: Maybe<Scalars['DateTime']>
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>
  createdAt_lt?: Maybe<Scalars['DateTime']>
  createdAt_lte?: Maybe<Scalars['DateTime']>
  createdAt_gt?: Maybe<Scalars['DateTime']>
  createdAt_gte?: Maybe<Scalars['DateTime']>
  updatedAt?: Maybe<Scalars['DateTime']>
  updatedAt_not?: Maybe<Scalars['DateTime']>
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>
  updatedAt_lt?: Maybe<Scalars['DateTime']>
  updatedAt_lte?: Maybe<Scalars['DateTime']>
  updatedAt_gt?: Maybe<Scalars['DateTime']>
  updatedAt_gte?: Maybe<Scalars['DateTime']>
  filename?: Maybe<Scalars['String']>
  filename_not?: Maybe<Scalars['String']>
  filename_in?: Maybe<Array<Scalars['String']>>
  filename_not_in?: Maybe<Array<Scalars['String']>>
  filename_lt?: Maybe<Scalars['String']>
  filename_lte?: Maybe<Scalars['String']>
  filename_gt?: Maybe<Scalars['String']>
  filename_gte?: Maybe<Scalars['String']>
  filename_contains?: Maybe<Scalars['String']>
  filename_not_contains?: Maybe<Scalars['String']>
  filename_starts_with?: Maybe<Scalars['String']>
  filename_not_starts_with?: Maybe<Scalars['String']>
  filename_ends_with?: Maybe<Scalars['String']>
  filename_not_ends_with?: Maybe<Scalars['String']>
  mimetype?: Maybe<Scalars['String']>
  mimetype_not?: Maybe<Scalars['String']>
  mimetype_in?: Maybe<Array<Scalars['String']>>
  mimetype_not_in?: Maybe<Array<Scalars['String']>>
  mimetype_lt?: Maybe<Scalars['String']>
  mimetype_lte?: Maybe<Scalars['String']>
  mimetype_gt?: Maybe<Scalars['String']>
  mimetype_gte?: Maybe<Scalars['String']>
  mimetype_contains?: Maybe<Scalars['String']>
  mimetype_not_contains?: Maybe<Scalars['String']>
  mimetype_starts_with?: Maybe<Scalars['String']>
  mimetype_not_starts_with?: Maybe<Scalars['String']>
  mimetype_ends_with?: Maybe<Scalars['String']>
  mimetype_not_ends_with?: Maybe<Scalars['String']>
  encoding?: Maybe<Scalars['String']>
  encoding_not?: Maybe<Scalars['String']>
  encoding_in?: Maybe<Array<Scalars['String']>>
  encoding_not_in?: Maybe<Array<Scalars['String']>>
  encoding_lt?: Maybe<Scalars['String']>
  encoding_lte?: Maybe<Scalars['String']>
  encoding_gt?: Maybe<Scalars['String']>
  encoding_gte?: Maybe<Scalars['String']>
  encoding_contains?: Maybe<Scalars['String']>
  encoding_not_contains?: Maybe<Scalars['String']>
  encoding_starts_with?: Maybe<Scalars['String']>
  encoding_not_starts_with?: Maybe<Scalars['String']>
  encoding_ends_with?: Maybe<Scalars['String']>
  encoding_not_ends_with?: Maybe<Scalars['String']>
  location?: Maybe<Scalars['String']>
  location_not?: Maybe<Scalars['String']>
  location_in?: Maybe<Array<Scalars['String']>>
  location_not_in?: Maybe<Array<Scalars['String']>>
  location_lt?: Maybe<Scalars['String']>
  location_lte?: Maybe<Scalars['String']>
  location_gt?: Maybe<Scalars['String']>
  location_gte?: Maybe<Scalars['String']>
  location_contains?: Maybe<Scalars['String']>
  location_not_contains?: Maybe<Scalars['String']>
  location_starts_with?: Maybe<Scalars['String']>
  location_not_starts_with?: Maybe<Scalars['String']>
  location_ends_with?: Maybe<Scalars['String']>
  location_not_ends_with?: Maybe<Scalars['String']>
  AND?: Maybe<Array<FileScalarWhereInput>>
  OR?: Maybe<Array<FileScalarWhereInput>>
  NOT?: Maybe<Array<FileScalarWhereInput>>
}

export type FileUpdateDataInput = {
  filename?: Maybe<Scalars['String']>
  mimetype?: Maybe<Scalars['String']>
  encoding?: Maybe<Scalars['String']>
  location?: Maybe<Scalars['String']>
}

export type FileUpdateManyDataInput = {
  filename?: Maybe<Scalars['String']>
  mimetype?: Maybe<Scalars['String']>
  encoding?: Maybe<Scalars['String']>
  location?: Maybe<Scalars['String']>
}

export type FileUpdateManyInput = {
  create?: Maybe<Array<FileCreateInput>>
  update?: Maybe<Array<FileUpdateWithWhereUniqueNestedInput>>
  upsert?: Maybe<Array<FileUpsertWithWhereUniqueNestedInput>>
  delete?: Maybe<Array<FileWhereUniqueInput>>
  connect?: Maybe<Array<FileWhereUniqueInput>>
  set?: Maybe<Array<FileWhereUniqueInput>>
  disconnect?: Maybe<Array<FileWhereUniqueInput>>
  deleteMany?: Maybe<Array<FileScalarWhereInput>>
  updateMany?: Maybe<Array<FileUpdateManyWithWhereNestedInput>>
}

export type FileUpdateManyWithWhereNestedInput = {
  where: FileScalarWhereInput
  data: FileUpdateManyDataInput
}

export type FileUpdateOneInput = {
  create?: Maybe<FileCreateInput>
  update?: Maybe<FileUpdateDataInput>
  upsert?: Maybe<FileUpsertNestedInput>
  delete?: Maybe<Scalars['Boolean']>
  disconnect?: Maybe<Scalars['Boolean']>
  connect?: Maybe<FileWhereUniqueInput>
}

export type FileUpdateWithWhereUniqueNestedInput = {
  where: FileWhereUniqueInput
  data: FileUpdateDataInput
}

export type FileUpsertNestedInput = {
  update: FileUpdateDataInput
  create: FileCreateInput
}

export type FileUpsertWithWhereUniqueNestedInput = {
  where: FileWhereUniqueInput
  update: FileUpdateDataInput
  create: FileCreateInput
}

export type FileWhereInput = {
  id?: Maybe<Scalars['ID']>
  id_not?: Maybe<Scalars['ID']>
  id_in?: Maybe<Array<Scalars['ID']>>
  id_not_in?: Maybe<Array<Scalars['ID']>>
  id_lt?: Maybe<Scalars['ID']>
  id_lte?: Maybe<Scalars['ID']>
  id_gt?: Maybe<Scalars['ID']>
  id_gte?: Maybe<Scalars['ID']>
  id_contains?: Maybe<Scalars['ID']>
  id_not_contains?: Maybe<Scalars['ID']>
  id_starts_with?: Maybe<Scalars['ID']>
  id_not_starts_with?: Maybe<Scalars['ID']>
  id_ends_with?: Maybe<Scalars['ID']>
  id_not_ends_with?: Maybe<Scalars['ID']>
  createdAt?: Maybe<Scalars['DateTime']>
  createdAt_not?: Maybe<Scalars['DateTime']>
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>
  createdAt_lt?: Maybe<Scalars['DateTime']>
  createdAt_lte?: Maybe<Scalars['DateTime']>
  createdAt_gt?: Maybe<Scalars['DateTime']>
  createdAt_gte?: Maybe<Scalars['DateTime']>
  updatedAt?: Maybe<Scalars['DateTime']>
  updatedAt_not?: Maybe<Scalars['DateTime']>
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>
  updatedAt_lt?: Maybe<Scalars['DateTime']>
  updatedAt_lte?: Maybe<Scalars['DateTime']>
  updatedAt_gt?: Maybe<Scalars['DateTime']>
  updatedAt_gte?: Maybe<Scalars['DateTime']>
  filename?: Maybe<Scalars['String']>
  filename_not?: Maybe<Scalars['String']>
  filename_in?: Maybe<Array<Scalars['String']>>
  filename_not_in?: Maybe<Array<Scalars['String']>>
  filename_lt?: Maybe<Scalars['String']>
  filename_lte?: Maybe<Scalars['String']>
  filename_gt?: Maybe<Scalars['String']>
  filename_gte?: Maybe<Scalars['String']>
  filename_contains?: Maybe<Scalars['String']>
  filename_not_contains?: Maybe<Scalars['String']>
  filename_starts_with?: Maybe<Scalars['String']>
  filename_not_starts_with?: Maybe<Scalars['String']>
  filename_ends_with?: Maybe<Scalars['String']>
  filename_not_ends_with?: Maybe<Scalars['String']>
  mimetype?: Maybe<Scalars['String']>
  mimetype_not?: Maybe<Scalars['String']>
  mimetype_in?: Maybe<Array<Scalars['String']>>
  mimetype_not_in?: Maybe<Array<Scalars['String']>>
  mimetype_lt?: Maybe<Scalars['String']>
  mimetype_lte?: Maybe<Scalars['String']>
  mimetype_gt?: Maybe<Scalars['String']>
  mimetype_gte?: Maybe<Scalars['String']>
  mimetype_contains?: Maybe<Scalars['String']>
  mimetype_not_contains?: Maybe<Scalars['String']>
  mimetype_starts_with?: Maybe<Scalars['String']>
  mimetype_not_starts_with?: Maybe<Scalars['String']>
  mimetype_ends_with?: Maybe<Scalars['String']>
  mimetype_not_ends_with?: Maybe<Scalars['String']>
  encoding?: Maybe<Scalars['String']>
  encoding_not?: Maybe<Scalars['String']>
  encoding_in?: Maybe<Array<Scalars['String']>>
  encoding_not_in?: Maybe<Array<Scalars['String']>>
  encoding_lt?: Maybe<Scalars['String']>
  encoding_lte?: Maybe<Scalars['String']>
  encoding_gt?: Maybe<Scalars['String']>
  encoding_gte?: Maybe<Scalars['String']>
  encoding_contains?: Maybe<Scalars['String']>
  encoding_not_contains?: Maybe<Scalars['String']>
  encoding_starts_with?: Maybe<Scalars['String']>
  encoding_not_starts_with?: Maybe<Scalars['String']>
  encoding_ends_with?: Maybe<Scalars['String']>
  encoding_not_ends_with?: Maybe<Scalars['String']>
  location?: Maybe<Scalars['String']>
  location_not?: Maybe<Scalars['String']>
  location_in?: Maybe<Array<Scalars['String']>>
  location_not_in?: Maybe<Array<Scalars['String']>>
  location_lt?: Maybe<Scalars['String']>
  location_lte?: Maybe<Scalars['String']>
  location_gt?: Maybe<Scalars['String']>
  location_gte?: Maybe<Scalars['String']>
  location_contains?: Maybe<Scalars['String']>
  location_not_contains?: Maybe<Scalars['String']>
  location_starts_with?: Maybe<Scalars['String']>
  location_not_starts_with?: Maybe<Scalars['String']>
  location_ends_with?: Maybe<Scalars['String']>
  location_not_ends_with?: Maybe<Scalars['String']>
  AND?: Maybe<Array<FileWhereInput>>
  OR?: Maybe<Array<FileWhereInput>>
  NOT?: Maybe<Array<FileWhereInput>>
}

export type FileWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>
}

export type Message = {
  __typename?: 'Message'
  id: Scalars['ID']
  createdAt: Scalars['DateTime']
  updatedAt: Scalars['DateTime']
  content: Scalars['String']
  user?: Maybe<User>
  client?: Maybe<Client>
}

export type MessageCreateInput = {
  id?: Maybe<Scalars['ID']>
  content: Scalars['String']
  user?: Maybe<UserCreateOneInput>
  client?: Maybe<ClientCreateOneInput>
}

export type MessageCreateManyInput = {
  create?: Maybe<Array<MessageCreateInput>>
  connect?: Maybe<Array<MessageWhereUniqueInput>>
}

export enum MessageOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  ContentAsc = 'content_ASC',
  ContentDesc = 'content_DESC',
}

export type MessageScalarWhereInput = {
  id?: Maybe<Scalars['ID']>
  id_not?: Maybe<Scalars['ID']>
  id_in?: Maybe<Array<Scalars['ID']>>
  id_not_in?: Maybe<Array<Scalars['ID']>>
  id_lt?: Maybe<Scalars['ID']>
  id_lte?: Maybe<Scalars['ID']>
  id_gt?: Maybe<Scalars['ID']>
  id_gte?: Maybe<Scalars['ID']>
  id_contains?: Maybe<Scalars['ID']>
  id_not_contains?: Maybe<Scalars['ID']>
  id_starts_with?: Maybe<Scalars['ID']>
  id_not_starts_with?: Maybe<Scalars['ID']>
  id_ends_with?: Maybe<Scalars['ID']>
  id_not_ends_with?: Maybe<Scalars['ID']>
  createdAt?: Maybe<Scalars['DateTime']>
  createdAt_not?: Maybe<Scalars['DateTime']>
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>
  createdAt_lt?: Maybe<Scalars['DateTime']>
  createdAt_lte?: Maybe<Scalars['DateTime']>
  createdAt_gt?: Maybe<Scalars['DateTime']>
  createdAt_gte?: Maybe<Scalars['DateTime']>
  updatedAt?: Maybe<Scalars['DateTime']>
  updatedAt_not?: Maybe<Scalars['DateTime']>
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>
  updatedAt_lt?: Maybe<Scalars['DateTime']>
  updatedAt_lte?: Maybe<Scalars['DateTime']>
  updatedAt_gt?: Maybe<Scalars['DateTime']>
  updatedAt_gte?: Maybe<Scalars['DateTime']>
  content?: Maybe<Scalars['String']>
  content_not?: Maybe<Scalars['String']>
  content_in?: Maybe<Array<Scalars['String']>>
  content_not_in?: Maybe<Array<Scalars['String']>>
  content_lt?: Maybe<Scalars['String']>
  content_lte?: Maybe<Scalars['String']>
  content_gt?: Maybe<Scalars['String']>
  content_gte?: Maybe<Scalars['String']>
  content_contains?: Maybe<Scalars['String']>
  content_not_contains?: Maybe<Scalars['String']>
  content_starts_with?: Maybe<Scalars['String']>
  content_not_starts_with?: Maybe<Scalars['String']>
  content_ends_with?: Maybe<Scalars['String']>
  content_not_ends_with?: Maybe<Scalars['String']>
  AND?: Maybe<Array<MessageScalarWhereInput>>
  OR?: Maybe<Array<MessageScalarWhereInput>>
  NOT?: Maybe<Array<MessageScalarWhereInput>>
}

export type MessageUpdateDataInput = {
  content?: Maybe<Scalars['String']>
  user?: Maybe<UserUpdateOneInput>
  client?: Maybe<ClientUpdateOneInput>
}

export type MessageUpdateManyDataInput = {
  content?: Maybe<Scalars['String']>
}

export type MessageUpdateManyInput = {
  create?: Maybe<Array<MessageCreateInput>>
  update?: Maybe<Array<MessageUpdateWithWhereUniqueNestedInput>>
  upsert?: Maybe<Array<MessageUpsertWithWhereUniqueNestedInput>>
  delete?: Maybe<Array<MessageWhereUniqueInput>>
  connect?: Maybe<Array<MessageWhereUniqueInput>>
  set?: Maybe<Array<MessageWhereUniqueInput>>
  disconnect?: Maybe<Array<MessageWhereUniqueInput>>
  deleteMany?: Maybe<Array<MessageScalarWhereInput>>
  updateMany?: Maybe<Array<MessageUpdateManyWithWhereNestedInput>>
}

export type MessageUpdateManyWithWhereNestedInput = {
  where: MessageScalarWhereInput
  data: MessageUpdateManyDataInput
}

export type MessageUpdateWithWhereUniqueNestedInput = {
  where: MessageWhereUniqueInput
  data: MessageUpdateDataInput
}

export type MessageUpsertWithWhereUniqueNestedInput = {
  where: MessageWhereUniqueInput
  update: MessageUpdateDataInput
  create: MessageCreateInput
}

export type MessageWhereInput = {
  id?: Maybe<Scalars['ID']>
  id_not?: Maybe<Scalars['ID']>
  id_in?: Maybe<Array<Scalars['ID']>>
  id_not_in?: Maybe<Array<Scalars['ID']>>
  id_lt?: Maybe<Scalars['ID']>
  id_lte?: Maybe<Scalars['ID']>
  id_gt?: Maybe<Scalars['ID']>
  id_gte?: Maybe<Scalars['ID']>
  id_contains?: Maybe<Scalars['ID']>
  id_not_contains?: Maybe<Scalars['ID']>
  id_starts_with?: Maybe<Scalars['ID']>
  id_not_starts_with?: Maybe<Scalars['ID']>
  id_ends_with?: Maybe<Scalars['ID']>
  id_not_ends_with?: Maybe<Scalars['ID']>
  createdAt?: Maybe<Scalars['DateTime']>
  createdAt_not?: Maybe<Scalars['DateTime']>
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>
  createdAt_lt?: Maybe<Scalars['DateTime']>
  createdAt_lte?: Maybe<Scalars['DateTime']>
  createdAt_gt?: Maybe<Scalars['DateTime']>
  createdAt_gte?: Maybe<Scalars['DateTime']>
  updatedAt?: Maybe<Scalars['DateTime']>
  updatedAt_not?: Maybe<Scalars['DateTime']>
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>
  updatedAt_lt?: Maybe<Scalars['DateTime']>
  updatedAt_lte?: Maybe<Scalars['DateTime']>
  updatedAt_gt?: Maybe<Scalars['DateTime']>
  updatedAt_gte?: Maybe<Scalars['DateTime']>
  content?: Maybe<Scalars['String']>
  content_not?: Maybe<Scalars['String']>
  content_in?: Maybe<Array<Scalars['String']>>
  content_not_in?: Maybe<Array<Scalars['String']>>
  content_lt?: Maybe<Scalars['String']>
  content_lte?: Maybe<Scalars['String']>
  content_gt?: Maybe<Scalars['String']>
  content_gte?: Maybe<Scalars['String']>
  content_contains?: Maybe<Scalars['String']>
  content_not_contains?: Maybe<Scalars['String']>
  content_starts_with?: Maybe<Scalars['String']>
  content_not_starts_with?: Maybe<Scalars['String']>
  content_ends_with?: Maybe<Scalars['String']>
  content_not_ends_with?: Maybe<Scalars['String']>
  user?: Maybe<UserWhereInput>
  client?: Maybe<ClientWhereInput>
  AND?: Maybe<Array<MessageWhereInput>>
  OR?: Maybe<Array<MessageWhereInput>>
  NOT?: Maybe<Array<MessageWhereInput>>
}

export type MessageWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>
}

export type Mutation = {
  __typename?: 'Mutation'
  login?: Maybe<AuthPayload>
  signup?: Maybe<AuthPayload>
  trackTraceLogin?: Maybe<ClientAuthPayload>
  requestSearch?: Maybe<SearchRequest>
  requestBpmIndication?: Maybe<Order>
  requestQuotation?: Maybe<Order>
  cancelOrder?: Maybe<Order>
  singleFileUpload?: Maybe<File>
  indicateBpm?: Maybe<Order>
  sendClientTrackTraceMessage?: Maybe<Order>
  sendAdminTrackTraceMessage?: Maybe<Order>
  updateOrder?: Maybe<Order>
  updateUser?: Maybe<User>
  createBrand: Brand
  updateBrand?: Maybe<Brand>
  createCar: Car
  updateCar?: Maybe<Car>
  updateClient?: Maybe<Client>
  createOrder: Order
  createPricePackage: PricePackage
  updatePricePackage?: Maybe<PricePackage>
}

export type MutationLoginArgs = {
  email?: Maybe<Scalars['String']>
  password?: Maybe<Scalars['String']>
}

export type MutationSignupArgs = {
  name?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['String']>
  password?: Maybe<Scalars['String']>
}

export type MutationTrackTraceLoginArgs = {
  orderNumber?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['String']>
}

export type MutationRequestSearchArgs = {
  email?: Maybe<Scalars['String']>
  telephone?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  note?: Maybe<Scalars['String']>
}

export type MutationRequestBpmIndicationArgs = {
  email?: Maybe<Scalars['String']>
  telephone?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  link?: Maybe<Scalars['String']>
}

export type MutationRequestQuotationArgs = {
  hashedOrderNumber?: Maybe<Scalars['String']>
  acceptTermsAndConditions?: Maybe<Scalars['Boolean']>
  pricePackageId?: Maybe<Scalars['String']>
}

export type MutationCancelOrderArgs = {
  hashedOrderNumber?: Maybe<Scalars['String']>
  reason?: Maybe<Scalars['String']>
}

export type MutationSingleFileUploadArgs = {
  file?: Maybe<Scalars['Upload']>
}

export type MutationIndicateBpmArgs = {
  where?: Maybe<OrderWhereUniqueInput>
  data?: Maybe<OrderUpdateDataInput>
}

export type MutationSendClientTrackTraceMessageArgs = {
  orderNumber?: Maybe<Scalars['String']>
  message?: Maybe<Scalars['String']>
}

export type MutationSendAdminTrackTraceMessageArgs = {
  orderId?: Maybe<Scalars['ID']>
  message?: Maybe<Scalars['String']>
}

export type MutationUpdateOrderArgs = {
  where?: Maybe<OrderWhereUniqueInput>
  data?: Maybe<OrderUpdateInput>
}

export type MutationUpdateUserArgs = {
  where?: Maybe<UserWhereUniqueInput>
  data?: Maybe<UserUpdateInput>
}

export type MutationCreateBrandArgs = {
  data: BrandCreateInput
}

export type MutationUpdateBrandArgs = {
  data: BrandUpdateInput
  where: BrandWhereUniqueInput
}

export type MutationCreateCarArgs = {
  data: CarCreateInput
}

export type MutationUpdateCarArgs = {
  data: CarUpdateInput
  where: CarWhereUniqueInput
}

export type MutationUpdateClientArgs = {
  data: ClientUpdateInput
  where: ClientWhereUniqueInput
}

export type MutationCreateOrderArgs = {
  data: OrderCreateInput
}

export type MutationCreatePricePackageArgs = {
  data: PricePackageCreateInput
}

export type MutationUpdatePricePackageArgs = {
  data: PricePackageUpdateInput
  where: PricePackageWhereUniqueInput
}

export type Order = {
  __typename?: 'Order'
  id: Scalars['ID']
  orderNumber: Scalars['String']
  createdAt: Scalars['DateTime']
  updatedAt: Scalars['DateTime']
  deletedAt?: Maybe<Scalars['DateTime']>
  client: Client
  link: Scalars['String']
  status?: Maybe<OrderStatus>
  statusLogs?: Maybe<Array<OrderStatusLog>>
  car?: Maybe<Car>
  notes?: Maybe<Scalars['String']>
  messages?: Maybe<Array<Message>>
  pricePackage?: Maybe<PricePackage>
  priceItems?: Maybe<Array<PriceItem>>
}

export type OrderStatusLogsArgs = {
  where?: Maybe<OrderStatusLogWhereInput>
  orderBy?: Maybe<OrderStatusLogOrderByInput>
  skip?: Maybe<Scalars['Int']>
  after?: Maybe<Scalars['String']>
  before?: Maybe<Scalars['String']>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
}

export type OrderMessagesArgs = {
  where?: Maybe<MessageWhereInput>
  orderBy?: Maybe<MessageOrderByInput>
  skip?: Maybe<Scalars['Int']>
  after?: Maybe<Scalars['String']>
  before?: Maybe<Scalars['String']>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
}

export type OrderPriceItemsArgs = {
  where?: Maybe<PriceItemWhereInput>
  orderBy?: Maybe<PriceItemOrderByInput>
  skip?: Maybe<Scalars['Int']>
  after?: Maybe<Scalars['String']>
  before?: Maybe<Scalars['String']>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
}

export type OrderConnection = {
  __typename?: 'OrderConnection'
  pageInfo: PageInfo
  edges: Array<OrderEdge>
  aggregate: AggregateOrder
}

export type OrderCreateInput = {
  id?: Maybe<Scalars['ID']>
  orderNumber: Scalars['String']
  deletedAt?: Maybe<Scalars['DateTime']>
  client: ClientCreateOneWithoutOrdersInput
  link: Scalars['String']
  status?: Maybe<OrderStatus>
  statusLogs?: Maybe<OrderStatusLogCreateManyInput>
  car?: Maybe<CarCreateOneWithoutOrderInput>
  notes?: Maybe<Scalars['String']>
  messages?: Maybe<MessageCreateManyInput>
  pricePackage?: Maybe<PricePackageCreateOneInput>
  priceItems?: Maybe<PriceItemCreateManyInput>
}

export type OrderCreateManyWithoutClientInput = {
  create?: Maybe<Array<OrderCreateWithoutClientInput>>
  connect?: Maybe<Array<OrderWhereUniqueInput>>
}

export type OrderCreateOneInput = {
  create?: Maybe<OrderCreateInput>
  connect?: Maybe<OrderWhereUniqueInput>
}

export type OrderCreateOneWithoutCarInput = {
  create?: Maybe<OrderCreateWithoutCarInput>
  connect?: Maybe<OrderWhereUniqueInput>
}

export type OrderCreateWithoutCarInput = {
  id?: Maybe<Scalars['ID']>
  orderNumber: Scalars['String']
  deletedAt?: Maybe<Scalars['DateTime']>
  client: ClientCreateOneWithoutOrdersInput
  link: Scalars['String']
  status?: Maybe<OrderStatus>
  statusLogs?: Maybe<OrderStatusLogCreateManyInput>
  notes?: Maybe<Scalars['String']>
  messages?: Maybe<MessageCreateManyInput>
  pricePackage?: Maybe<PricePackageCreateOneInput>
  priceItems?: Maybe<PriceItemCreateManyInput>
}

export type OrderCreateWithoutClientInput = {
  id?: Maybe<Scalars['ID']>
  orderNumber: Scalars['String']
  deletedAt?: Maybe<Scalars['DateTime']>
  link: Scalars['String']
  status?: Maybe<OrderStatus>
  statusLogs?: Maybe<OrderStatusLogCreateManyInput>
  car?: Maybe<CarCreateOneWithoutOrderInput>
  notes?: Maybe<Scalars['String']>
  messages?: Maybe<MessageCreateManyInput>
  pricePackage?: Maybe<PricePackageCreateOneInput>
  priceItems?: Maybe<PriceItemCreateManyInput>
}

export type OrderEdge = {
  __typename?: 'OrderEdge'
  node: Order
  cursor: Scalars['String']
}

export enum OrderOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  OrderNumberAsc = 'orderNumber_ASC',
  OrderNumberDesc = 'orderNumber_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  LinkAsc = 'link_ASC',
  LinkDesc = 'link_DESC',
  StatusAsc = 'status_ASC',
  StatusDesc = 'status_DESC',
  NotesAsc = 'notes_ASC',
  NotesDesc = 'notes_DESC',
}

export type OrderScalarWhereInput = {
  id?: Maybe<Scalars['ID']>
  id_not?: Maybe<Scalars['ID']>
  id_in?: Maybe<Array<Scalars['ID']>>
  id_not_in?: Maybe<Array<Scalars['ID']>>
  id_lt?: Maybe<Scalars['ID']>
  id_lte?: Maybe<Scalars['ID']>
  id_gt?: Maybe<Scalars['ID']>
  id_gte?: Maybe<Scalars['ID']>
  id_contains?: Maybe<Scalars['ID']>
  id_not_contains?: Maybe<Scalars['ID']>
  id_starts_with?: Maybe<Scalars['ID']>
  id_not_starts_with?: Maybe<Scalars['ID']>
  id_ends_with?: Maybe<Scalars['ID']>
  id_not_ends_with?: Maybe<Scalars['ID']>
  orderNumber?: Maybe<Scalars['String']>
  orderNumber_not?: Maybe<Scalars['String']>
  orderNumber_in?: Maybe<Array<Scalars['String']>>
  orderNumber_not_in?: Maybe<Array<Scalars['String']>>
  orderNumber_lt?: Maybe<Scalars['String']>
  orderNumber_lte?: Maybe<Scalars['String']>
  orderNumber_gt?: Maybe<Scalars['String']>
  orderNumber_gte?: Maybe<Scalars['String']>
  orderNumber_contains?: Maybe<Scalars['String']>
  orderNumber_not_contains?: Maybe<Scalars['String']>
  orderNumber_starts_with?: Maybe<Scalars['String']>
  orderNumber_not_starts_with?: Maybe<Scalars['String']>
  orderNumber_ends_with?: Maybe<Scalars['String']>
  orderNumber_not_ends_with?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['DateTime']>
  createdAt_not?: Maybe<Scalars['DateTime']>
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>
  createdAt_lt?: Maybe<Scalars['DateTime']>
  createdAt_lte?: Maybe<Scalars['DateTime']>
  createdAt_gt?: Maybe<Scalars['DateTime']>
  createdAt_gte?: Maybe<Scalars['DateTime']>
  updatedAt?: Maybe<Scalars['DateTime']>
  updatedAt_not?: Maybe<Scalars['DateTime']>
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>
  updatedAt_lt?: Maybe<Scalars['DateTime']>
  updatedAt_lte?: Maybe<Scalars['DateTime']>
  updatedAt_gt?: Maybe<Scalars['DateTime']>
  updatedAt_gte?: Maybe<Scalars['DateTime']>
  deletedAt?: Maybe<Scalars['DateTime']>
  deletedAt_not?: Maybe<Scalars['DateTime']>
  deletedAt_in?: Maybe<Array<Scalars['DateTime']>>
  deletedAt_not_in?: Maybe<Array<Scalars['DateTime']>>
  deletedAt_lt?: Maybe<Scalars['DateTime']>
  deletedAt_lte?: Maybe<Scalars['DateTime']>
  deletedAt_gt?: Maybe<Scalars['DateTime']>
  deletedAt_gte?: Maybe<Scalars['DateTime']>
  link?: Maybe<Scalars['String']>
  link_not?: Maybe<Scalars['String']>
  link_in?: Maybe<Array<Scalars['String']>>
  link_not_in?: Maybe<Array<Scalars['String']>>
  link_lt?: Maybe<Scalars['String']>
  link_lte?: Maybe<Scalars['String']>
  link_gt?: Maybe<Scalars['String']>
  link_gte?: Maybe<Scalars['String']>
  link_contains?: Maybe<Scalars['String']>
  link_not_contains?: Maybe<Scalars['String']>
  link_starts_with?: Maybe<Scalars['String']>
  link_not_starts_with?: Maybe<Scalars['String']>
  link_ends_with?: Maybe<Scalars['String']>
  link_not_ends_with?: Maybe<Scalars['String']>
  status?: Maybe<OrderStatus>
  status_not?: Maybe<OrderStatus>
  status_in?: Maybe<Array<OrderStatus>>
  status_not_in?: Maybe<Array<OrderStatus>>
  notes?: Maybe<Scalars['String']>
  notes_not?: Maybe<Scalars['String']>
  notes_in?: Maybe<Array<Scalars['String']>>
  notes_not_in?: Maybe<Array<Scalars['String']>>
  notes_lt?: Maybe<Scalars['String']>
  notes_lte?: Maybe<Scalars['String']>
  notes_gt?: Maybe<Scalars['String']>
  notes_gte?: Maybe<Scalars['String']>
  notes_contains?: Maybe<Scalars['String']>
  notes_not_contains?: Maybe<Scalars['String']>
  notes_starts_with?: Maybe<Scalars['String']>
  notes_not_starts_with?: Maybe<Scalars['String']>
  notes_ends_with?: Maybe<Scalars['String']>
  notes_not_ends_with?: Maybe<Scalars['String']>
  AND?: Maybe<Array<OrderScalarWhereInput>>
  OR?: Maybe<Array<OrderScalarWhereInput>>
  NOT?: Maybe<Array<OrderScalarWhereInput>>
}

export enum OrderStatus {
  Pending = 'PENDING',
  BpmIndicated = 'BPM_INDICATED',
  QuotationRequested = 'QUOTATION_REQUESTED',
  QuotationSend = 'QUOTATION_SEND',
  PaymentReceived = 'PAYMENT_RECEIVED',
  TechnicalExamination = 'TECHNICAL_EXAMINATION',
  InTransit = 'IN_TRANSIT',
  Arrived = 'ARRIVED',
  RdwExamination = 'RDW_EXAMINATION',
  Accessoiries = 'ACCESSOIRIES',
  ReceivedLicensePlate = 'RECEIVED_LICENSE_PLATE',
  InTransitToYou = 'IN_TRANSIT_TO_YOU',
  Completed = 'COMPLETED',
  Canceled = 'CANCELED',
}

export type OrderStatusLog = {
  __typename?: 'OrderStatusLog'
  id: Scalars['ID']
  createdAt: Scalars['DateTime']
  status?: Maybe<OrderStatus>
}

export type OrderStatusLogCreateInput = {
  id?: Maybe<Scalars['ID']>
  status?: Maybe<OrderStatus>
}

export type OrderStatusLogCreateManyInput = {
  create?: Maybe<Array<OrderStatusLogCreateInput>>
  connect?: Maybe<Array<OrderStatusLogWhereUniqueInput>>
}

export enum OrderStatusLogOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  StatusAsc = 'status_ASC',
  StatusDesc = 'status_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type OrderStatusLogScalarWhereInput = {
  id?: Maybe<Scalars['ID']>
  id_not?: Maybe<Scalars['ID']>
  id_in?: Maybe<Array<Scalars['ID']>>
  id_not_in?: Maybe<Array<Scalars['ID']>>
  id_lt?: Maybe<Scalars['ID']>
  id_lte?: Maybe<Scalars['ID']>
  id_gt?: Maybe<Scalars['ID']>
  id_gte?: Maybe<Scalars['ID']>
  id_contains?: Maybe<Scalars['ID']>
  id_not_contains?: Maybe<Scalars['ID']>
  id_starts_with?: Maybe<Scalars['ID']>
  id_not_starts_with?: Maybe<Scalars['ID']>
  id_ends_with?: Maybe<Scalars['ID']>
  id_not_ends_with?: Maybe<Scalars['ID']>
  createdAt?: Maybe<Scalars['DateTime']>
  createdAt_not?: Maybe<Scalars['DateTime']>
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>
  createdAt_lt?: Maybe<Scalars['DateTime']>
  createdAt_lte?: Maybe<Scalars['DateTime']>
  createdAt_gt?: Maybe<Scalars['DateTime']>
  createdAt_gte?: Maybe<Scalars['DateTime']>
  status?: Maybe<OrderStatus>
  status_not?: Maybe<OrderStatus>
  status_in?: Maybe<Array<OrderStatus>>
  status_not_in?: Maybe<Array<OrderStatus>>
  AND?: Maybe<Array<OrderStatusLogScalarWhereInput>>
  OR?: Maybe<Array<OrderStatusLogScalarWhereInput>>
  NOT?: Maybe<Array<OrderStatusLogScalarWhereInput>>
}

export type OrderStatusLogUpdateDataInput = {
  status?: Maybe<OrderStatus>
}

export type OrderStatusLogUpdateManyDataInput = {
  status?: Maybe<OrderStatus>
}

export type OrderStatusLogUpdateManyInput = {
  create?: Maybe<Array<OrderStatusLogCreateInput>>
  update?: Maybe<Array<OrderStatusLogUpdateWithWhereUniqueNestedInput>>
  upsert?: Maybe<Array<OrderStatusLogUpsertWithWhereUniqueNestedInput>>
  delete?: Maybe<Array<OrderStatusLogWhereUniqueInput>>
  connect?: Maybe<Array<OrderStatusLogWhereUniqueInput>>
  set?: Maybe<Array<OrderStatusLogWhereUniqueInput>>
  disconnect?: Maybe<Array<OrderStatusLogWhereUniqueInput>>
  deleteMany?: Maybe<Array<OrderStatusLogScalarWhereInput>>
  updateMany?: Maybe<Array<OrderStatusLogUpdateManyWithWhereNestedInput>>
}

export type OrderStatusLogUpdateManyWithWhereNestedInput = {
  where: OrderStatusLogScalarWhereInput
  data: OrderStatusLogUpdateManyDataInput
}

export type OrderStatusLogUpdateWithWhereUniqueNestedInput = {
  where: OrderStatusLogWhereUniqueInput
  data: OrderStatusLogUpdateDataInput
}

export type OrderStatusLogUpsertWithWhereUniqueNestedInput = {
  where: OrderStatusLogWhereUniqueInput
  update: OrderStatusLogUpdateDataInput
  create: OrderStatusLogCreateInput
}

export type OrderStatusLogWhereInput = {
  id?: Maybe<Scalars['ID']>
  id_not?: Maybe<Scalars['ID']>
  id_in?: Maybe<Array<Scalars['ID']>>
  id_not_in?: Maybe<Array<Scalars['ID']>>
  id_lt?: Maybe<Scalars['ID']>
  id_lte?: Maybe<Scalars['ID']>
  id_gt?: Maybe<Scalars['ID']>
  id_gte?: Maybe<Scalars['ID']>
  id_contains?: Maybe<Scalars['ID']>
  id_not_contains?: Maybe<Scalars['ID']>
  id_starts_with?: Maybe<Scalars['ID']>
  id_not_starts_with?: Maybe<Scalars['ID']>
  id_ends_with?: Maybe<Scalars['ID']>
  id_not_ends_with?: Maybe<Scalars['ID']>
  createdAt?: Maybe<Scalars['DateTime']>
  createdAt_not?: Maybe<Scalars['DateTime']>
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>
  createdAt_lt?: Maybe<Scalars['DateTime']>
  createdAt_lte?: Maybe<Scalars['DateTime']>
  createdAt_gt?: Maybe<Scalars['DateTime']>
  createdAt_gte?: Maybe<Scalars['DateTime']>
  status?: Maybe<OrderStatus>
  status_not?: Maybe<OrderStatus>
  status_in?: Maybe<Array<OrderStatus>>
  status_not_in?: Maybe<Array<OrderStatus>>
  AND?: Maybe<Array<OrderStatusLogWhereInput>>
  OR?: Maybe<Array<OrderStatusLogWhereInput>>
  NOT?: Maybe<Array<OrderStatusLogWhereInput>>
}

export type OrderStatusLogWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>
}

export type OrderUpdateDataInput = {
  orderNumber?: Maybe<Scalars['String']>
  deletedAt?: Maybe<Scalars['DateTime']>
  client?: Maybe<ClientUpdateOneRequiredWithoutOrdersInput>
  link?: Maybe<Scalars['String']>
  status?: Maybe<OrderStatus>
  statusLogs?: Maybe<OrderStatusLogUpdateManyInput>
  car?: Maybe<CarUpdateOneWithoutOrderInput>
  notes?: Maybe<Scalars['String']>
  messages?: Maybe<MessageUpdateManyInput>
  pricePackage?: Maybe<PricePackageUpdateOneInput>
  priceItems?: Maybe<PriceItemUpdateManyInput>
}

export type OrderUpdateInput = {
  orderNumber?: Maybe<Scalars['String']>
  deletedAt?: Maybe<Scalars['DateTime']>
  client?: Maybe<ClientUpdateOneRequiredWithoutOrdersInput>
  link?: Maybe<Scalars['String']>
  status?: Maybe<OrderStatus>
  statusLogs?: Maybe<OrderStatusLogUpdateManyInput>
  car?: Maybe<CarUpdateOneWithoutOrderInput>
  notes?: Maybe<Scalars['String']>
  messages?: Maybe<MessageUpdateManyInput>
  pricePackage?: Maybe<PricePackageUpdateOneInput>
  priceItems?: Maybe<PriceItemUpdateManyInput>
}

export type OrderUpdateManyDataInput = {
  orderNumber?: Maybe<Scalars['String']>
  deletedAt?: Maybe<Scalars['DateTime']>
  link?: Maybe<Scalars['String']>
  status?: Maybe<OrderStatus>
  notes?: Maybe<Scalars['String']>
}

export type OrderUpdateManyWithoutClientInput = {
  create?: Maybe<Array<OrderCreateWithoutClientInput>>
  delete?: Maybe<Array<OrderWhereUniqueInput>>
  connect?: Maybe<Array<OrderWhereUniqueInput>>
  set?: Maybe<Array<OrderWhereUniqueInput>>
  disconnect?: Maybe<Array<OrderWhereUniqueInput>>
  update?: Maybe<Array<OrderUpdateWithWhereUniqueWithoutClientInput>>
  upsert?: Maybe<Array<OrderUpsertWithWhereUniqueWithoutClientInput>>
  deleteMany?: Maybe<Array<OrderScalarWhereInput>>
  updateMany?: Maybe<Array<OrderUpdateManyWithWhereNestedInput>>
}

export type OrderUpdateManyWithWhereNestedInput = {
  where: OrderScalarWhereInput
  data: OrderUpdateManyDataInput
}

export type OrderUpdateOneInput = {
  create?: Maybe<OrderCreateInput>
  update?: Maybe<OrderUpdateDataInput>
  upsert?: Maybe<OrderUpsertNestedInput>
  delete?: Maybe<Scalars['Boolean']>
  disconnect?: Maybe<Scalars['Boolean']>
  connect?: Maybe<OrderWhereUniqueInput>
}

export type OrderUpdateOneWithoutCarInput = {
  create?: Maybe<OrderCreateWithoutCarInput>
  update?: Maybe<OrderUpdateWithoutCarDataInput>
  upsert?: Maybe<OrderUpsertWithoutCarInput>
  delete?: Maybe<Scalars['Boolean']>
  disconnect?: Maybe<Scalars['Boolean']>
  connect?: Maybe<OrderWhereUniqueInput>
}

export type OrderUpdateWithoutCarDataInput = {
  orderNumber?: Maybe<Scalars['String']>
  deletedAt?: Maybe<Scalars['DateTime']>
  client?: Maybe<ClientUpdateOneRequiredWithoutOrdersInput>
  link?: Maybe<Scalars['String']>
  status?: Maybe<OrderStatus>
  statusLogs?: Maybe<OrderStatusLogUpdateManyInput>
  notes?: Maybe<Scalars['String']>
  messages?: Maybe<MessageUpdateManyInput>
  pricePackage?: Maybe<PricePackageUpdateOneInput>
  priceItems?: Maybe<PriceItemUpdateManyInput>
}

export type OrderUpdateWithoutClientDataInput = {
  orderNumber?: Maybe<Scalars['String']>
  deletedAt?: Maybe<Scalars['DateTime']>
  link?: Maybe<Scalars['String']>
  status?: Maybe<OrderStatus>
  statusLogs?: Maybe<OrderStatusLogUpdateManyInput>
  car?: Maybe<CarUpdateOneWithoutOrderInput>
  notes?: Maybe<Scalars['String']>
  messages?: Maybe<MessageUpdateManyInput>
  pricePackage?: Maybe<PricePackageUpdateOneInput>
  priceItems?: Maybe<PriceItemUpdateManyInput>
}

export type OrderUpdateWithWhereUniqueWithoutClientInput = {
  where: OrderWhereUniqueInput
  data: OrderUpdateWithoutClientDataInput
}

export type OrderUpsertNestedInput = {
  update: OrderUpdateDataInput
  create: OrderCreateInput
}

export type OrderUpsertWithoutCarInput = {
  update: OrderUpdateWithoutCarDataInput
  create: OrderCreateWithoutCarInput
}

export type OrderUpsertWithWhereUniqueWithoutClientInput = {
  where: OrderWhereUniqueInput
  update: OrderUpdateWithoutClientDataInput
  create: OrderCreateWithoutClientInput
}

export type OrderWhereInput = {
  id?: Maybe<Scalars['ID']>
  id_not?: Maybe<Scalars['ID']>
  id_in?: Maybe<Array<Scalars['ID']>>
  id_not_in?: Maybe<Array<Scalars['ID']>>
  id_lt?: Maybe<Scalars['ID']>
  id_lte?: Maybe<Scalars['ID']>
  id_gt?: Maybe<Scalars['ID']>
  id_gte?: Maybe<Scalars['ID']>
  id_contains?: Maybe<Scalars['ID']>
  id_not_contains?: Maybe<Scalars['ID']>
  id_starts_with?: Maybe<Scalars['ID']>
  id_not_starts_with?: Maybe<Scalars['ID']>
  id_ends_with?: Maybe<Scalars['ID']>
  id_not_ends_with?: Maybe<Scalars['ID']>
  orderNumber?: Maybe<Scalars['String']>
  orderNumber_not?: Maybe<Scalars['String']>
  orderNumber_in?: Maybe<Array<Scalars['String']>>
  orderNumber_not_in?: Maybe<Array<Scalars['String']>>
  orderNumber_lt?: Maybe<Scalars['String']>
  orderNumber_lte?: Maybe<Scalars['String']>
  orderNumber_gt?: Maybe<Scalars['String']>
  orderNumber_gte?: Maybe<Scalars['String']>
  orderNumber_contains?: Maybe<Scalars['String']>
  orderNumber_not_contains?: Maybe<Scalars['String']>
  orderNumber_starts_with?: Maybe<Scalars['String']>
  orderNumber_not_starts_with?: Maybe<Scalars['String']>
  orderNumber_ends_with?: Maybe<Scalars['String']>
  orderNumber_not_ends_with?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['DateTime']>
  createdAt_not?: Maybe<Scalars['DateTime']>
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>
  createdAt_lt?: Maybe<Scalars['DateTime']>
  createdAt_lte?: Maybe<Scalars['DateTime']>
  createdAt_gt?: Maybe<Scalars['DateTime']>
  createdAt_gte?: Maybe<Scalars['DateTime']>
  updatedAt?: Maybe<Scalars['DateTime']>
  updatedAt_not?: Maybe<Scalars['DateTime']>
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>
  updatedAt_lt?: Maybe<Scalars['DateTime']>
  updatedAt_lte?: Maybe<Scalars['DateTime']>
  updatedAt_gt?: Maybe<Scalars['DateTime']>
  updatedAt_gte?: Maybe<Scalars['DateTime']>
  deletedAt?: Maybe<Scalars['DateTime']>
  deletedAt_not?: Maybe<Scalars['DateTime']>
  deletedAt_in?: Maybe<Array<Scalars['DateTime']>>
  deletedAt_not_in?: Maybe<Array<Scalars['DateTime']>>
  deletedAt_lt?: Maybe<Scalars['DateTime']>
  deletedAt_lte?: Maybe<Scalars['DateTime']>
  deletedAt_gt?: Maybe<Scalars['DateTime']>
  deletedAt_gte?: Maybe<Scalars['DateTime']>
  client?: Maybe<ClientWhereInput>
  link?: Maybe<Scalars['String']>
  link_not?: Maybe<Scalars['String']>
  link_in?: Maybe<Array<Scalars['String']>>
  link_not_in?: Maybe<Array<Scalars['String']>>
  link_lt?: Maybe<Scalars['String']>
  link_lte?: Maybe<Scalars['String']>
  link_gt?: Maybe<Scalars['String']>
  link_gte?: Maybe<Scalars['String']>
  link_contains?: Maybe<Scalars['String']>
  link_not_contains?: Maybe<Scalars['String']>
  link_starts_with?: Maybe<Scalars['String']>
  link_not_starts_with?: Maybe<Scalars['String']>
  link_ends_with?: Maybe<Scalars['String']>
  link_not_ends_with?: Maybe<Scalars['String']>
  status?: Maybe<OrderStatus>
  status_not?: Maybe<OrderStatus>
  status_in?: Maybe<Array<OrderStatus>>
  status_not_in?: Maybe<Array<OrderStatus>>
  statusLogs_every?: Maybe<OrderStatusLogWhereInput>
  statusLogs_some?: Maybe<OrderStatusLogWhereInput>
  statusLogs_none?: Maybe<OrderStatusLogWhereInput>
  car?: Maybe<CarWhereInput>
  notes?: Maybe<Scalars['String']>
  notes_not?: Maybe<Scalars['String']>
  notes_in?: Maybe<Array<Scalars['String']>>
  notes_not_in?: Maybe<Array<Scalars['String']>>
  notes_lt?: Maybe<Scalars['String']>
  notes_lte?: Maybe<Scalars['String']>
  notes_gt?: Maybe<Scalars['String']>
  notes_gte?: Maybe<Scalars['String']>
  notes_contains?: Maybe<Scalars['String']>
  notes_not_contains?: Maybe<Scalars['String']>
  notes_starts_with?: Maybe<Scalars['String']>
  notes_not_starts_with?: Maybe<Scalars['String']>
  notes_ends_with?: Maybe<Scalars['String']>
  notes_not_ends_with?: Maybe<Scalars['String']>
  messages_every?: Maybe<MessageWhereInput>
  messages_some?: Maybe<MessageWhereInput>
  messages_none?: Maybe<MessageWhereInput>
  pricePackage?: Maybe<PricePackageWhereInput>
  priceItems_every?: Maybe<PriceItemWhereInput>
  priceItems_some?: Maybe<PriceItemWhereInput>
  priceItems_none?: Maybe<PriceItemWhereInput>
  AND?: Maybe<Array<OrderWhereInput>>
  OR?: Maybe<Array<OrderWhereInput>>
  NOT?: Maybe<Array<OrderWhereInput>>
}

export type OrderWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>
  orderNumber?: Maybe<Scalars['String']>
}

export type PageInfo = {
  __typename?: 'PageInfo'
  hasNextPage: Scalars['Boolean']
  hasPreviousPage: Scalars['Boolean']
  startCursor?: Maybe<Scalars['String']>
  endCursor?: Maybe<Scalars['String']>
}

export type PriceItem = {
  __typename?: 'PriceItem'
  id: Scalars['ID']
  createdAt: Scalars['DateTime']
  updatedAt: Scalars['DateTime']
  name: Scalars['String']
  price: Scalars['Int']
  inclVAT?: Maybe<Scalars['Boolean']>
  VAT?: Maybe<Scalars['Int']>
  amount: Scalars['Int']
  priceClient: Scalars['Int']
}

export type PriceItemCreateInput = {
  id?: Maybe<Scalars['ID']>
  name: Scalars['String']
  price: Scalars['Int']
  inclVAT?: Maybe<Scalars['Boolean']>
  VAT?: Maybe<Scalars['Int']>
  amount?: Maybe<Scalars['Int']>
  priceClient: Scalars['Int']
}

export type PriceItemCreateManyInput = {
  create?: Maybe<Array<PriceItemCreateInput>>
  connect?: Maybe<Array<PriceItemWhereUniqueInput>>
}

export enum PriceItemOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PriceAsc = 'price_ASC',
  PriceDesc = 'price_DESC',
  InclVatAsc = 'inclVAT_ASC',
  InclVatDesc = 'inclVAT_DESC',
  VatAsc = 'VAT_ASC',
  VatDesc = 'VAT_DESC',
  AmountAsc = 'amount_ASC',
  AmountDesc = 'amount_DESC',
  PriceClientAsc = 'priceClient_ASC',
  PriceClientDesc = 'priceClient_DESC',
}

export type PriceItemScalarWhereInput = {
  id?: Maybe<Scalars['ID']>
  id_not?: Maybe<Scalars['ID']>
  id_in?: Maybe<Array<Scalars['ID']>>
  id_not_in?: Maybe<Array<Scalars['ID']>>
  id_lt?: Maybe<Scalars['ID']>
  id_lte?: Maybe<Scalars['ID']>
  id_gt?: Maybe<Scalars['ID']>
  id_gte?: Maybe<Scalars['ID']>
  id_contains?: Maybe<Scalars['ID']>
  id_not_contains?: Maybe<Scalars['ID']>
  id_starts_with?: Maybe<Scalars['ID']>
  id_not_starts_with?: Maybe<Scalars['ID']>
  id_ends_with?: Maybe<Scalars['ID']>
  id_not_ends_with?: Maybe<Scalars['ID']>
  createdAt?: Maybe<Scalars['DateTime']>
  createdAt_not?: Maybe<Scalars['DateTime']>
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>
  createdAt_lt?: Maybe<Scalars['DateTime']>
  createdAt_lte?: Maybe<Scalars['DateTime']>
  createdAt_gt?: Maybe<Scalars['DateTime']>
  createdAt_gte?: Maybe<Scalars['DateTime']>
  updatedAt?: Maybe<Scalars['DateTime']>
  updatedAt_not?: Maybe<Scalars['DateTime']>
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>
  updatedAt_lt?: Maybe<Scalars['DateTime']>
  updatedAt_lte?: Maybe<Scalars['DateTime']>
  updatedAt_gt?: Maybe<Scalars['DateTime']>
  updatedAt_gte?: Maybe<Scalars['DateTime']>
  name?: Maybe<Scalars['String']>
  name_not?: Maybe<Scalars['String']>
  name_in?: Maybe<Array<Scalars['String']>>
  name_not_in?: Maybe<Array<Scalars['String']>>
  name_lt?: Maybe<Scalars['String']>
  name_lte?: Maybe<Scalars['String']>
  name_gt?: Maybe<Scalars['String']>
  name_gte?: Maybe<Scalars['String']>
  name_contains?: Maybe<Scalars['String']>
  name_not_contains?: Maybe<Scalars['String']>
  name_starts_with?: Maybe<Scalars['String']>
  name_not_starts_with?: Maybe<Scalars['String']>
  name_ends_with?: Maybe<Scalars['String']>
  name_not_ends_with?: Maybe<Scalars['String']>
  price?: Maybe<Scalars['Int']>
  price_not?: Maybe<Scalars['Int']>
  price_in?: Maybe<Array<Scalars['Int']>>
  price_not_in?: Maybe<Array<Scalars['Int']>>
  price_lt?: Maybe<Scalars['Int']>
  price_lte?: Maybe<Scalars['Int']>
  price_gt?: Maybe<Scalars['Int']>
  price_gte?: Maybe<Scalars['Int']>
  inclVAT?: Maybe<Scalars['Boolean']>
  inclVAT_not?: Maybe<Scalars['Boolean']>
  VAT?: Maybe<Scalars['Int']>
  VAT_not?: Maybe<Scalars['Int']>
  VAT_in?: Maybe<Array<Scalars['Int']>>
  VAT_not_in?: Maybe<Array<Scalars['Int']>>
  VAT_lt?: Maybe<Scalars['Int']>
  VAT_lte?: Maybe<Scalars['Int']>
  VAT_gt?: Maybe<Scalars['Int']>
  VAT_gte?: Maybe<Scalars['Int']>
  amount?: Maybe<Scalars['Int']>
  amount_not?: Maybe<Scalars['Int']>
  amount_in?: Maybe<Array<Scalars['Int']>>
  amount_not_in?: Maybe<Array<Scalars['Int']>>
  amount_lt?: Maybe<Scalars['Int']>
  amount_lte?: Maybe<Scalars['Int']>
  amount_gt?: Maybe<Scalars['Int']>
  amount_gte?: Maybe<Scalars['Int']>
  priceClient?: Maybe<Scalars['Int']>
  priceClient_not?: Maybe<Scalars['Int']>
  priceClient_in?: Maybe<Array<Scalars['Int']>>
  priceClient_not_in?: Maybe<Array<Scalars['Int']>>
  priceClient_lt?: Maybe<Scalars['Int']>
  priceClient_lte?: Maybe<Scalars['Int']>
  priceClient_gt?: Maybe<Scalars['Int']>
  priceClient_gte?: Maybe<Scalars['Int']>
  AND?: Maybe<Array<PriceItemScalarWhereInput>>
  OR?: Maybe<Array<PriceItemScalarWhereInput>>
  NOT?: Maybe<Array<PriceItemScalarWhereInput>>
}

export type PriceItemUpdateDataInput = {
  name?: Maybe<Scalars['String']>
  price?: Maybe<Scalars['Int']>
  inclVAT?: Maybe<Scalars['Boolean']>
  VAT?: Maybe<Scalars['Int']>
  amount?: Maybe<Scalars['Int']>
  priceClient?: Maybe<Scalars['Int']>
}

export type PriceItemUpdateManyDataInput = {
  name?: Maybe<Scalars['String']>
  price?: Maybe<Scalars['Int']>
  inclVAT?: Maybe<Scalars['Boolean']>
  VAT?: Maybe<Scalars['Int']>
  amount?: Maybe<Scalars['Int']>
  priceClient?: Maybe<Scalars['Int']>
}

export type PriceItemUpdateManyInput = {
  create?: Maybe<Array<PriceItemCreateInput>>
  update?: Maybe<Array<PriceItemUpdateWithWhereUniqueNestedInput>>
  upsert?: Maybe<Array<PriceItemUpsertWithWhereUniqueNestedInput>>
  delete?: Maybe<Array<PriceItemWhereUniqueInput>>
  connect?: Maybe<Array<PriceItemWhereUniqueInput>>
  set?: Maybe<Array<PriceItemWhereUniqueInput>>
  disconnect?: Maybe<Array<PriceItemWhereUniqueInput>>
  deleteMany?: Maybe<Array<PriceItemScalarWhereInput>>
  updateMany?: Maybe<Array<PriceItemUpdateManyWithWhereNestedInput>>
}

export type PriceItemUpdateManyWithWhereNestedInput = {
  where: PriceItemScalarWhereInput
  data: PriceItemUpdateManyDataInput
}

export type PriceItemUpdateWithWhereUniqueNestedInput = {
  where: PriceItemWhereUniqueInput
  data: PriceItemUpdateDataInput
}

export type PriceItemUpsertWithWhereUniqueNestedInput = {
  where: PriceItemWhereUniqueInput
  update: PriceItemUpdateDataInput
  create: PriceItemCreateInput
}

export type PriceItemWhereInput = {
  id?: Maybe<Scalars['ID']>
  id_not?: Maybe<Scalars['ID']>
  id_in?: Maybe<Array<Scalars['ID']>>
  id_not_in?: Maybe<Array<Scalars['ID']>>
  id_lt?: Maybe<Scalars['ID']>
  id_lte?: Maybe<Scalars['ID']>
  id_gt?: Maybe<Scalars['ID']>
  id_gte?: Maybe<Scalars['ID']>
  id_contains?: Maybe<Scalars['ID']>
  id_not_contains?: Maybe<Scalars['ID']>
  id_starts_with?: Maybe<Scalars['ID']>
  id_not_starts_with?: Maybe<Scalars['ID']>
  id_ends_with?: Maybe<Scalars['ID']>
  id_not_ends_with?: Maybe<Scalars['ID']>
  createdAt?: Maybe<Scalars['DateTime']>
  createdAt_not?: Maybe<Scalars['DateTime']>
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>
  createdAt_lt?: Maybe<Scalars['DateTime']>
  createdAt_lte?: Maybe<Scalars['DateTime']>
  createdAt_gt?: Maybe<Scalars['DateTime']>
  createdAt_gte?: Maybe<Scalars['DateTime']>
  updatedAt?: Maybe<Scalars['DateTime']>
  updatedAt_not?: Maybe<Scalars['DateTime']>
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>
  updatedAt_lt?: Maybe<Scalars['DateTime']>
  updatedAt_lte?: Maybe<Scalars['DateTime']>
  updatedAt_gt?: Maybe<Scalars['DateTime']>
  updatedAt_gte?: Maybe<Scalars['DateTime']>
  name?: Maybe<Scalars['String']>
  name_not?: Maybe<Scalars['String']>
  name_in?: Maybe<Array<Scalars['String']>>
  name_not_in?: Maybe<Array<Scalars['String']>>
  name_lt?: Maybe<Scalars['String']>
  name_lte?: Maybe<Scalars['String']>
  name_gt?: Maybe<Scalars['String']>
  name_gte?: Maybe<Scalars['String']>
  name_contains?: Maybe<Scalars['String']>
  name_not_contains?: Maybe<Scalars['String']>
  name_starts_with?: Maybe<Scalars['String']>
  name_not_starts_with?: Maybe<Scalars['String']>
  name_ends_with?: Maybe<Scalars['String']>
  name_not_ends_with?: Maybe<Scalars['String']>
  price?: Maybe<Scalars['Int']>
  price_not?: Maybe<Scalars['Int']>
  price_in?: Maybe<Array<Scalars['Int']>>
  price_not_in?: Maybe<Array<Scalars['Int']>>
  price_lt?: Maybe<Scalars['Int']>
  price_lte?: Maybe<Scalars['Int']>
  price_gt?: Maybe<Scalars['Int']>
  price_gte?: Maybe<Scalars['Int']>
  inclVAT?: Maybe<Scalars['Boolean']>
  inclVAT_not?: Maybe<Scalars['Boolean']>
  VAT?: Maybe<Scalars['Int']>
  VAT_not?: Maybe<Scalars['Int']>
  VAT_in?: Maybe<Array<Scalars['Int']>>
  VAT_not_in?: Maybe<Array<Scalars['Int']>>
  VAT_lt?: Maybe<Scalars['Int']>
  VAT_lte?: Maybe<Scalars['Int']>
  VAT_gt?: Maybe<Scalars['Int']>
  VAT_gte?: Maybe<Scalars['Int']>
  amount?: Maybe<Scalars['Int']>
  amount_not?: Maybe<Scalars['Int']>
  amount_in?: Maybe<Array<Scalars['Int']>>
  amount_not_in?: Maybe<Array<Scalars['Int']>>
  amount_lt?: Maybe<Scalars['Int']>
  amount_lte?: Maybe<Scalars['Int']>
  amount_gt?: Maybe<Scalars['Int']>
  amount_gte?: Maybe<Scalars['Int']>
  priceClient?: Maybe<Scalars['Int']>
  priceClient_not?: Maybe<Scalars['Int']>
  priceClient_in?: Maybe<Array<Scalars['Int']>>
  priceClient_not_in?: Maybe<Array<Scalars['Int']>>
  priceClient_lt?: Maybe<Scalars['Int']>
  priceClient_lte?: Maybe<Scalars['Int']>
  priceClient_gt?: Maybe<Scalars['Int']>
  priceClient_gte?: Maybe<Scalars['Int']>
  AND?: Maybe<Array<PriceItemWhereInput>>
  OR?: Maybe<Array<PriceItemWhereInput>>
  NOT?: Maybe<Array<PriceItemWhereInput>>
}

export type PriceItemWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>
}

export type PricePackage = {
  __typename?: 'PricePackage'
  id: Scalars['ID']
  createdAt: Scalars['DateTime']
  updatedAt: Scalars['DateTime']
  name: Scalars['String']
  price: Scalars['Int']
  featured?: Maybe<Scalars['Boolean']>
  mostChosen?: Maybe<Scalars['Boolean']>
  description?: Maybe<Scalars['String']>
  summary?: Maybe<Scalars['String']>
  properties: Array<Scalars['String']>
}

export type PricePackageCreateInput = {
  id?: Maybe<Scalars['ID']>
  name: Scalars['String']
  price: Scalars['Int']
  featured?: Maybe<Scalars['Boolean']>
  mostChosen?: Maybe<Scalars['Boolean']>
  description?: Maybe<Scalars['String']>
  summary?: Maybe<Scalars['String']>
  properties?: Maybe<PricePackageCreatepropertiesInput>
}

export type PricePackageCreateOneInput = {
  create?: Maybe<PricePackageCreateInput>
  connect?: Maybe<PricePackageWhereUniqueInput>
}

export type PricePackageCreatepropertiesInput = {
  set?: Maybe<Array<Scalars['String']>>
}

export enum PricePackageOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PriceAsc = 'price_ASC',
  PriceDesc = 'price_DESC',
  FeaturedAsc = 'featured_ASC',
  FeaturedDesc = 'featured_DESC',
  MostChosenAsc = 'mostChosen_ASC',
  MostChosenDesc = 'mostChosen_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  SummaryAsc = 'summary_ASC',
  SummaryDesc = 'summary_DESC',
}

export type PricePackageUpdateDataInput = {
  name?: Maybe<Scalars['String']>
  price?: Maybe<Scalars['Int']>
  featured?: Maybe<Scalars['Boolean']>
  mostChosen?: Maybe<Scalars['Boolean']>
  description?: Maybe<Scalars['String']>
  summary?: Maybe<Scalars['String']>
  properties?: Maybe<PricePackageUpdatepropertiesInput>
}

export type PricePackageUpdateInput = {
  name?: Maybe<Scalars['String']>
  price?: Maybe<Scalars['Int']>
  featured?: Maybe<Scalars['Boolean']>
  mostChosen?: Maybe<Scalars['Boolean']>
  description?: Maybe<Scalars['String']>
  summary?: Maybe<Scalars['String']>
  properties?: Maybe<PricePackageUpdatepropertiesInput>
}

export type PricePackageUpdateOneInput = {
  create?: Maybe<PricePackageCreateInput>
  update?: Maybe<PricePackageUpdateDataInput>
  upsert?: Maybe<PricePackageUpsertNestedInput>
  delete?: Maybe<Scalars['Boolean']>
  disconnect?: Maybe<Scalars['Boolean']>
  connect?: Maybe<PricePackageWhereUniqueInput>
}

export type PricePackageUpdatepropertiesInput = {
  set?: Maybe<Array<Scalars['String']>>
}

export type PricePackageUpsertNestedInput = {
  update: PricePackageUpdateDataInput
  create: PricePackageCreateInput
}

export type PricePackageWhereInput = {
  id?: Maybe<Scalars['ID']>
  id_not?: Maybe<Scalars['ID']>
  id_in?: Maybe<Array<Scalars['ID']>>
  id_not_in?: Maybe<Array<Scalars['ID']>>
  id_lt?: Maybe<Scalars['ID']>
  id_lte?: Maybe<Scalars['ID']>
  id_gt?: Maybe<Scalars['ID']>
  id_gte?: Maybe<Scalars['ID']>
  id_contains?: Maybe<Scalars['ID']>
  id_not_contains?: Maybe<Scalars['ID']>
  id_starts_with?: Maybe<Scalars['ID']>
  id_not_starts_with?: Maybe<Scalars['ID']>
  id_ends_with?: Maybe<Scalars['ID']>
  id_not_ends_with?: Maybe<Scalars['ID']>
  createdAt?: Maybe<Scalars['DateTime']>
  createdAt_not?: Maybe<Scalars['DateTime']>
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>
  createdAt_lt?: Maybe<Scalars['DateTime']>
  createdAt_lte?: Maybe<Scalars['DateTime']>
  createdAt_gt?: Maybe<Scalars['DateTime']>
  createdAt_gte?: Maybe<Scalars['DateTime']>
  updatedAt?: Maybe<Scalars['DateTime']>
  updatedAt_not?: Maybe<Scalars['DateTime']>
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>
  updatedAt_lt?: Maybe<Scalars['DateTime']>
  updatedAt_lte?: Maybe<Scalars['DateTime']>
  updatedAt_gt?: Maybe<Scalars['DateTime']>
  updatedAt_gte?: Maybe<Scalars['DateTime']>
  name?: Maybe<Scalars['String']>
  name_not?: Maybe<Scalars['String']>
  name_in?: Maybe<Array<Scalars['String']>>
  name_not_in?: Maybe<Array<Scalars['String']>>
  name_lt?: Maybe<Scalars['String']>
  name_lte?: Maybe<Scalars['String']>
  name_gt?: Maybe<Scalars['String']>
  name_gte?: Maybe<Scalars['String']>
  name_contains?: Maybe<Scalars['String']>
  name_not_contains?: Maybe<Scalars['String']>
  name_starts_with?: Maybe<Scalars['String']>
  name_not_starts_with?: Maybe<Scalars['String']>
  name_ends_with?: Maybe<Scalars['String']>
  name_not_ends_with?: Maybe<Scalars['String']>
  price?: Maybe<Scalars['Int']>
  price_not?: Maybe<Scalars['Int']>
  price_in?: Maybe<Array<Scalars['Int']>>
  price_not_in?: Maybe<Array<Scalars['Int']>>
  price_lt?: Maybe<Scalars['Int']>
  price_lte?: Maybe<Scalars['Int']>
  price_gt?: Maybe<Scalars['Int']>
  price_gte?: Maybe<Scalars['Int']>
  featured?: Maybe<Scalars['Boolean']>
  featured_not?: Maybe<Scalars['Boolean']>
  mostChosen?: Maybe<Scalars['Boolean']>
  mostChosen_not?: Maybe<Scalars['Boolean']>
  description?: Maybe<Scalars['String']>
  description_not?: Maybe<Scalars['String']>
  description_in?: Maybe<Array<Scalars['String']>>
  description_not_in?: Maybe<Array<Scalars['String']>>
  description_lt?: Maybe<Scalars['String']>
  description_lte?: Maybe<Scalars['String']>
  description_gt?: Maybe<Scalars['String']>
  description_gte?: Maybe<Scalars['String']>
  description_contains?: Maybe<Scalars['String']>
  description_not_contains?: Maybe<Scalars['String']>
  description_starts_with?: Maybe<Scalars['String']>
  description_not_starts_with?: Maybe<Scalars['String']>
  description_ends_with?: Maybe<Scalars['String']>
  description_not_ends_with?: Maybe<Scalars['String']>
  summary?: Maybe<Scalars['String']>
  summary_not?: Maybe<Scalars['String']>
  summary_in?: Maybe<Array<Scalars['String']>>
  summary_not_in?: Maybe<Array<Scalars['String']>>
  summary_lt?: Maybe<Scalars['String']>
  summary_lte?: Maybe<Scalars['String']>
  summary_gt?: Maybe<Scalars['String']>
  summary_gte?: Maybe<Scalars['String']>
  summary_contains?: Maybe<Scalars['String']>
  summary_not_contains?: Maybe<Scalars['String']>
  summary_starts_with?: Maybe<Scalars['String']>
  summary_not_starts_with?: Maybe<Scalars['String']>
  summary_ends_with?: Maybe<Scalars['String']>
  summary_not_ends_with?: Maybe<Scalars['String']>
  AND?: Maybe<Array<PricePackageWhereInput>>
  OR?: Maybe<Array<PricePackageWhereInput>>
  NOT?: Maybe<Array<PricePackageWhereInput>>
}

export type PricePackageWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>
}

export type Query = {
  __typename?: 'Query'
  me?: Maybe<User>
  featuredCars?: Maybe<Array<FeaturedCar>>
  tracktrace?: Maybe<Order>
  bpmIndication?: Maybe<Order>
  brand?: Maybe<Brand>
  brands: Array<Brand>
  client?: Maybe<Client>
  clients: Array<Client>
  user?: Maybe<User>
  users: Array<User>
  order?: Maybe<Order>
  orders: Array<Order>
  ordersConnection: OrderConnection
  pricePackage?: Maybe<PricePackage>
  pricePackages: Array<PricePackage>
  usersConnection: UserConnection
  searchRequest?: Maybe<SearchRequest>
  searchRequests: Array<SearchRequest>
}

export type QueryTracktraceArgs = {
  orderNumber?: Maybe<Scalars['String']>
}

export type QueryBpmIndicationArgs = {
  hashedOrderNumber?: Maybe<Scalars['String']>
}

export type QueryBrandArgs = {
  where: BrandWhereUniqueInput
}

export type QueryBrandsArgs = {
  where?: Maybe<BrandWhereInput>
  orderBy?: Maybe<BrandOrderByInput>
  skip?: Maybe<Scalars['Int']>
  after?: Maybe<Scalars['String']>
  before?: Maybe<Scalars['String']>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
}

export type QueryClientArgs = {
  where: ClientWhereUniqueInput
}

export type QueryClientsArgs = {
  where?: Maybe<ClientWhereInput>
  orderBy?: Maybe<ClientOrderByInput>
  skip?: Maybe<Scalars['Int']>
  after?: Maybe<Scalars['String']>
  before?: Maybe<Scalars['String']>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
}

export type QueryUserArgs = {
  where: UserWhereUniqueInput
}

export type QueryUsersArgs = {
  where?: Maybe<UserWhereInput>
  orderBy?: Maybe<UserOrderByInput>
  skip?: Maybe<Scalars['Int']>
  after?: Maybe<Scalars['String']>
  before?: Maybe<Scalars['String']>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
}

export type QueryOrderArgs = {
  where: OrderWhereUniqueInput
}

export type QueryOrdersArgs = {
  where?: Maybe<OrderWhereInput>
  orderBy?: Maybe<OrderOrderByInput>
  skip?: Maybe<Scalars['Int']>
  after?: Maybe<Scalars['String']>
  before?: Maybe<Scalars['String']>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
}

export type QueryOrdersConnectionArgs = {
  where?: Maybe<OrderWhereInput>
  orderBy?: Maybe<OrderOrderByInput>
  skip?: Maybe<Scalars['Int']>
  after?: Maybe<Scalars['String']>
  before?: Maybe<Scalars['String']>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
}

export type QueryPricePackageArgs = {
  where: PricePackageWhereUniqueInput
}

export type QueryPricePackagesArgs = {
  where?: Maybe<PricePackageWhereInput>
  orderBy?: Maybe<PricePackageOrderByInput>
  skip?: Maybe<Scalars['Int']>
  after?: Maybe<Scalars['String']>
  before?: Maybe<Scalars['String']>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
}

export type QueryUsersConnectionArgs = {
  where?: Maybe<UserWhereInput>
  orderBy?: Maybe<UserOrderByInput>
  skip?: Maybe<Scalars['Int']>
  after?: Maybe<Scalars['String']>
  before?: Maybe<Scalars['String']>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
}

export type QuerySearchRequestArgs = {
  where: SearchRequestWhereUniqueInput
}

export type QuerySearchRequestsArgs = {
  where?: Maybe<SearchRequestWhereInput>
  orderBy?: Maybe<SearchRequestOrderByInput>
  skip?: Maybe<Scalars['Int']>
  after?: Maybe<Scalars['String']>
  before?: Maybe<Scalars['String']>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
}

export type SearchRequest = {
  __typename?: 'SearchRequest'
  id: Scalars['ID']
  createdAt: Scalars['DateTime']
  updatedAt: Scalars['DateTime']
  deletedAt?: Maybe<Scalars['DateTime']>
  archived?: Maybe<Scalars['DateTime']>
  client: Client
  order?: Maybe<Order>
  note: Scalars['String']
  done: Scalars['Boolean']
}

export type SearchRequestCreateManyWithoutClientInput = {
  create?: Maybe<Array<SearchRequestCreateWithoutClientInput>>
  connect?: Maybe<Array<SearchRequestWhereUniqueInput>>
}

export type SearchRequestCreateWithoutClientInput = {
  id?: Maybe<Scalars['ID']>
  deletedAt?: Maybe<Scalars['DateTime']>
  archived?: Maybe<Scalars['DateTime']>
  order?: Maybe<OrderCreateOneInput>
  note: Scalars['String']
  done?: Maybe<Scalars['Boolean']>
}

export enum SearchRequestOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  ArchivedAsc = 'archived_ASC',
  ArchivedDesc = 'archived_DESC',
  NoteAsc = 'note_ASC',
  NoteDesc = 'note_DESC',
  DoneAsc = 'done_ASC',
  DoneDesc = 'done_DESC',
}

export type SearchRequestScalarWhereInput = {
  id?: Maybe<Scalars['ID']>
  id_not?: Maybe<Scalars['ID']>
  id_in?: Maybe<Array<Scalars['ID']>>
  id_not_in?: Maybe<Array<Scalars['ID']>>
  id_lt?: Maybe<Scalars['ID']>
  id_lte?: Maybe<Scalars['ID']>
  id_gt?: Maybe<Scalars['ID']>
  id_gte?: Maybe<Scalars['ID']>
  id_contains?: Maybe<Scalars['ID']>
  id_not_contains?: Maybe<Scalars['ID']>
  id_starts_with?: Maybe<Scalars['ID']>
  id_not_starts_with?: Maybe<Scalars['ID']>
  id_ends_with?: Maybe<Scalars['ID']>
  id_not_ends_with?: Maybe<Scalars['ID']>
  createdAt?: Maybe<Scalars['DateTime']>
  createdAt_not?: Maybe<Scalars['DateTime']>
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>
  createdAt_lt?: Maybe<Scalars['DateTime']>
  createdAt_lte?: Maybe<Scalars['DateTime']>
  createdAt_gt?: Maybe<Scalars['DateTime']>
  createdAt_gte?: Maybe<Scalars['DateTime']>
  updatedAt?: Maybe<Scalars['DateTime']>
  updatedAt_not?: Maybe<Scalars['DateTime']>
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>
  updatedAt_lt?: Maybe<Scalars['DateTime']>
  updatedAt_lte?: Maybe<Scalars['DateTime']>
  updatedAt_gt?: Maybe<Scalars['DateTime']>
  updatedAt_gte?: Maybe<Scalars['DateTime']>
  deletedAt?: Maybe<Scalars['DateTime']>
  deletedAt_not?: Maybe<Scalars['DateTime']>
  deletedAt_in?: Maybe<Array<Scalars['DateTime']>>
  deletedAt_not_in?: Maybe<Array<Scalars['DateTime']>>
  deletedAt_lt?: Maybe<Scalars['DateTime']>
  deletedAt_lte?: Maybe<Scalars['DateTime']>
  deletedAt_gt?: Maybe<Scalars['DateTime']>
  deletedAt_gte?: Maybe<Scalars['DateTime']>
  archived?: Maybe<Scalars['DateTime']>
  archived_not?: Maybe<Scalars['DateTime']>
  archived_in?: Maybe<Array<Scalars['DateTime']>>
  archived_not_in?: Maybe<Array<Scalars['DateTime']>>
  archived_lt?: Maybe<Scalars['DateTime']>
  archived_lte?: Maybe<Scalars['DateTime']>
  archived_gt?: Maybe<Scalars['DateTime']>
  archived_gte?: Maybe<Scalars['DateTime']>
  note?: Maybe<Scalars['String']>
  note_not?: Maybe<Scalars['String']>
  note_in?: Maybe<Array<Scalars['String']>>
  note_not_in?: Maybe<Array<Scalars['String']>>
  note_lt?: Maybe<Scalars['String']>
  note_lte?: Maybe<Scalars['String']>
  note_gt?: Maybe<Scalars['String']>
  note_gte?: Maybe<Scalars['String']>
  note_contains?: Maybe<Scalars['String']>
  note_not_contains?: Maybe<Scalars['String']>
  note_starts_with?: Maybe<Scalars['String']>
  note_not_starts_with?: Maybe<Scalars['String']>
  note_ends_with?: Maybe<Scalars['String']>
  note_not_ends_with?: Maybe<Scalars['String']>
  done?: Maybe<Scalars['Boolean']>
  done_not?: Maybe<Scalars['Boolean']>
  AND?: Maybe<Array<SearchRequestScalarWhereInput>>
  OR?: Maybe<Array<SearchRequestScalarWhereInput>>
  NOT?: Maybe<Array<SearchRequestScalarWhereInput>>
}

export type SearchRequestUpdateManyDataInput = {
  deletedAt?: Maybe<Scalars['DateTime']>
  archived?: Maybe<Scalars['DateTime']>
  note?: Maybe<Scalars['String']>
  done?: Maybe<Scalars['Boolean']>
}

export type SearchRequestUpdateManyWithoutClientInput = {
  create?: Maybe<Array<SearchRequestCreateWithoutClientInput>>
  delete?: Maybe<Array<SearchRequestWhereUniqueInput>>
  connect?: Maybe<Array<SearchRequestWhereUniqueInput>>
  set?: Maybe<Array<SearchRequestWhereUniqueInput>>
  disconnect?: Maybe<Array<SearchRequestWhereUniqueInput>>
  update?: Maybe<Array<SearchRequestUpdateWithWhereUniqueWithoutClientInput>>
  upsert?: Maybe<Array<SearchRequestUpsertWithWhereUniqueWithoutClientInput>>
  deleteMany?: Maybe<Array<SearchRequestScalarWhereInput>>
  updateMany?: Maybe<Array<SearchRequestUpdateManyWithWhereNestedInput>>
}

export type SearchRequestUpdateManyWithWhereNestedInput = {
  where: SearchRequestScalarWhereInput
  data: SearchRequestUpdateManyDataInput
}

export type SearchRequestUpdateWithoutClientDataInput = {
  deletedAt?: Maybe<Scalars['DateTime']>
  archived?: Maybe<Scalars['DateTime']>
  order?: Maybe<OrderUpdateOneInput>
  note?: Maybe<Scalars['String']>
  done?: Maybe<Scalars['Boolean']>
}

export type SearchRequestUpdateWithWhereUniqueWithoutClientInput = {
  where: SearchRequestWhereUniqueInput
  data: SearchRequestUpdateWithoutClientDataInput
}

export type SearchRequestUpsertWithWhereUniqueWithoutClientInput = {
  where: SearchRequestWhereUniqueInput
  update: SearchRequestUpdateWithoutClientDataInput
  create: SearchRequestCreateWithoutClientInput
}

export type SearchRequestWhereInput = {
  id?: Maybe<Scalars['ID']>
  id_not?: Maybe<Scalars['ID']>
  id_in?: Maybe<Array<Scalars['ID']>>
  id_not_in?: Maybe<Array<Scalars['ID']>>
  id_lt?: Maybe<Scalars['ID']>
  id_lte?: Maybe<Scalars['ID']>
  id_gt?: Maybe<Scalars['ID']>
  id_gte?: Maybe<Scalars['ID']>
  id_contains?: Maybe<Scalars['ID']>
  id_not_contains?: Maybe<Scalars['ID']>
  id_starts_with?: Maybe<Scalars['ID']>
  id_not_starts_with?: Maybe<Scalars['ID']>
  id_ends_with?: Maybe<Scalars['ID']>
  id_not_ends_with?: Maybe<Scalars['ID']>
  createdAt?: Maybe<Scalars['DateTime']>
  createdAt_not?: Maybe<Scalars['DateTime']>
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>
  createdAt_lt?: Maybe<Scalars['DateTime']>
  createdAt_lte?: Maybe<Scalars['DateTime']>
  createdAt_gt?: Maybe<Scalars['DateTime']>
  createdAt_gte?: Maybe<Scalars['DateTime']>
  updatedAt?: Maybe<Scalars['DateTime']>
  updatedAt_not?: Maybe<Scalars['DateTime']>
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>
  updatedAt_lt?: Maybe<Scalars['DateTime']>
  updatedAt_lte?: Maybe<Scalars['DateTime']>
  updatedAt_gt?: Maybe<Scalars['DateTime']>
  updatedAt_gte?: Maybe<Scalars['DateTime']>
  deletedAt?: Maybe<Scalars['DateTime']>
  deletedAt_not?: Maybe<Scalars['DateTime']>
  deletedAt_in?: Maybe<Array<Scalars['DateTime']>>
  deletedAt_not_in?: Maybe<Array<Scalars['DateTime']>>
  deletedAt_lt?: Maybe<Scalars['DateTime']>
  deletedAt_lte?: Maybe<Scalars['DateTime']>
  deletedAt_gt?: Maybe<Scalars['DateTime']>
  deletedAt_gte?: Maybe<Scalars['DateTime']>
  archived?: Maybe<Scalars['DateTime']>
  archived_not?: Maybe<Scalars['DateTime']>
  archived_in?: Maybe<Array<Scalars['DateTime']>>
  archived_not_in?: Maybe<Array<Scalars['DateTime']>>
  archived_lt?: Maybe<Scalars['DateTime']>
  archived_lte?: Maybe<Scalars['DateTime']>
  archived_gt?: Maybe<Scalars['DateTime']>
  archived_gte?: Maybe<Scalars['DateTime']>
  client?: Maybe<ClientWhereInput>
  order?: Maybe<OrderWhereInput>
  note?: Maybe<Scalars['String']>
  note_not?: Maybe<Scalars['String']>
  note_in?: Maybe<Array<Scalars['String']>>
  note_not_in?: Maybe<Array<Scalars['String']>>
  note_lt?: Maybe<Scalars['String']>
  note_lte?: Maybe<Scalars['String']>
  note_gt?: Maybe<Scalars['String']>
  note_gte?: Maybe<Scalars['String']>
  note_contains?: Maybe<Scalars['String']>
  note_not_contains?: Maybe<Scalars['String']>
  note_starts_with?: Maybe<Scalars['String']>
  note_not_starts_with?: Maybe<Scalars['String']>
  note_ends_with?: Maybe<Scalars['String']>
  note_not_ends_with?: Maybe<Scalars['String']>
  done?: Maybe<Scalars['Boolean']>
  done_not?: Maybe<Scalars['Boolean']>
  AND?: Maybe<Array<SearchRequestWhereInput>>
  OR?: Maybe<Array<SearchRequestWhereInput>>
  NOT?: Maybe<Array<SearchRequestWhereInput>>
}

export type SearchRequestWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>
}

export type User = {
  __typename?: 'User'
  id: Scalars['ID']
  name: Scalars['String']
  email: Scalars['String']
}

export type UserConnection = {
  __typename?: 'UserConnection'
  pageInfo: PageInfo
  edges: Array<UserEdge>
  aggregate: AggregateUser
}

export type UserCreateInput = {
  id?: Maybe<Scalars['ID']>
  deletedAt?: Maybe<Scalars['DateTime']>
  email: Scalars['String']
  password: Scalars['String']
  name: Scalars['String']
}

export type UserCreateOneInput = {
  create?: Maybe<UserCreateInput>
  connect?: Maybe<UserWhereUniqueInput>
}

export type UserEdge = {
  __typename?: 'UserEdge'
  node: User
  cursor: Scalars['String']
}

export enum UserOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  EmailAsc = 'email_ASC',
  EmailDesc = 'email_DESC',
  PasswordAsc = 'password_ASC',
  PasswordDesc = 'password_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
}

export type UserUpdateDataInput = {
  deletedAt?: Maybe<Scalars['DateTime']>
  email?: Maybe<Scalars['String']>
  password?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
}

export type UserUpdateInput = {
  deletedAt?: Maybe<Scalars['DateTime']>
  email?: Maybe<Scalars['String']>
  password?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
}

export type UserUpdateOneInput = {
  create?: Maybe<UserCreateInput>
  update?: Maybe<UserUpdateDataInput>
  upsert?: Maybe<UserUpsertNestedInput>
  delete?: Maybe<Scalars['Boolean']>
  disconnect?: Maybe<Scalars['Boolean']>
  connect?: Maybe<UserWhereUniqueInput>
}

export type UserUpsertNestedInput = {
  update: UserUpdateDataInput
  create: UserCreateInput
}

export type UserWhereInput = {
  id?: Maybe<Scalars['ID']>
  id_not?: Maybe<Scalars['ID']>
  id_in?: Maybe<Array<Scalars['ID']>>
  id_not_in?: Maybe<Array<Scalars['ID']>>
  id_lt?: Maybe<Scalars['ID']>
  id_lte?: Maybe<Scalars['ID']>
  id_gt?: Maybe<Scalars['ID']>
  id_gte?: Maybe<Scalars['ID']>
  id_contains?: Maybe<Scalars['ID']>
  id_not_contains?: Maybe<Scalars['ID']>
  id_starts_with?: Maybe<Scalars['ID']>
  id_not_starts_with?: Maybe<Scalars['ID']>
  id_ends_with?: Maybe<Scalars['ID']>
  id_not_ends_with?: Maybe<Scalars['ID']>
  createdAt?: Maybe<Scalars['DateTime']>
  createdAt_not?: Maybe<Scalars['DateTime']>
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>
  createdAt_lt?: Maybe<Scalars['DateTime']>
  createdAt_lte?: Maybe<Scalars['DateTime']>
  createdAt_gt?: Maybe<Scalars['DateTime']>
  createdAt_gte?: Maybe<Scalars['DateTime']>
  updatedAt?: Maybe<Scalars['DateTime']>
  updatedAt_not?: Maybe<Scalars['DateTime']>
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>
  updatedAt_lt?: Maybe<Scalars['DateTime']>
  updatedAt_lte?: Maybe<Scalars['DateTime']>
  updatedAt_gt?: Maybe<Scalars['DateTime']>
  updatedAt_gte?: Maybe<Scalars['DateTime']>
  deletedAt?: Maybe<Scalars['DateTime']>
  deletedAt_not?: Maybe<Scalars['DateTime']>
  deletedAt_in?: Maybe<Array<Scalars['DateTime']>>
  deletedAt_not_in?: Maybe<Array<Scalars['DateTime']>>
  deletedAt_lt?: Maybe<Scalars['DateTime']>
  deletedAt_lte?: Maybe<Scalars['DateTime']>
  deletedAt_gt?: Maybe<Scalars['DateTime']>
  deletedAt_gte?: Maybe<Scalars['DateTime']>
  email?: Maybe<Scalars['String']>
  email_not?: Maybe<Scalars['String']>
  email_in?: Maybe<Array<Scalars['String']>>
  email_not_in?: Maybe<Array<Scalars['String']>>
  email_lt?: Maybe<Scalars['String']>
  email_lte?: Maybe<Scalars['String']>
  email_gt?: Maybe<Scalars['String']>
  email_gte?: Maybe<Scalars['String']>
  email_contains?: Maybe<Scalars['String']>
  email_not_contains?: Maybe<Scalars['String']>
  email_starts_with?: Maybe<Scalars['String']>
  email_not_starts_with?: Maybe<Scalars['String']>
  email_ends_with?: Maybe<Scalars['String']>
  email_not_ends_with?: Maybe<Scalars['String']>
  password?: Maybe<Scalars['String']>
  password_not?: Maybe<Scalars['String']>
  password_in?: Maybe<Array<Scalars['String']>>
  password_not_in?: Maybe<Array<Scalars['String']>>
  password_lt?: Maybe<Scalars['String']>
  password_lte?: Maybe<Scalars['String']>
  password_gt?: Maybe<Scalars['String']>
  password_gte?: Maybe<Scalars['String']>
  password_contains?: Maybe<Scalars['String']>
  password_not_contains?: Maybe<Scalars['String']>
  password_starts_with?: Maybe<Scalars['String']>
  password_not_starts_with?: Maybe<Scalars['String']>
  password_ends_with?: Maybe<Scalars['String']>
  password_not_ends_with?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  name_not?: Maybe<Scalars['String']>
  name_in?: Maybe<Array<Scalars['String']>>
  name_not_in?: Maybe<Array<Scalars['String']>>
  name_lt?: Maybe<Scalars['String']>
  name_lte?: Maybe<Scalars['String']>
  name_gt?: Maybe<Scalars['String']>
  name_gte?: Maybe<Scalars['String']>
  name_contains?: Maybe<Scalars['String']>
  name_not_contains?: Maybe<Scalars['String']>
  name_starts_with?: Maybe<Scalars['String']>
  name_not_starts_with?: Maybe<Scalars['String']>
  name_ends_with?: Maybe<Scalars['String']>
  name_not_ends_with?: Maybe<Scalars['String']>
  AND?: Maybe<Array<UserWhereInput>>
  OR?: Maybe<Array<UserWhereInput>>
  NOT?: Maybe<Array<UserWhereInput>>
}

export type UserWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>
  email?: Maybe<Scalars['String']>
}

export type RequestBpmIndicationMutationVariables = {
  name?: Maybe<Scalars['String']>
  telephone?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['String']>
  link?: Maybe<Scalars['String']>
}

export type RequestBpmIndicationMutation = { __typename?: 'Mutation' } & {
  requestBpmIndication?: Maybe<{ __typename?: 'Order' } & Pick<Order, 'id'>>
}

export type RequestSearchMutationVariables = {
  name?: Maybe<Scalars['String']>
  telephone?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['String']>
  note?: Maybe<Scalars['String']>
}

export type RequestSearchMutation = { __typename?: 'Mutation' } & {
  requestSearch?: Maybe<
    { __typename?: 'SearchRequest' } & Pick<SearchRequest, 'id'>
  >
}

export type FeaturedCarsQueryVariables = {}

export type FeaturedCarsQuery = { __typename?: 'Query' } & {
  featuredCars?: Maybe<
    Array<
      { __typename?: 'FeaturedCar' } & Pick<
        FeaturedCar,
        'id' | 'carPrice' | 'saving' | 'model'
      > & {
          brand?: Maybe<
            { __typename?: 'Brand' } & Pick<Brand, 'name'> & {
                logo?: Maybe<{ __typename?: 'File' } & Pick<File, 'location'>>
              }
          >
          images?: Maybe<
            Array<{ __typename?: 'File' } & Pick<File, 'location'>>
          >
        }
    >
  >
}

export type PricePackageFragmentFragment = {
  __typename?: 'PricePackage'
} & Pick<
  PricePackage,
  | 'id'
  | 'name'
  | 'description'
  | 'summary'
  | 'properties'
  | 'featured'
  | 'mostChosen'
  | 'price'
>

export type CancelOrderMutationVariables = {
  hashedOrderNumber: Scalars['String']
  reason: Scalars['String']
}

export type CancelOrderMutation = { __typename?: 'Mutation' } & {
  cancelOrder?: Maybe<{ __typename?: 'Order' } & Pick<Order, 'id'>>
}

export type RequestQuotationMutationVariables = {
  acceptTermsAndConditions: Scalars['Boolean']
  pricePackageId: Scalars['String']
  hashedOrderNumber: Scalars['String']
}

export type RequestQuotationMutation = { __typename?: 'Mutation' } & {
  requestQuotation?: Maybe<{ __typename?: 'Order' } & Pick<Order, 'id'>>
}

export type SendClientTrackTraceMessageMutationVariables = {
  orderNumber: Scalars['String']
  message: Scalars['String']
}

export type SendClientTrackTraceMessageMutation = {
  __typename?: 'Mutation'
} & {
  sendClientTrackTraceMessage?: Maybe<
    { __typename?: 'Order' } & Pick<Order, 'id'> & {
        messages?: Maybe<
          Array<
            { __typename?: 'Message' } & Pick<Message, 'id' | 'content'> & {
                client?: Maybe<
                  { __typename?: 'Client' } & Pick<Client, 'id' | 'name'>
                >
                user?: Maybe<
                  { __typename?: 'User' } & Pick<User, 'id' | 'name'>
                >
              }
          >
        >
      }
  >
}

export type TrackTraceLoginMutationVariables = {
  orderNumber: Scalars['String']
  email: Scalars['String']
}

export type TrackTraceLoginMutation = { __typename?: 'Mutation' } & {
  trackTraceLogin?: Maybe<
    { __typename?: 'ClientAuthPayload' } & {
      client?: Maybe<{ __typename?: 'Client' } & Pick<Client, 'id'>>
    }
  >
}

export type BpmIndicationQueryVariables = {
  hashedOrderNumber: Scalars['String']
}

export type BpmIndicationQuery = { __typename?: 'Query' } & {
  bpmIndication?: Maybe<
    { __typename?: 'Order' } & {
      client: { __typename?: 'Client' } & Pick<Client, 'id'>
      car?: Maybe<
        { __typename?: 'Car' } & Pick<
          Car,
          'carPrice' | 'saving' | 'bpmPrice' | 'model'
        > & { brand: { __typename?: 'Brand' } & Pick<Brand, 'name'> }
      >
    }
  >
}

export type PricePackagesQueryVariables = {
  where?: Maybe<PricePackageWhereInput>
}

export type PricePackagesQuery = { __typename?: 'Query' } & {
  pricePackages: Array<
    { __typename?: 'PricePackage' } & PricePackageFragmentFragment
  >
}

export type TrackTraceQueryVariables = {
  orderNumber: Scalars['String']
}

export type TrackTraceQuery = { __typename?: 'Query' } & {
  tracktrace?: Maybe<
    { __typename?: 'Order' } & Pick<Order, 'id' | 'orderNumber' | 'status'> & {
        priceItems?: Maybe<
          Array<
            { __typename?: 'PriceItem' } & Pick<
              PriceItem,
              'id' | 'name' | 'price' | 'VAT' | 'inclVAT' | 'amount'
            >
          >
        >
        pricePackage?: Maybe<
          { __typename?: 'PricePackage' } & Pick<
            PricePackage,
            'id' | 'name' | 'price' | 'description'
          >
        >
        car?: Maybe<
          { __typename?: 'Car' } & Pick<
            Car,
            | 'carPrice'
            | 'bpmPrice'
            | 'saving'
            | 'id'
            | 'model'
            | 'version'
            | 'co2'
            | 'color'
            | 'paint'
            | 'interiorColor'
            | 'interiorType'
            | 'transmission'
            | 'fuelType'
            | 'year'
            | 'power'
            | 'displacement'
          > & {
              brand: { __typename?: 'Brand' } & Pick<Brand, 'name'>
              images?: Maybe<
                Array<{ __typename?: 'File' } & Pick<File, 'id' | 'location'>>
              >
            }
        >
        statusLogs?: Maybe<
          Array<
            { __typename?: 'OrderStatusLog' } & Pick<
              OrderStatusLog,
              'id' | 'createdAt' | 'status'
            >
          >
        >
        messages?: Maybe<
          Array<
            { __typename?: 'Message' } & Pick<Message, 'id' | 'content'> & {
                client?: Maybe<
                  { __typename?: 'Client' } & Pick<Client, 'id' | 'name'>
                >
                user?: Maybe<
                  { __typename?: 'User' } & Pick<User, 'id' | 'name'>
                >
              }
          >
        >
      }
  >
}

export const PricePackageFragmentFragmentDoc = gql`
  fragment PricePackageFragment on PricePackage {
    id
    name
    description
    summary
    properties
    featured
    mostChosen
    price
  }
`
export const RequestBpmIndicationDocument = gql`
  mutation RequestBpmIndication(
    $name: String
    $telephone: String
    $email: String
    $link: String
  ) {
    requestBpmIndication(
      name: $name
      telephone: $telephone
      email: $email
      link: $link
    ) {
      id
    }
  }
`
export type RequestBpmIndicationMutationFn = ApolloReactCommon.MutationFunction<
  RequestBpmIndicationMutation,
  RequestBpmIndicationMutationVariables
>
export type RequestBpmIndicationComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    RequestBpmIndicationMutation,
    RequestBpmIndicationMutationVariables
  >,
  'mutation'
>

export const RequestBpmIndicationComponent = (
  props: RequestBpmIndicationComponentProps
) => (
  <ApolloReactComponents.Mutation<
    RequestBpmIndicationMutation,
    RequestBpmIndicationMutationVariables
  >
    mutation={RequestBpmIndicationDocument}
    {...props}
  />
)

export type RequestBpmIndicationProps<
  TChildProps = {}
> = ApolloReactHoc.MutateProps<
  RequestBpmIndicationMutation,
  RequestBpmIndicationMutationVariables
> &
  TChildProps
export function withRequestBpmIndication<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    RequestBpmIndicationMutation,
    RequestBpmIndicationMutationVariables,
    RequestBpmIndicationProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    RequestBpmIndicationMutation,
    RequestBpmIndicationMutationVariables,
    RequestBpmIndicationProps<TChildProps>
  >(RequestBpmIndicationDocument, {
    alias: 'requestBpmIndication',
    ...operationOptions,
  })
}

/**
 * __useRequestBpmIndicationMutation__
 *
 * To run a mutation, you first call `useRequestBpmIndicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestBpmIndicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestBpmIndicationMutation, { data, loading, error }] = useRequestBpmIndicationMutation({
 *   variables: {
 *      name: // value for 'name'
 *      telephone: // value for 'telephone'
 *      email: // value for 'email'
 *      link: // value for 'link'
 *   },
 * });
 */
export function useRequestBpmIndicationMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    RequestBpmIndicationMutation,
    RequestBpmIndicationMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    RequestBpmIndicationMutation,
    RequestBpmIndicationMutationVariables
  >(RequestBpmIndicationDocument, baseOptions)
}
export type RequestBpmIndicationMutationHookResult = ReturnType<
  typeof useRequestBpmIndicationMutation
>
export type RequestBpmIndicationMutationResult = ApolloReactCommon.MutationResult<RequestBpmIndicationMutation>
export type RequestBpmIndicationMutationOptions = ApolloReactCommon.BaseMutationOptions<
  RequestBpmIndicationMutation,
  RequestBpmIndicationMutationVariables
>
export const RequestSearchDocument = gql`
  mutation RequestSearch(
    $name: String
    $telephone: String
    $email: String
    $note: String
  ) {
    requestSearch(
      name: $name
      telephone: $telephone
      email: $email
      note: $note
    ) {
      id
    }
  }
`
export type RequestSearchMutationFn = ApolloReactCommon.MutationFunction<
  RequestSearchMutation,
  RequestSearchMutationVariables
>
export type RequestSearchComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    RequestSearchMutation,
    RequestSearchMutationVariables
  >,
  'mutation'
>

export const RequestSearchComponent = (props: RequestSearchComponentProps) => (
  <ApolloReactComponents.Mutation<
    RequestSearchMutation,
    RequestSearchMutationVariables
  >
    mutation={RequestSearchDocument}
    {...props}
  />
)

export type RequestSearchProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  RequestSearchMutation,
  RequestSearchMutationVariables
> &
  TChildProps
export function withRequestSearch<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    RequestSearchMutation,
    RequestSearchMutationVariables,
    RequestSearchProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    RequestSearchMutation,
    RequestSearchMutationVariables,
    RequestSearchProps<TChildProps>
  >(RequestSearchDocument, {
    alias: 'requestSearch',
    ...operationOptions,
  })
}

/**
 * __useRequestSearchMutation__
 *
 * To run a mutation, you first call `useRequestSearchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestSearchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestSearchMutation, { data, loading, error }] = useRequestSearchMutation({
 *   variables: {
 *      name: // value for 'name'
 *      telephone: // value for 'telephone'
 *      email: // value for 'email'
 *      note: // value for 'note'
 *   },
 * });
 */
export function useRequestSearchMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    RequestSearchMutation,
    RequestSearchMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    RequestSearchMutation,
    RequestSearchMutationVariables
  >(RequestSearchDocument, baseOptions)
}
export type RequestSearchMutationHookResult = ReturnType<
  typeof useRequestSearchMutation
>
export type RequestSearchMutationResult = ApolloReactCommon.MutationResult<RequestSearchMutation>
export type RequestSearchMutationOptions = ApolloReactCommon.BaseMutationOptions<
  RequestSearchMutation,
  RequestSearchMutationVariables
>
export const FeaturedCarsDocument = gql`
  query FeaturedCars {
    featuredCars {
      id
      carPrice
      saving
      model
      brand {
        logo {
          location
        }
        name
      }
      images {
        location
      }
    }
  }
`
export type FeaturedCarsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    FeaturedCarsQuery,
    FeaturedCarsQueryVariables
  >,
  'query'
>

export const FeaturedCarsComponent = (props: FeaturedCarsComponentProps) => (
  <ApolloReactComponents.Query<FeaturedCarsQuery, FeaturedCarsQueryVariables>
    query={FeaturedCarsDocument}
    {...props}
  />
)

export type FeaturedCarsProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  FeaturedCarsQuery,
  FeaturedCarsQueryVariables
> &
  TChildProps
export function withFeaturedCars<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    FeaturedCarsQuery,
    FeaturedCarsQueryVariables,
    FeaturedCarsProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    FeaturedCarsQuery,
    FeaturedCarsQueryVariables,
    FeaturedCarsProps<TChildProps>
  >(FeaturedCarsDocument, {
    alias: 'featuredCars',
    ...operationOptions,
  })
}

/**
 * __useFeaturedCarsQuery__
 *
 * To run a query within a React component, call `useFeaturedCarsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFeaturedCarsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFeaturedCarsQuery({
 *   variables: {
 *   },
 * });
 */
export function useFeaturedCarsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    FeaturedCarsQuery,
    FeaturedCarsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    FeaturedCarsQuery,
    FeaturedCarsQueryVariables
  >(FeaturedCarsDocument, baseOptions)
}
export function useFeaturedCarsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    FeaturedCarsQuery,
    FeaturedCarsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    FeaturedCarsQuery,
    FeaturedCarsQueryVariables
  >(FeaturedCarsDocument, baseOptions)
}
export type FeaturedCarsQueryHookResult = ReturnType<
  typeof useFeaturedCarsQuery
>
export type FeaturedCarsLazyQueryHookResult = ReturnType<
  typeof useFeaturedCarsLazyQuery
>
export type FeaturedCarsQueryResult = ApolloReactCommon.QueryResult<
  FeaturedCarsQuery,
  FeaturedCarsQueryVariables
>
export const CancelOrderDocument = gql`
  mutation CancelOrder($hashedOrderNumber: String!, $reason: String!) {
    cancelOrder(hashedOrderNumber: $hashedOrderNumber, reason: $reason) {
      id
    }
  }
`
export type CancelOrderMutationFn = ApolloReactCommon.MutationFunction<
  CancelOrderMutation,
  CancelOrderMutationVariables
>
export type CancelOrderComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    CancelOrderMutation,
    CancelOrderMutationVariables
  >,
  'mutation'
>

export const CancelOrderComponent = (props: CancelOrderComponentProps) => (
  <ApolloReactComponents.Mutation<
    CancelOrderMutation,
    CancelOrderMutationVariables
  >
    mutation={CancelOrderDocument}
    {...props}
  />
)

export type CancelOrderProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  CancelOrderMutation,
  CancelOrderMutationVariables
> &
  TChildProps
export function withCancelOrder<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CancelOrderMutation,
    CancelOrderMutationVariables,
    CancelOrderProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CancelOrderMutation,
    CancelOrderMutationVariables,
    CancelOrderProps<TChildProps>
  >(CancelOrderDocument, {
    alias: 'cancelOrder',
    ...operationOptions,
  })
}

/**
 * __useCancelOrderMutation__
 *
 * To run a mutation, you first call `useCancelOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelOrderMutation, { data, loading, error }] = useCancelOrderMutation({
 *   variables: {
 *      hashedOrderNumber: // value for 'hashedOrderNumber'
 *      reason: // value for 'reason'
 *   },
 * });
 */
export function useCancelOrderMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CancelOrderMutation,
    CancelOrderMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    CancelOrderMutation,
    CancelOrderMutationVariables
  >(CancelOrderDocument, baseOptions)
}
export type CancelOrderMutationHookResult = ReturnType<
  typeof useCancelOrderMutation
>
export type CancelOrderMutationResult = ApolloReactCommon.MutationResult<CancelOrderMutation>
export type CancelOrderMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CancelOrderMutation,
  CancelOrderMutationVariables
>
export const RequestQuotationDocument = gql`
  mutation RequestQuotation(
    $acceptTermsAndConditions: Boolean!
    $pricePackageId: String!
    $hashedOrderNumber: String!
  ) {
    requestQuotation(
      acceptTermsAndConditions: $acceptTermsAndConditions
      pricePackageId: $pricePackageId
      hashedOrderNumber: $hashedOrderNumber
    ) {
      id
    }
  }
`
export type RequestQuotationMutationFn = ApolloReactCommon.MutationFunction<
  RequestQuotationMutation,
  RequestQuotationMutationVariables
>
export type RequestQuotationComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    RequestQuotationMutation,
    RequestQuotationMutationVariables
  >,
  'mutation'
>

export const RequestQuotationComponent = (
  props: RequestQuotationComponentProps
) => (
  <ApolloReactComponents.Mutation<
    RequestQuotationMutation,
    RequestQuotationMutationVariables
  >
    mutation={RequestQuotationDocument}
    {...props}
  />
)

export type RequestQuotationProps<
  TChildProps = {}
> = ApolloReactHoc.MutateProps<
  RequestQuotationMutation,
  RequestQuotationMutationVariables
> &
  TChildProps
export function withRequestQuotation<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    RequestQuotationMutation,
    RequestQuotationMutationVariables,
    RequestQuotationProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    RequestQuotationMutation,
    RequestQuotationMutationVariables,
    RequestQuotationProps<TChildProps>
  >(RequestQuotationDocument, {
    alias: 'requestQuotation',
    ...operationOptions,
  })
}

/**
 * __useRequestQuotationMutation__
 *
 * To run a mutation, you first call `useRequestQuotationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestQuotationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestQuotationMutation, { data, loading, error }] = useRequestQuotationMutation({
 *   variables: {
 *      acceptTermsAndConditions: // value for 'acceptTermsAndConditions'
 *      pricePackageId: // value for 'pricePackageId'
 *      hashedOrderNumber: // value for 'hashedOrderNumber'
 *   },
 * });
 */
export function useRequestQuotationMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    RequestQuotationMutation,
    RequestQuotationMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    RequestQuotationMutation,
    RequestQuotationMutationVariables
  >(RequestQuotationDocument, baseOptions)
}
export type RequestQuotationMutationHookResult = ReturnType<
  typeof useRequestQuotationMutation
>
export type RequestQuotationMutationResult = ApolloReactCommon.MutationResult<RequestQuotationMutation>
export type RequestQuotationMutationOptions = ApolloReactCommon.BaseMutationOptions<
  RequestQuotationMutation,
  RequestQuotationMutationVariables
>
export const SendClientTrackTraceMessageDocument = gql`
  mutation SendClientTrackTraceMessage(
    $orderNumber: String!
    $message: String!
  ) {
    sendClientTrackTraceMessage(orderNumber: $orderNumber, message: $message) {
      id
      messages {
        id
        client {
          id
          name
        }
        user {
          id
          name
        }
        content
      }
    }
  }
`
export type SendClientTrackTraceMessageMutationFn = ApolloReactCommon.MutationFunction<
  SendClientTrackTraceMessageMutation,
  SendClientTrackTraceMessageMutationVariables
>
export type SendClientTrackTraceMessageComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    SendClientTrackTraceMessageMutation,
    SendClientTrackTraceMessageMutationVariables
  >,
  'mutation'
>

export const SendClientTrackTraceMessageComponent = (
  props: SendClientTrackTraceMessageComponentProps
) => (
  <ApolloReactComponents.Mutation<
    SendClientTrackTraceMessageMutation,
    SendClientTrackTraceMessageMutationVariables
  >
    mutation={SendClientTrackTraceMessageDocument}
    {...props}
  />
)

export type SendClientTrackTraceMessageProps<
  TChildProps = {}
> = ApolloReactHoc.MutateProps<
  SendClientTrackTraceMessageMutation,
  SendClientTrackTraceMessageMutationVariables
> &
  TChildProps
export function withSendClientTrackTraceMessage<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    SendClientTrackTraceMessageMutation,
    SendClientTrackTraceMessageMutationVariables,
    SendClientTrackTraceMessageProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    SendClientTrackTraceMessageMutation,
    SendClientTrackTraceMessageMutationVariables,
    SendClientTrackTraceMessageProps<TChildProps>
  >(SendClientTrackTraceMessageDocument, {
    alias: 'sendClientTrackTraceMessage',
    ...operationOptions,
  })
}

/**
 * __useSendClientTrackTraceMessageMutation__
 *
 * To run a mutation, you first call `useSendClientTrackTraceMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendClientTrackTraceMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendClientTrackTraceMessageMutation, { data, loading, error }] = useSendClientTrackTraceMessageMutation({
 *   variables: {
 *      orderNumber: // value for 'orderNumber'
 *      message: // value for 'message'
 *   },
 * });
 */
export function useSendClientTrackTraceMessageMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    SendClientTrackTraceMessageMutation,
    SendClientTrackTraceMessageMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    SendClientTrackTraceMessageMutation,
    SendClientTrackTraceMessageMutationVariables
  >(SendClientTrackTraceMessageDocument, baseOptions)
}
export type SendClientTrackTraceMessageMutationHookResult = ReturnType<
  typeof useSendClientTrackTraceMessageMutation
>
export type SendClientTrackTraceMessageMutationResult = ApolloReactCommon.MutationResult<SendClientTrackTraceMessageMutation>
export type SendClientTrackTraceMessageMutationOptions = ApolloReactCommon.BaseMutationOptions<
  SendClientTrackTraceMessageMutation,
  SendClientTrackTraceMessageMutationVariables
>
export const TrackTraceLoginDocument = gql`
  mutation TrackTraceLogin($orderNumber: String!, $email: String!) {
    trackTraceLogin(orderNumber: $orderNumber, email: $email) {
      client {
        id
      }
    }
  }
`
export type TrackTraceLoginMutationFn = ApolloReactCommon.MutationFunction<
  TrackTraceLoginMutation,
  TrackTraceLoginMutationVariables
>
export type TrackTraceLoginComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    TrackTraceLoginMutation,
    TrackTraceLoginMutationVariables
  >,
  'mutation'
>

export const TrackTraceLoginComponent = (
  props: TrackTraceLoginComponentProps
) => (
  <ApolloReactComponents.Mutation<
    TrackTraceLoginMutation,
    TrackTraceLoginMutationVariables
  >
    mutation={TrackTraceLoginDocument}
    {...props}
  />
)

export type TrackTraceLoginProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  TrackTraceLoginMutation,
  TrackTraceLoginMutationVariables
> &
  TChildProps
export function withTrackTraceLogin<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    TrackTraceLoginMutation,
    TrackTraceLoginMutationVariables,
    TrackTraceLoginProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    TrackTraceLoginMutation,
    TrackTraceLoginMutationVariables,
    TrackTraceLoginProps<TChildProps>
  >(TrackTraceLoginDocument, {
    alias: 'trackTraceLogin',
    ...operationOptions,
  })
}

/**
 * __useTrackTraceLoginMutation__
 *
 * To run a mutation, you first call `useTrackTraceLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTrackTraceLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [trackTraceLoginMutation, { data, loading, error }] = useTrackTraceLoginMutation({
 *   variables: {
 *      orderNumber: // value for 'orderNumber'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useTrackTraceLoginMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    TrackTraceLoginMutation,
    TrackTraceLoginMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    TrackTraceLoginMutation,
    TrackTraceLoginMutationVariables
  >(TrackTraceLoginDocument, baseOptions)
}
export type TrackTraceLoginMutationHookResult = ReturnType<
  typeof useTrackTraceLoginMutation
>
export type TrackTraceLoginMutationResult = ApolloReactCommon.MutationResult<TrackTraceLoginMutation>
export type TrackTraceLoginMutationOptions = ApolloReactCommon.BaseMutationOptions<
  TrackTraceLoginMutation,
  TrackTraceLoginMutationVariables
>
export const BpmIndicationDocument = gql`
  query BpmIndication($hashedOrderNumber: String!) {
    bpmIndication(hashedOrderNumber: $hashedOrderNumber) {
      client {
        id
      }
      car {
        carPrice
        saving
        bpmPrice
        brand {
          name
        }
        model
      }
    }
  }
`
export type BpmIndicationComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    BpmIndicationQuery,
    BpmIndicationQueryVariables
  >,
  'query'
> &
  (
    | { variables: BpmIndicationQueryVariables; skip?: boolean }
    | { skip: boolean }
  )

export const BpmIndicationComponent = (props: BpmIndicationComponentProps) => (
  <ApolloReactComponents.Query<BpmIndicationQuery, BpmIndicationQueryVariables>
    query={BpmIndicationDocument}
    {...props}
  />
)

export type BpmIndicationProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  BpmIndicationQuery,
  BpmIndicationQueryVariables
> &
  TChildProps
export function withBpmIndication<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    BpmIndicationQuery,
    BpmIndicationQueryVariables,
    BpmIndicationProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    BpmIndicationQuery,
    BpmIndicationQueryVariables,
    BpmIndicationProps<TChildProps>
  >(BpmIndicationDocument, {
    alias: 'bpmIndication',
    ...operationOptions,
  })
}

/**
 * __useBpmIndicationQuery__
 *
 * To run a query within a React component, call `useBpmIndicationQuery` and pass it any options that fit your needs.
 * When your component renders, `useBpmIndicationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBpmIndicationQuery({
 *   variables: {
 *      hashedOrderNumber: // value for 'hashedOrderNumber'
 *   },
 * });
 */
export function useBpmIndicationQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    BpmIndicationQuery,
    BpmIndicationQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    BpmIndicationQuery,
    BpmIndicationQueryVariables
  >(BpmIndicationDocument, baseOptions)
}
export function useBpmIndicationLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    BpmIndicationQuery,
    BpmIndicationQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    BpmIndicationQuery,
    BpmIndicationQueryVariables
  >(BpmIndicationDocument, baseOptions)
}
export type BpmIndicationQueryHookResult = ReturnType<
  typeof useBpmIndicationQuery
>
export type BpmIndicationLazyQueryHookResult = ReturnType<
  typeof useBpmIndicationLazyQuery
>
export type BpmIndicationQueryResult = ApolloReactCommon.QueryResult<
  BpmIndicationQuery,
  BpmIndicationQueryVariables
>
export const PricePackagesDocument = gql`
  query PricePackages($where: PricePackageWhereInput) {
    pricePackages(where: $where) {
      ...PricePackageFragment
    }
  }
  ${PricePackageFragmentFragmentDoc}
`
export type PricePackagesComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    PricePackagesQuery,
    PricePackagesQueryVariables
  >,
  'query'
>

export const PricePackagesComponent = (props: PricePackagesComponentProps) => (
  <ApolloReactComponents.Query<PricePackagesQuery, PricePackagesQueryVariables>
    query={PricePackagesDocument}
    {...props}
  />
)

export type PricePackagesProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  PricePackagesQuery,
  PricePackagesQueryVariables
> &
  TChildProps
export function withPricePackages<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    PricePackagesQuery,
    PricePackagesQueryVariables,
    PricePackagesProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    PricePackagesQuery,
    PricePackagesQueryVariables,
    PricePackagesProps<TChildProps>
  >(PricePackagesDocument, {
    alias: 'pricePackages',
    ...operationOptions,
  })
}

/**
 * __usePricePackagesQuery__
 *
 * To run a query within a React component, call `usePricePackagesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePricePackagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePricePackagesQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function usePricePackagesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    PricePackagesQuery,
    PricePackagesQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    PricePackagesQuery,
    PricePackagesQueryVariables
  >(PricePackagesDocument, baseOptions)
}
export function usePricePackagesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    PricePackagesQuery,
    PricePackagesQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    PricePackagesQuery,
    PricePackagesQueryVariables
  >(PricePackagesDocument, baseOptions)
}
export type PricePackagesQueryHookResult = ReturnType<
  typeof usePricePackagesQuery
>
export type PricePackagesLazyQueryHookResult = ReturnType<
  typeof usePricePackagesLazyQuery
>
export type PricePackagesQueryResult = ApolloReactCommon.QueryResult<
  PricePackagesQuery,
  PricePackagesQueryVariables
>
export const TrackTraceDocument = gql`
  query TrackTrace($orderNumber: String!) {
    tracktrace(orderNumber: $orderNumber) {
      id
      orderNumber
      priceItems {
        id
        name
        price
        VAT
        inclVAT
        amount
      }
      pricePackage {
        id
        name
        price
        description
      }
      car {
        carPrice
        bpmPrice
        saving
        id
        brand {
          name
        }
        model
        version
        images {
          id
          location
        }
        co2
        color
        paint
        interiorColor
        interiorType
        transmission
        fuelType
        year
        power
        displacement
      }
      status
      statusLogs {
        id
        createdAt
        status
      }
      messages {
        id
        client {
          id
          name
        }
        user {
          id
          name
        }
        content
      }
    }
  }
`
export type TrackTraceComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    TrackTraceQuery,
    TrackTraceQueryVariables
  >,
  'query'
> &
  ({ variables: TrackTraceQueryVariables; skip?: boolean } | { skip: boolean })

export const TrackTraceComponent = (props: TrackTraceComponentProps) => (
  <ApolloReactComponents.Query<TrackTraceQuery, TrackTraceQueryVariables>
    query={TrackTraceDocument}
    {...props}
  />
)

export type TrackTraceProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  TrackTraceQuery,
  TrackTraceQueryVariables
> &
  TChildProps
export function withTrackTrace<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    TrackTraceQuery,
    TrackTraceQueryVariables,
    TrackTraceProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    TrackTraceQuery,
    TrackTraceQueryVariables,
    TrackTraceProps<TChildProps>
  >(TrackTraceDocument, {
    alias: 'trackTrace',
    ...operationOptions,
  })
}

/**
 * __useTrackTraceQuery__
 *
 * To run a query within a React component, call `useTrackTraceQuery` and pass it any options that fit your needs.
 * When your component renders, `useTrackTraceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTrackTraceQuery({
 *   variables: {
 *      orderNumber: // value for 'orderNumber'
 *   },
 * });
 */
export function useTrackTraceQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    TrackTraceQuery,
    TrackTraceQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<TrackTraceQuery, TrackTraceQueryVariables>(
    TrackTraceDocument,
    baseOptions
  )
}
export function useTrackTraceLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    TrackTraceQuery,
    TrackTraceQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    TrackTraceQuery,
    TrackTraceQueryVariables
  >(TrackTraceDocument, baseOptions)
}
export type TrackTraceQueryHookResult = ReturnType<typeof useTrackTraceQuery>
export type TrackTraceLazyQueryHookResult = ReturnType<
  typeof useTrackTraceLazyQuery
>
export type TrackTraceQueryResult = ApolloReactCommon.QueryResult<
  TrackTraceQuery,
  TrackTraceQueryVariables
>
