import React from 'react'
import { OrderStatus } from './generated-models'
import { IconProps } from './components/ui/Icon'
import styled from '@emotion/styled'

const ArticleList = styled.ol`
  list-style: decimal;
  padding-left: 28px;
  margin: 10px 0;
`

export const isClient = typeof window !== 'undefined'

export const orderStatuses: {
  value: OrderStatus
  label: string
  icon?: IconProps['icon']
  description?: string
}[] = [
  {
    value: OrderStatus.BpmIndicated,
    label: 'BPM Berekend',
    description: 'De BPM is bepaald',
  },
  {
    value: OrderStatus.QuotationRequested,
    label: 'Offerte aanvraag ontvangen',
  },
  {
    value: OrderStatus.QuotationSend,
    label: 'Offerte verzonden',
  },
  { value: OrderStatus.PaymentReceived, label: 'Betaling Ontvangen' },
  {
    value: OrderStatus.TechnicalExamination,
    label: 'Eventuele Technische Keuring',
  },
  { value: OrderStatus.InTransit, label: 'Onderweg naar Nederland' },
  { value: OrderStatus.Arrived, label: 'Gearriveerd in Nederland' },
  { value: OrderStatus.RdwExamination, label: 'RDW Keuring' },
  {
    value: OrderStatus.Accessoiries,
    label: 'Eventuele accesoires monteren',
  },
  { value: OrderStatus.ReceivedLicensePlate, label: 'Kenteken ontvangen' },
  { value: OrderStatus.InTransitToYou, label: 'Onderweg naar klant' },
  { value: OrderStatus.Completed, label: 'Afgehandeld' },
  { value: OrderStatus.Canceled, label: 'Geannuleerd' },
]

export const fuelTypes = [
  { value: 'DIESEL', label: 'Diesel' },
  { value: 'GASOLINE', label: 'Benzine' },
  { value: 'ELECTRIC', label: 'Elektrisch' },
]

export const paintTypes = [
  { value: 'GENERAL', label: 'Standaard' },
  { value: 'PEARLESCENT', label: 'Parelmoer' },
  { value: 'MATTE', label: 'Mat' },
  { value: 'METALLIC', label: 'Metallic' },
]

export const interiorTypes = [
  { value: 'LEATHER', label: 'Leer' },
  { value: 'FABRIC', label: 'Stof' },
]

export const transmissionTypes = [
  { value: 'AUTOMATIC', label: 'Automatisch' },
  { value: 'MANUAL', label: 'Handgeschakeld' },
]

export const clientTypes = [
  { value: 'PRIVATE', label: 'Particulier' },
  { value: 'BUSINESS', label: 'Zakelijk' },
]

export const TermsAndConditionsText = (
  <>
    <h4>Algemene voorwaarden PC Automotive Services.</h4>
    <br />
    <h5>Artikel 1. Algemeen toepasselijkheid</h5>
    <ArticleList>
      <li>
        Deze algemene voorwaarden zijn van toepassing op alle opdrachten aan PC
        Automotive Services handelend onder de naam PC Auto Import gevestigd te
        Amsterdam.
      </li>
      <li>
        PC Automotive Services wordt in haar hoedanigheid als gebruiker van deze
        algemene voorwaarden aangeduid als ‘opdrachtnemer’. De wederpartij wordt
        aangeduid als ‘opdrachtgever’.
      </li>
      <li>
        Deze algemene voorwaarden zijn van toepassing op alle correspondentie,
        aanbiedingen en offertes van opdrachtnemer en alle overeenkomsten tussen
        opdrachtgever en opdrachtnemer.
      </li>
      <li>
        Op de rechtsverhouding tussen opdrachtnemer en opdrachtgever is
        Nederlands recht van toepassing. Ook indien aan een verbintenis geheel
        of gedeeltelijk in het buitenland uitvoerig wordt gegeven of indien bij
        de rechtsbetrekking betrokken partij aldaar woon of vaste verblijfplaats
        heeft. De toepassing van het Weens Koopverdrag (CISG) wordt
        uitdrukkelijk uitgesloten. Alleen de rechter te Amsterdam is bevoegd in
        eerste instantie van een geschil tussen opdrachtnemer en opdrachtgever
        kennis te nemen.
      </li>
      <li>
        Toepasselijkheid van eigen voorwaarden of andere voorwaarden door
        opdrachtgever worden door opdrachtnemer uitdrukkelijk afgewezen.
        Afwijkingen van deze voorwaarden binden opdrachtnemer niet.
      </li>
      <li>
        Bij geheel of gedeeltelijke vernietiging of nietig verklaring van een in
        deze algemene voorwaarden opgenomen bepalingen blijven de overige
        bepalingen uit deze algemene voorwaarden van toepassing.
      </li>
      <li>
        Indien in bepaalde situaties en onderlinge afspraken opdrachtnemer geen
        strikte naleving van de bepalingen in deze algemene voorwaarden verlangt
        heeft dit niet als gevolg dat de bepalingen uit deze algemene
        voorwaarden niet meer van toepassing zijn. De bepalingen uit deze
        algemene voorwaarden blijven onveranderd van toepassing.
      </li>
    </ArticleList>
    <h5>Artikel 2. Insturen of uploaden van BPM aanvragen en zoekopdrachten</h5>
    <ArticleList>
      <li>
        Alle aanvragen en zoekopdrachten die ingestuurd worden via de email of
        de website www.pcautoimport.nl, verbinden opdrachtnemer niet tot het in
        behandeling nemen. Door enkel het insturen of uploaden van een
        zoekopdracht nadat een auto is gevonden of komt geen overeenkomst tot
        stand tussen opdrachtnemer en opdrachtgever.
      </li>
      <li>
        Er komt pas een overeenkomst tot stand indien door beide partijen het
        formulier ‘overeenkomst van opdracht’ is ondertekend.
      </li>
    </ArticleList>
    <h5>Artikel 3. Aanbiedingen en offertes</h5>
    <ArticleList>
      <li>
        Aan geen enkele aanbieding of offerte van opdrachtnemer kunnen rechten
        ontleend worden.
      </li>
      <li>
        Indien opdrachtgever kan begrijpen dat er sprake is van een kennelijke
        vergissing of verschrijving kan opdrachtnemer niet aan zijn aanbiedingen
        of offertes gehouden worden.
      </li>
      <li>
        Alle vermelde prijzen in aanbiedingen en offertes zijn exclusief BTW en
        tenzij anders vermeld, exclusief eventuele heffingen van overheidswege
        en andere in het kader van de overeenkomst te maken kosten zoals reis-
        en verblijf-. Administratiekosten en verzendkosten.
      </li>
      <li>
        Alle aanbiedingen en offertes gelden niet automatisch voor toekomstige
        opdrachten. Eventuele prijsverhogingen, betalingsvoorwaarden en andere
        voorwaarden zijn voorbehouden.
      </li>
    </ArticleList>
    <h5>Artikel 4. De overeenkomst</h5>
    <ArticleList>
      <li>
        Tenzij uitdrukkelijk anders overeengekomen, wordt de overeenkomst door
        opdrachtgever en opdrachtnemer aangegaan voor onbepaalde tijd.
      </li>
      <li>
        Indien voor bepaalde werkzaamheden door opdrachtnemer een termijn wordt
        opgegeven of overeengekomen dan is dit nimmer de fatale termijn tenzij
        dit uitdrukkelijk anders is overeengekomen. Overschrijding van termijnen
        verplicht opdrachtnemer niet tot enige schadevergoeding.
      </li>
      <li>
        Opdrachtnemer heeft het recht werkzaamheden te laten uitvoeren door
        derden. De werking van artikel 7:404 en van artikel 7:407 lid 2 BW is
        uitgesloten.
      </li>
      <li>
        Opdrachtgever is gerechtigd de overeenkomst in verschillende fasen ‘A’
        en ‘B’ uit te voeren en ieder uitgevoerde deel afzonderlijk te facturen
        aan opdrachtgever.
      </li>
      <li>
        Opdrachtgever is verplicht de roerende zaken aan te nemen die hem ter
        beschikking worden gesteld. Levering van deze zaken vindt plaats op het
        adres van opdrachtnemer mits anders is overeengekomen.
      </li>
      <li>
        Opdrachtnemer heeft het recht de uitvoering van de overeenkomst op te
        schorten of extra kosten in rekening te brengen indien opdrachtgever
        niet alle gegevens die opdrachtnemer vereist (tijdig) aanlevert. De
        uitvoeringstermijn van de overeenkomst begint pas als de opdrachtgever
        alle gewenste gegevens aan opdrachtnemer ter beschikking heeft gesteld.
      </li>
      <li>
        Opdrachtnemer is niet aansprakelijk voor schade ontstaan doordat
        onjuiste of onvolledige gegevens of te late of onvolledige betalingen
        door opdrachtgever zijn aangedragen.
      </li>
      <li>
        De opdrachtgever aanvaardt de mogelijkheid van wijziging van de
        overeenkomst, daaronder begrepen de wijziging in prijs en de termijn van
        uitvoering indien; dat voor een behoorlijke uitvoering van de
        overeenkomst noodzakelijk is en indien de aard, omvang of inhoud van de
        overeenkomst op verzoek of aanwijzing van opdrachtgever of daartoe
        bevoegde instanties gewijzigd wordt.
      </li>
      <li>
        Indien er sprake is van een wijziging van de overeenkomst is
        opdrachtnemer bevoegd extra kosten in rekening te brengen.
      </li>
    </ArticleList>
    <h5>Artikel 5. Garantie</h5>
    <ArticleList>
      <li>
        Iedere vorm van garantie en aansprakelijkheid van opdrachtnemer komt te
        vervallen indien een gebrek is ontstaan of voortvloeit uit
        onoordeelkundig of oneigenlijk gebruik van software, diensten/ of
        goederen. Opdrachtgever kan evenmin aanspraak maken op garantie indien
        een gebrek is ontstaan door, of gevolg van, omstandigheden waar
        opdrachtnemen geen invloed op kan uitoefenen, daaronder begrepen
        weersomstandigheden.
      </li>
      <li>
        De waarde van een garantie kan in geen geval het oorspronkelijk aan
        opdrachtnemer betaalde bedrag voor geleverde dienst of product
        overstijgen.
      </li>
      <li>
        Nakoming van een garantieverplichting van opdrachtnemer verlengt de
        garantietermijn niet.
      </li>
      <li>
        Indien en zover opdrachtnemer een ter zake van een garantietoezegging
        een overdraagbare aanspraak op een derde heeft, zal opdrachtnemer door
        de enkele overdracht van die aanspraak geheel bevrijd zijn voor haar
        garantieverplichtingen.
      </li>
      <li>
        Verplichtingen uit garantietoezeggingen kunnen door opdrachtnemer worden
        opgeschort totdat opdrachtgever aan alle opeisbare verplichtingen heeft
        voldaan.
      </li>
      <li>
        Indien opdrachtnemer door derden aansprakelijk wordt gesteld wegens
        tekortkomingen of nalatigheid van opdrachtgever is opdrachtgever
        gehouden opdrachtnemer zowel buiten als in rechte bij te staan en
        onverwijld al hetgeen te doen dat van hem in dat geval verwacht mag
        worden. Indien opdrachtgever in gebreke blijft is opdrachtnemer zonder
        ingebrekestelling gerechtigd zich te juridisch te verdedigen. Alle
        kosten die hierdoor ontstaan komen integraal voor rekening en risico van
        opdrachtgever.
      </li>
      <li>
        Opdrachtnemer is door opdrachtgever gevrijwaard voor eventuele
        aanspraken van derden die schade lijden die niet toerekenbaar is aan
        grove schuld of opzet van opdrachtnemer.
      </li>
      <li>
        De aansprakelijkheid van opdrachtnemer is beperkt tot gebreken waarvan
        opdrachtgever bewijst dat zij binnen de overeengekomen garantietermijn
        bij het verrichten van diensten of na levering zijn opgetreden.
        Opdrachtgever dient gebreken binnen de overeengekomen garantietermijn te
        vermelden.
      </li>
    </ArticleList>
    <h5>Artikel 6. Exoneratieclausule</h5>
    <ArticleList>
      <li>
        Op deze exoneratieclausule kan opdrachtnemer geen beroep doen indien
        sprake is van opzet of bewuste roekeloosheid.
      </li>
      <li>
        Opdrachtnemer is niet aansprakelijk voor het verstrijken van enige
        termijn indien de opdrachtgever te laat is met het voldoen van
        betalingen of het verstrekken van gegevens dan wel het te wijten is aan
        derden.
      </li>
      <li>
        Opdrachtnemer is gerechtigd eventuele aansprakelijkheidsbeperkingen van
        derden namens de opdrachtgever te aanvaarden. Opdrachtnemer is niet
        aansprakelijk voor fouten en/of tekortkomingen van de door haar
        ingeschakelde derden.
      </li>
      <li>
        Opdrachtnemer is niet aansprakelijk voor enige gevolgschade indien hij
        opdrachtgever onmiddellijk of middelijk vertegenwoordigd.
      </li>
      <li>
        Opdrachtnemer is niet aansprakelijk indien zij door overmacht, direct of
        indirect, is verhinderd aan diens verplichtingen te voldoen. Onder
        overmacht wordt verstaan elke omstandigheid ten gevolge waarvan de
        naleving door opdrachtnemer niet meer kan worden verlangd.
      </li>
    </ArticleList>
    <h5>Artikel 7. Vrijwaring opdrachtnemer</h5>
    <ArticleList>
      <li>
        Opdrachtnemer is door opdrachtgever gevrijwaard voor eventuele
        aanspraken van derden die schade lijden die niet toerekenbaar is aan de
        opdrachtnemer.
      </li>
      <li>
        Indien opdrachtnemer door derden aansprakelijk wordt gesteld is
        opdrachtgever gehouden opdrachtnemer zowel buiten als in rechte bij te
        staan en onverwijld al hetgeen te doen dat van hem in dat geval verwacht
        mag worden. Indien opdrachtgever in gebreke blijft is opdrachtnemer
        zonder ingebrekestelling gerechtigd zich te juridisch te verdedigen.
        Alle kosten die hierdoor ontstaan komen integraal voor rekening en
        risico van opdrachtgever.
      </li>
      <li>
        Opdrachtnemer is beperkt aansprakelijk tot maximaal de factuurwaarde van
        de geleverde dient of zaak, althans tot dat gedeelte waarop de
        aansprakelijkheid betrekking heeft. Opdrachtnemer is in geen enkel geval
        nooit voor geen hoger bedrag aansprakelijk dan het bedrag dat haar
        verzekeraar in het concrete geval uitkeert.
      </li>
      <li>
        Opdrachtnemer is enkel aansprakelijk voor directe schade, ofwel de
        redelijk kosten ter vaststelling van de oorzaak en omvang van de schade.
        Opdrachtgever is nimmer aansprakelijk voor indirecte schade, daaronder
        begrepen gederfde winst, gevolgschade, gemiste besparingen en schade
        door bedrijfsstagnatie.
      </li>
    </ArticleList>
  </>
)
