import React, { useState } from 'react'
import reset from 'emotion-reset'
import { Global, css } from '@emotion/core'
import * as UI from './components/ui'
import * as Rebass from 'rebass'
import { ApolloProvider } from '@apollo/react-hooks'
import { client } from './utils/apollo'
import { MDXProvider } from '@mdx-js/react'

export const components = {
  ...UI,
  ...Rebass,
  li: UI.ListItem,
  h1: UI.H1,
  h2: UI.H2,
  h3: UI.H3,
  h4: UI.H4,
}

export const ThemeContext = React.createContext<
  ['light' | 'dark', (mode: 'light' | 'dark') => void]
>(null)

export const Theme: React.FC = ({ children }) => {
  const [mode, setMode] = useState<'dark' | 'light'>('light')
  return (
    <ApolloProvider client={client}>
      <MDXProvider components={components}>
        <ThemeContext.Provider value={[mode, setMode]}>
          <Global
            styles={css`
              ${reset}
              html, body {
                height: 100%;
              }
              strong {
                font-weight: 600;
              }
              @import url('https://fonts.googleapis.com/css?family=Montserrat:400,500,600,700&display=swap');
            `}
          />
          {children}
        </ThemeContext.Provider>
      </MDXProvider>
    </ApolloProvider>
  )
}
