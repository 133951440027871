export default {
  colors: {
    primary: '#00537E',
    primaryDark: '#00314b',
    secondary: '#3AA17E',
    text: '#4A4A4A',
    background: '#fff',
    muted: '#f6f6f6',
    dark: '#222',
    disabled: '#afafaf',
    white: '#fff',
  },
  fonts: {
    body: 'Montserrat, sans-serif',
    heading: 'Montserrat, sans-serif',
    monospace: 'Menlo, monospace',
  },
  fontSizes: [12, 14, 16, 20, 24, 32, 48, 64, 96],
  fontWeights: {
    body: 400,
    heading: 700,
    bold: 700,
  },
  lineHeights: {
    body: 1.5,
    heading: 1.125,
  },
  variants: {
    container: {
      mx: 'auto',
      maxWidth: 'maxWidth',
      px: 3,
    },
    content: {
      background: (theme: any) =>
        `linear-gradient(to right, ${theme.colors.primary}, ${theme.colors.secondary})`,
      borderRadius: 5,
      borderStyle: 'solid',
      borderWidth: 1,
      borderColor: 'white',
      p: 4,
      boxShadow: '0 1px 20px 5px rgba(0,0,0,.2)',
      color: 'muted',
    },
  },
  breakpoints: ['40em', '53.75em', '64em'],
  space: [0, 4, 8, 16, 32, 64, 128, 256],
  sizes: {
    maxWidth: 1240,
  },
  text: {
    heading: {
      fontFamily: 'heading',
      fontWeight: 'heading',
      lineHeight: 'heading',
      letterSpacing: 'heading',
    },
  },
  styles: {
    root: {
      fontFamily: 'body',
      lineHeight: 'body',
      fontWeight: 'body',
    },
    strong: {
      fontWeight: 600,
    },
  },
}
