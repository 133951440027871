import React from 'react'
import styled from '@emotion/styled'
import { keyframes } from '@emotion/core'
import Icon, { IconProps } from './Icon'

const feedBackAnimation = keyframes`
  0% {
    opacity: 0;
    max-height: 0;
  }
  100% {
    opacity: 1;
    max-height: 80px;
  }
`

const InputContainer = styled.div`
  margin: 10px 0;
  display: flex;
  width: 100%;
  border-bottom: 1px solid white;
  align-items: center;
  svg {
    margin-right: 10px;
  }
`

const InputFeedback = styled.div`
  float: right;
  animation: ${feedBackAnimation} 0.8s ease-out;
  & > p {
    font-size: 12px;
    margin-bottom: 0;
  }
`

interface FormFieldProps extends Partial<IconProps> {
  touched?: any
  errors?: any
}

const FormField: React.SFC<FormFieldProps> = ({
  icon,
  color,
  size,
  touched,
  errors,
  children,
}) => (
  <>
    <InputContainer>
      {icon && <Icon icon={icon} color={color} size={size} />}
      {children}
    </InputContainer>
    {touched && errors && (
      <InputFeedback>
        <p>{errors}</p>
      </InputFeedback>
    )}
  </>
)

export default FormField
