import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

const Logo = () => {
  const { logo } = useStaticQuery(graphql`
    query {
      logo: file(name: { eq: "logo" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            src
          }
        }
      }
    }
  `)
  return <img width="100%" alt="Logo" src={logo.childImageSharp.fluid.src} />
}

export default Logo
